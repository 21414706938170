import { Box, Grid, Typography, styled, useTheme } from '@mui/material';
import { AlertDialog, Button, Input, Radio } from '../../../../../components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useRef, useState } from 'react';
import HotelSelectionHotel from './hotel';
import _ from 'lodash';
import { useLoader } from '../../../../../providers/loader';
import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../../../../../helper/constant';
import { addHotelsToLayover, getHotelsInLayover, removeHotelsFromLayover } from '../../../../../services/layover/hotels';
import { useNavigate } from 'react-router-dom';
import { InputEndAdornment } from '../../../components/endAdornment';
import { getLayoverDetails } from '../../../../../services/layover';

const StyledMainLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[800],
  textTransform: 'uppercase'
}))

const HotelSelection = (props) => {
  const { layover, layoverId, onSave, updateLayover } = props;
  const navigate = useNavigate();
  const formRef = useRef(null);
  const theme = useTheme();
  const { startLoader, stopLoader } = useLoader();
  const [hotels, setHotels] = useState([]);
  const [passengerPlacementStrategy, setPassengerPlacementStrategy] = useState('');
  const [totalSelectedRoomOccupants, setTotalSelectedRoomOccupants] = useState(0);
  const [dialogNotAvailableCapacity, setDialogNotAvailableCapacity] = useState(false);
  const [newlyAddedPassenger, setNewlyAddedPassenger] = useState(0)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setFocus,
    setValue,
    getValues,
    trigger
  } = useForm({
    shouldUnregister: true,
    mode: 'all'
  });

  // check booked rooms are available in hotels or not
  function checkRoomAvailability(hotels) {
    // Iterate over each hotel in the array
    for (const hotel of hotels) {
      if(!hotel.hotel.is_open_for_layover){
        return true;
      }
      // Create a map for roomTypeId to available_rooms
      const roomTypeMap = new Map();
      for (const roomType of hotel.hotel.roomTypes) {
          roomTypeMap.set(roomType.roomTypeId, roomType.available_rooms);
      }
      // Check each room in the rooms array
      for (const room of hotel.rooms) {
          const availableRooms = roomTypeMap.get(parseInt(room.roomTypeId));
          if (availableRooms !== undefined && room.totalRooms !== "" && room.totalRooms > availableRooms) {
              return true; // Total rooms is less than available rooms
          }
      }
    }
    return false; // Condition not met for any hotel
  }
  
  // function go to checkout page and delete all checkout hotels
  const handleNotAvailableCapacityGoBackBtn = () => {
    navigate(`/layover/${layoverId}/hotels`, { state: { checkoutDeleteAll: true } });
    setDialogNotAvailableCapacity(false)
  }

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
  
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const validateRoomOccupancy = useCallback((noOfRooms, occupancy) => {
    if (((totalSelectedRoomOccupants + (noOfRooms * occupancy)) <= layover?.economy_passangers) || updateLayover) {
      return true;
    }
    return false;
  }, [layover, totalSelectedRoomOccupants]);

  const handleIncreaseRoom = useCallback((noOfRooms, occupancy) => {
    setNewlyAddedPassenger(oldNewlyAddedPassenger=>oldNewlyAddedPassenger + (noOfRooms * occupancy))
    setTotalSelectedRoomOccupants(oldTotalSelectedRoomOccupants => {
      return oldTotalSelectedRoomOccupants + (noOfRooms * occupancy);
    })
  }, []);

  const handleDecreaseRoom = useCallback(async (noOfRooms, occupancy) => {
    setNewlyAddedPassenger(oldNewlyAddedPassenger=>oldNewlyAddedPassenger - (noOfRooms * occupancy))
    setTotalSelectedRoomOccupants(oldTotalSelectedRoomOccupants => {
      return oldTotalSelectedRoomOccupants - (noOfRooms * occupancy);
    })
  }, []);

  /**
   * Get layover details from API
   */
  const getLayoverFromApi = useCallback(async (layoverId) => {
    try {
      let request = {
        'populate[0]': 'airline_id',
        'populate[1]': 'airport_id'
      };
      const response = await getLayoverDetails(layoverId, {params: request});
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Get hotels in layover via API
   * 
   * @param {*} layoverId  Layover Id
   */
  const getHotelsInLayoverViaApi = useCallback(async (layoverId) => {
    try {
      const response = await getHotelsInLayover(layoverId);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Store hotels to layover via API
   * 
   * @param {*} id  Hotel Id
   */
  const storeHotelsToLayoverViaApi = useCallback(async (layoverId, request) => {
    try {
      const response = await addHotelsToLayover(layoverId, request);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * remove hotel from layover via API
   * 
   * @param {*} layoverId   Layover Id
   * @param {*} hotelId     Hotel Id
   */
  const removeHotelsFromLayoverViaApi = useCallback(async (layoverId, hotelId) => {
    try {
      const response = await removeHotelsFromLayover(layoverId, hotelId);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  const handleRemoveHotelFromLayover = useCallback((hotelId) => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        await removeHotelsFromLayoverViaApi(layoverId, hotelId)
        const response = await getHotelsInLayoverViaApi(layoverId)
        if (response && response?.length > 0) {
          setHotels(response);
        } else {
          setHotels([])
          navigate(`/layover/${layoverId}/hotels`);
        }
        resolve()
      } catch (e) {
        reject(e)
      }
    }).then(response => {
    }).catch((e) => {
    }).finally(() => {
      stopLoader();
    });
  }, [startLoader, stopLoader, layoverId, navigate, removeHotelsFromLayoverViaApi, getHotelsInLayoverViaApi]);

  // Call API's on form submit
  const onSubmit = useCallback(async (data) => {
    if (data?.formHotelSelection) {
      let request = _.cloneDeep(data.formHotelSelection);
      delete request?.hotels;
      if (data.formHotelSelection?.hotels && Object.keys(data.formHotelSelection.hotels).length > 0) {
        let tempHotels = [];
        for (const hotelId in data.formHotelSelection.hotels) {
          let tempHotelRooms = [];
          const hotel = data.formHotelSelection.hotels[hotelId]
          if (hotel?.rooms && Object.keys(hotel.rooms).length > 0) {
            for (const roomId in hotel.rooms) {
              tempHotelRooms.push({
                roomTypeId: roomId,
                ...hotel.rooms[roomId]
              })
            }
          }
          tempHotels.push({
            hotelId: hotelId,
            ...hotel,
            rooms: tempHotelRooms
          })
        }
        request.hotels = tempHotels;
      }
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          await storeHotelsToLayoverViaApi(layoverId, request)
          resolve()
        } catch (e) {
          reject()
        }
      }).then(response => {
        toast.success(toastMessages.airline.storeHotelSelectionToLayover.success, {
          autoClose: maxToastTime,
          toastId: 'update-airline-store-hotel-selection-success',
        })
        if (onSave) { onSave() }
      }).catch((e) => {
        toast.error(toastMessages.airline.storeHotelSelectionToLayover.error, {
          autoClose: maxToastTime,
          toastId: 'update-airline-store-hotel-selection-error',
        })
      }).finally(() => {
        stopLoader();
      });
    }
  }, [startLoader, stopLoader, layoverId, storeHotelsToLayoverViaApi, onSave]);

  useEffect(() => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        const layoverResponse = await getLayoverFromApi(layoverId);
        setPassengerPlacementStrategy(layoverResponse.data.passenger_placement_strategy);

        const response = await getHotelsInLayoverViaApi(layoverId)
        if (response && response.length > 0) {
          setHotels(response)
          setNewlyAddedPassenger(response.reduce((total, hotel)=>{ 
            return total + hotel.rooms.reduce((total, room)=>{
              let hotelOccupancy = hotel?.hotel?.roomTypes?.find(HotelRoom=> Number(room.roomTypeId) === Number(HotelRoom.roomTypeId))?.occupancy
              return total + room.additional_rooms * hotelOccupancy
            },0)
          },0) || 0)
          if(checkRoomAvailability(response) && !updateLayover){
            setDialogNotAvailableCapacity(true)
          }
        }
        resolve()
      } catch (e) {
        reject(e)
      }
    }).then(response => {
    }).catch((e) => {
    }).finally(() => {
      stopLoader();
    });
  }, [startLoader, stopLoader, layoverId, getLayoverFromApi, getHotelsInLayoverViaApi])

  useEffect(() => {
    if (hotels && hotels.length > 0) {
      let tempTotalHotelRoomOccupants = 0;
      for (const hotel of hotels) {
        let tempRoomTypes = {};
        if (hotel?.hotel?.roomTypes && hotel.hotel.roomTypes.length > 0) {
          for (const room of hotel.hotel.roomTypes) {
            tempRoomTypes[room.roomTypeId] = room;
          }
        }
        if (hotel?.rooms && hotel.rooms.length > 0) {
          for (const room of hotel.rooms) {
            if (tempRoomTypes[room.roomTypeId]) {
              let occupants = tempRoomTypes[room.roomTypeId]?.occupancy ? Number(tempRoomTypes[room.roomTypeId].occupancy) : 1;
              tempTotalHotelRoomOccupants += (Number(room.totalRooms) * Number(occupants));
            }
          }
        }
      }
      setTotalSelectedRoomOccupants(tempTotalHotelRoomOccupants)
    }
  }, [hotels]);
  
  return (hotels.length > 0 ? (
    <Box sx={{overflowX: 'hidden', borderRadius: theme.borderRadius[10]}}>
      <FormProvider {...{control, handleSubmit, formState: { errors }, setFocus, setValue, getValues, trigger}}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <Grid container>
            <Box sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left'
            }}>
              <StyledMainLabel sx={{mb:1}}>Passenger</StyledMainLabel>
                <Input
                  value={`${totalSelectedRoomOccupants} / ${layover?.economy_passangers}`}
                  // isError={totalSelectedRoomOccupants > layover?.economy_passangers}
                  disabled
                  endAdornment={
                    <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                      People
                    </InputEndAdornment>
                  }
                  formControlProps={{
                    sx: {
                      marginBottom: 0
                    }
                  }}
                />
            </Box>
            <Box sx={{mt: 4.8, ml: 3}}>
              {updateLayover ? 
                <Input
                  value={newlyAddedPassenger}
                  sx={{
                    backgroundColor: newlyAddedPassenger ? theme.palette.sidebarBg : 'default',
                    color: newlyAddedPassenger ? theme.palette.common.white : 'inherit',
                    '& .MuiInputBase-input': {
                      width: theme.spacing(3)
                    },
                  }}
                  endAdornment={
                    <InputEndAdornment position='end' color={newlyAddedPassenger ? theme.palette.common.white : null} sx={{textTransform:'capitalize'}}>
                      Additional People
                    </InputEndAdornment>
                  }
                />
              : null}
            </Box>
            {hotels.map((hotel, index) => (
              <HotelSelectionHotel
                key={`hs-h-${index}`}
                hotel={hotel}
                updateLayover={updateLayover}
                validateRoomOccupancy={validateRoomOccupancy}
                onIncreaseRoom={handleIncreaseRoom}
                onDecreaseRoom={handleDecreaseRoom}
                onRemoveHotelFromLayover={handleRemoveHotelFromLayover}
              />
            ))}
          </Grid>
          <Grid container rowSpacing={2} sx={{mt:1}}>
            <Grid item xs={12}>
              <StyledMainLabel>Select Passenger Placement Strategy</StyledMainLabel>
            </Grid>
            <Grid item xs={12} sx={{mb:1}}>
              <Controller
                name={`formHotelSelection[passenger_placement_strategy]`}
                control={control}
                defaultValue={passengerPlacementStrategy ? passengerPlacementStrategy : 'PRIORITIZE'}
                render={({ field }) => (
                  <Radio
                    options={[
                      {
                        value: 'PRIORITIZE',
                        label: 'Prioritize hotels',
                        disabled: hotels?.length <= 1 || updateLayover
                      },
                      {
                        value: 'EQUALLY_SPLIT',
                        label: 'Equally split between hotels',
                        disabled: hotels?.length <= 1 || updateLayover
                      },
                      {
                        value: 'PASSENGER_CHOICE',
                        label: 'Passenger can select hotel',
                        disabled: hotels?.length <= 1 || updateLayover
                      }
                    ]}
                    inputRef={field.ref}
                    {...field}
                    sx={{
                      '& .MuiFormControlLabel-root': {
                        alignItems: 'start',
                        '&.Mui-disabled': {
                          filter: 'blur(.09rem)'
                        },
                        '&> .MuiRadio-root': {
                          paddingTop: 0,
                          paddingBottom: 0
                        },
                        '&> .MuiTypography-root': {
                          paddingTop: theme.spacing(.3)
                        }
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container columnSpacing={4}>
                <Grid item xs={6}>
                  <Button
                    type='submit'
                    variant='contained'
                    sx={{
                      width: '100%',
                      '&.MuiButton-contained': {
                        ...theme.typography.fontMedium600
                      }
                    }}
                  >
                    Save and Continue
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <AlertDialog
        title='Room Availability Update'
        dialogTitleProps={{
          sx: {
            ...theme.typography.textxxLarge,
            paddingTop: theme.spacing(5),
            color: theme.palette.roseGold[100],
            paddingBottom: theme.spacing(2)
          }
        }}
        open={dialogNotAvailableCapacity}
        onDisagree={handleNotAvailableCapacityGoBackBtn}
        noBtnText={'Go back to hotel selection'}
        sx={{
          textAlign: 'center'
        }}
        fullWidth={true}
        maxWidth={'xs'}
        stretchBtn
      >
        <Box>
          <Typography sx={{pb:1, lineHeight: theme.lineHeight[1.2]}} variant='body1'>
            The availability of Hotel A has changed and the hotel does not have the requested room capacity. Please re-search the hotel from the list and add it.
          </Typography>
        </Box>
      </AlertDialog>
    </Box>
  ) : (null));
};

export default HotelSelection;
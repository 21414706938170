import { useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  useTheme,
  Typography
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { LargeHeading } from '../../hotel/components/heading';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { prepareImageUrlByPath } from '../../../utils';
import downloadPDF from '../../../utils/downloadPDF';
import downloadJsonAsXlsx from '../../../utils/downloadJsonAsXlsx';
import { languages } from '../../../helper/constant';
import downloadCSVFile from '../../../utils/downloadCSV';
import { Dropdown } from '../../../components';

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontSemiBold500,
  textTransform: 'uppercase',
  color: theme.palette.table.header,
  borderBottom: 'unset',
  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottomColor:theme.mode === 'light' ? theme.palette.primary.main : theme.palette.table.divider,
  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
}));

const BillingTable = (props) => {
  const { invoices } = props;
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleDownloadAdditionalDoc = async (url, fileName) => {
    const tempUrl = await (process.env.REACT_APP_ENVIRONMENT === 'LOCAL') ? prepareImageUrlByPath(url) : url
    await downloadPDF(tempUrl, fileName)
  }

  const getLanguageByCode = (langCode) => {
    const langCodeLowerCase = langCode.toLowerCase();
    return (languages?.[langCodeLowerCase]?.name) ? languages[langCodeLowerCase].name : langCode;
  };

  const convertPassengerDataCSV = (passengers) => {
    // headers for csv file
    let headers = ['First Name,Last Name,Email Id,Booking Id,Preferred language']
    
    // Convert all passenger data into camma separated string
    const passengersCsv = passengers?.reduce((acc, passenger) => {
      // Add lead passenger data
      const leadPassengerRow = [
        passenger?.leadPassengerFirstName,
        passenger?.leadPassengerLastName,
        passenger?.leadPassengerEmail,
        passenger?.confirmationCode,
        getLanguageByCode(passenger?.preferredLanguageCode),
      ];
      acc.push(leadPassengerRow);

      // Add co-passenger data
      const coPassengerRows = passenger.coPassengers.map(coPassenger => [
        coPassenger?.first_name,
        coPassenger?.last_name,
        coPassenger?.email,
        passenger?.confirmationCode,
        getLanguageByCode(passenger?.preferredLanguageCode),
      ]);
      acc.push(...coPassengerRows);

      return acc;
    }, []);
  
    downloadCSVFile({
      data: [...headers, ...passengersCsv].join('\n'),
      fileName: 'passengerList.csv',
      fileType: 'text/csv',
    })
  }

  const convertPassengerDataXlsx = (passengers) => {
    
    // Headers for XLSX file
    let headers = ['First Name', 'Last Name', 'Email Id', 'Booking Id', 'Preferred Language'];
    const passengersXlsx = passengers?.reduce((acc, passenger) => {
      // Add lead passenger data
      const leadPassengerRow = [
        passenger?.leadPassengerFirstName,
        passenger?.leadPassengerLastName,
        passenger?.leadPassengerEmail,
        passenger?.confirmationCode,
        getLanguageByCode(passenger?.preferredLanguageCode),
      ];
      acc.push(leadPassengerRow);

      // Add co-passenger data
      const coPassengerRows = passenger.coPassengers.map(coPassenger => [
        coPassenger?.first_name,
        coPassenger?.last_name,
        coPassenger?.email,
        passenger?.confirmationCode,
        getLanguageByCode(passenger?.preferredLanguageCode),
      ]);
      acc.push(...coPassengerRows);

      return acc;
    }, []);

    // Download XLSX file
    downloadJsonAsXlsx({
      data: [headers, ...passengersXlsx],
      fileName: 'passengerList.xlsx',
    });
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell sx={{ width: '15%' }}>Layover Id</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Layover Date</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Invoice Uploaded On</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>PAX List</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '25%', textAlign:'center' }}>Hotel Name</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Current Invoice Stage</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ textAlign:'center' }}>Additional Files</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          
          {invoices?.length > 0 ? (
            <TableBody>
              {invoices
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => (
                  <TableRow
                    key={`b-m-i-${index}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <StyledTableCell sx={{width: '15%'}}>
                      <Link 
                        to={`/layovers/${row?.layoverId}/details`} 
                        style={{ color: 'inherit', textDecoration: 'none' }}
                      >
                        {row?.flightCode}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell sx={{width: '15%', textAlign: 'center'}}>{moment(row?.invoiceDate).format('YYYY-MM-DD')}</StyledTableCell>
                    <StyledTableCell sx={{width: '15%', textAlign: 'center'}}>
                      {row?.invoice?.createdAt ? moment(row?.invoice?.createdAt).format('YYYY-MM-DD') : '-'}
                    </StyledTableCell>
                    <StyledTableCell sx={{textAlign: 'center'}}>
                      {row?.passengerInfo?.totalRegisteredPx ? (
                        <Dropdown
                          options={[
                            {
                              key: 1,
                              name: 'Download as Excel',
                              onClickOption: ()=> convertPassengerDataXlsx(row?.passengerInfo?.passengers)
                            },
                            {
                              key: 2,
                              name: 'Download as CSV',
                              onClickOption: ()=> convertPassengerDataCSV(row?.passengerInfo?.passengers)
                            },
                          ]}
                          text={
                            <Typography  variant='body2' sx={{cursor: 'pointer', color: theme.palette.primary.light}}>
                              {row?.passengerInfo?.totalRegisteredPx}
                            </Typography>
                          }
                        />
                      ) : (
                        <Box sx={{textAlign: 'center'}}>-</Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell sx={{width: '25%', textAlign: 'center'}}>{row?.hotelName}</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'center' }}>
                      {row?.invoiceStage === 'SUBMITTED' ? (
                        <Link to={`/billing/${row?.id}`} style={{textAlign: 'center', textDecoration: 'none',color: theme.palette.success.main}}>
                          Invoice Submitted
                        </Link>
                      ) : (
                        <Typography sx={{textAlign: 'center', color: theme.palette.roseGold[100]}}>
                          In Process
                        </Typography>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box>
                        {row?.additionalDocuments ? row.additionalDocuments?.map((file, index) => (
                          <Grid container key={index} sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography 
                              variant='body2' 
                              sx={{
                                pl: 1, width: theme.spacing(17), 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis', 
                                overflow: 'hidden',
                                cursor: 'pointer',  
                                textAlign: 'center',
                                lineHeight: theme.lineHeight[1.37],
                                color: theme.palette.primary.light
                              }}
                              onClick={()=>handleDownloadAdditionalDoc(file.url, file?.name)}
                            >
                              {index + 1}. {file?.name}
                            </Typography>
                          </Grid>
                        )) : (
                          <Box sx={{textAlign: 'center'}}>-</Box>
                        )}
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          ):(null)}
        </Table>
        {!invoices?.length > 0 ? (
          <Box sx={{ width:'100%', py: 28, display: 'flex', justifyContent: 'center' }}>
            <LargeHeading title='Your airline does not yet have layover bookings.' style={{ textAlign: 'center' }} />
          </Box>
        ):(null)}
      </TableContainer>

      <Box sx={{ mt: 3.5, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={Math.ceil(invoices?.length / rowsPerPage)}
          page={page + 1}
          variant='outlined'
          shape='rounded'
          onChange={handlePageChange}
          hidePrevButton
          hideNextButton
          sx={{
            '& .MuiPaginationItem-root': {
              backgroundColor: theme.palette.grey[800],
              color: theme.palette.background.default,
              border: 'none',
              ':hover':{
                backgroundColor: theme.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[400],
                color: theme.palette.grey[800],
              }
            },
            '& .MuiPaginationItem-root.Mui-selected': {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.background.default,
              border: 'none',
              ':hover':{
                backgroundColor: theme.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[400],
                color: theme.palette.grey[800],
              }
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default BillingTable;

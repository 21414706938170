import { forwardRef, memo, useEffect, useState } from 'react';
import { Box, TableCell, TableRow, Typography, styled, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { InnerSwitch } from '../../../components';
import AirlineContractPreNegotiated from './airlineContractPreNegotiated';

const pricingOptions = [
  {
    key: 'FV',
    name: 'Fixed Rate'
  },
  {
    key: 'PV',
    name: '% Discount'
  }
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottomColor: theme.mode === 'light' ? theme.palette.primary.main : theme.palette.table.divider
}));

const AirlineContractRow = forwardRef((props, ref) => {
  const { setValue } = useFormContext();
  const { row, roomTypes, currency, permission } = props;
  const theme = useTheme();
  const [isBookingsOpen, setIsBookingsOpen] = useState(1);
  const [isPreNegotiated, setIsPreNegotiated] = useState(0);

  useEffect(() => {
    if (row?.contract) {
      setIsPreNegotiated(row?.contract?.is_prenegotiated_rates ? Number(row?.contract.is_prenegotiated_rates) : 0)
      setValue(`formAirlineContracts[${row?.id}][is_prenegotiated_rates]`, row?.contract?.is_prenegotiated_rates ? Number(row?.contract.is_prenegotiated_rates) : 0);
      setIsBookingsOpen(row?.contract.is_bookings_open)
      setValue(`formAirlineContracts[${row?.id}][is_bookings_open]`, row?.contract.is_bookings_open);
    }
  }, [row, setValue])

  return (
    <TableRow
      ref={ref}
      sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '100px', }}
    >
      <StyledTableCell scope='row' sx={{p:0}}>
        {row?.name}
      </StyledTableCell>
      <StyledTableCell>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mr: theme.spacing(2)
        }}>
          <Controller
            name={`formAirlineContracts[${row?.id}][is_bookings_open]`}
            defaultValue={isBookingsOpen}
            render={({ field }) => (
              <InnerSwitch
                formControlLabelSx={{
                  mb: theme.spacing(1)
                }}
                inputRef={field.ref}
                {...field}
                disabled={!permission?.edit}
                checked={field.value}
                onChange={(e) => {
                  setIsBookingsOpen(e.target.checked)
                  field.onChange(e)
                }}
              />
            )}
          />
          <Typography sx={{
            ...theme.typography.textxxSmall,
            textAlign: 'center'
          }} variant='caption' >Open</Typography>
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          mr: theme.spacing(2)
        }}>
          <Typography sx={{
            ...theme.typography.textxxSmall,
            textAlign: 'center'
          }} variant='caption'>No</Typography>
          <Controller
            name={`formAirlineContracts[${row?.id}][is_prenegotiated_rates]`}
            defaultValue={isPreNegotiated}
            render={({ field }) => (
              <InnerSwitch
                formControlLabelSx={{
                  mx: theme.spacing(1)
                }}
                inputRef={field.ref}
                {...field}
                disabled={(!isBookingsOpen) || (!permission?.edit)}
                checked={field.value}
                onChange={(e) => {
                  setIsPreNegotiated(e.target.checked)
                  field.onChange(e)
                }}
              />
            )}
          />
          <Typography sx={{
            ...theme.typography.textxxSmall,
            textAlign: 'center'
          }} variant='caption'>Yes</Typography>
        </Box>
      </StyledTableCell>
      {roomTypes.map((roomType, index) => {
        return (
          <StyledTableCell>
            <AirlineContractPreNegotiated
              sxProp={{...(isPreNegotiated && isBookingsOpen ? { visibility: 'initial' } : { visibility: 'hidden' })}}
              selectDefault={pricingOptions[0].key}
              selectOptions={pricingOptions}
              currency={currency}
              id={row?.id}
              entity='roomTypes'
              roomTypeId={roomType.id}
              contract={row?.contract}
              permission={permission}
            />
          </StyledTableCell>
        )
      })}
      <StyledTableCell>
        <AirlineContractPreNegotiated
          sxProp={{...(isPreNegotiated && isBookingsOpen ? { visibility: 'initial' } : { visibility: 'hidden' })}}
          selectDefault={pricingOptions[0].key}
          selectOptions={pricingOptions}
          currency={currency}
          id={row?.id}
          entity='breakfast'
          contract={row?.contract}
          permission={permission}
        />
      </StyledTableCell>
      <StyledTableCell>
        <AirlineContractPreNegotiated
          sxProp={{...(isPreNegotiated && isBookingsOpen ? { visibility: 'initial' } : { visibility: 'hidden' })}}
          selectDefault={pricingOptions[0].key}
          selectOptions={pricingOptions}
          currency={currency}
          id={row?.id}
          entity='lunch'
          contract={row?.contract}
          permission={permission}
        />
      </StyledTableCell>
      <StyledTableCell>
        <AirlineContractPreNegotiated
          sxProp={{...(isPreNegotiated && isBookingsOpen ? { visibility: 'initial' } : { visibility: 'hidden' })}}
          selectDefault={pricingOptions[0].key}
          selectOptions={pricingOptions}
          currency={currency}
          id={row?.id}
          entity='dinner'
          contract={row?.contract}
          permission={permission}
        />
      </StyledTableCell>
      <StyledTableCell>
        <AirlineContractPreNegotiated
          sxProp={{...(isPreNegotiated && isBookingsOpen ? { visibility: 'initial' } : { visibility: 'hidden' })}}
          selectDefault={pricingOptions[0].key}
          selectOptions={pricingOptions}
          currency={currency}
          id={row?.id}
          entity='snacks'
          contract={row?.contract}
          permission={permission}
        />
      </StyledTableCell>
    </TableRow>
  );
});

export default memo(AirlineContractRow);
import { useContext, useEffect, useState, useCallback } from 'react';
import { useTheme, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, Grid, Typography, IconButton, Pagination } from '@mui/material';
import { SuperAdminContext } from '../../../context/superAdmin';
import { useLoader } from '../../../providers/loader';
import { Heading, LargeHeading } from '../components/heading';
import { symbol as getCurrencySymbol } from 'better-currency-codes';
import { getAllInvoices } from '../../../services/hotel/hotelBillingManagement';
import downloadPDF from '../../../utils/downloadPDF';
import { formatPrice, prepareImageUrlByPath } from '../../../utils';
import moment from 'moment';

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontSemiBold500,
  textTransform: 'uppercase',
  color: theme.palette.table.header,
  borderBottom: 'unset',
  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottomColor:theme.mode === 'light' ? theme.palette.primary.main : theme.palette.table.divider,
  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
}));

const Invoices = () => {
  const theme = useTheme();
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const { setIsCommonTopbar } = useContext(SuperAdminContext);
  const { startLoader, stopLoader } = useLoader();

  useEffect(() => {
    setIsCommonTopbar(false)
  }, [setIsCommonTopbar])

  /**
   * Get currency symbol
   * 
   * @param {*} currencyCode  Currency Code
   */
  const getHotelCurrencySymbol = (currencyCode) => {
    let currency = '';
    if (currencyCode) {
      const currencySymbol = getCurrencySymbol({ code: currencyCode, numeric: false });
      currency = currencySymbol?.native ? currencySymbol.native : currencyCode;
    }
    return currency;
  };
  
  const handlePageChange = useCallback((e, newPage) => {
    console.log('newPage', e, newPage)
    setPage(newPage);
    getAllInvoicesFromApi(newPage, pageSize)
  }, [pageSize]);

  /**
   * Get invoices from API
   */
  const getAllInvoicesFromApi = useCallback(async (page, pageSize) => {
    try {
      const request = {
        'pagination[page]': page,
        'pagination[pageSize]': pageSize
      };
      const response = await getAllInvoices({params: request});
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  const handleDownloadAdditionalDoc = async (url, fileName) => {
    const tempUrl = await (process.env.REACT_APP_ENVIRONMENT === 'LOCAL') ? prepareImageUrlByPath(url) : url
    await downloadPDF(tempUrl, fileName)
  }

  useEffect(() => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        const response = await getAllInvoicesFromApi(page + 1, pageSize)
        resolve(response)
      } catch (e) {
        reject(e)
      }
    }).then(response => {
      if (response?.data && response.data.length) {
        setInvoices(response.data);
      } else {
        setInvoices([]);
      }
      console.log('response', response)
      if (response?.meta?.pagination) {
        setPageSize(response.meta.pagination.pageSize)
        setTotalPages(response.meta.pagination.total)
      }
    }).catch((e) => {
      setInvoices([])
    }).finally(() => {
      stopLoader();
    });
  }, [startLoader, stopLoader, page, pageSize]);
console.log('totalPages', totalPages, page)

  return (
    <Box sx={{ px: 2, py: 1, backgroundColor: theme.mode === 'light' && theme.palette.common.white, borderRadius: theme.borderRadius[10] }}>
      <Heading title='Invoices' />

      <Box sx={{ py: 2 }}>
        {invoices?.length > 0 ? (
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='Invoices'>
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell sx={{ width: '15%', pl: 3 }}>Invoice No</StyledTableHeaderCell>
                    <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Date</StyledTableHeaderCell>
                    <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Airline</StyledTableHeaderCell>
                    <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Hotel</StyledTableHeaderCell>
                    <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Airport</StyledTableHeaderCell>
                    <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Booking Revenue</StyledTableHeaderCell>
                    <StyledTableHeaderCell sx={{ textAlign:'center' }}>Additional Files</StyledTableHeaderCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {invoices.map((row, index) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                        <StyledTableCell sx={{width: '10%', pl: 3}}>
                          {row?.invoice_id}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: '15%', textAlign: 'center'}}>
                          {moment(row?.invoice_date).format('YYYY-MM-DD')}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: '15%', textAlign: 'center'}}>
                          {row?.airline?.name}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: '15%', textAlign: 'center'}}>
                          {row?.hotel?.name}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: '15%', textAlign: 'center'}}>
                          {row?.airport?.name}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: '15%', textAlign: 'center'}}>
                          {`${row?.currency?.alphabetic_code ? getHotelCurrencySymbol(row.currency.alphabetic_code) : ''}${formatPrice(row?.booking_revenue)}`}
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign:'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Box>
                            {row?.additionalDocuments ? row.additionalDocuments?.map((file, index) => (
                              <Grid container key={index} sx={{display: 'flex', alignItems: 'center'}}>
                                <Grid item xs={12}>
                                  <Typography 
                                    variant='body2' 
                                    sx={{
                                      pl: 1, width: theme.spacing(17), 
                                      whiteSpace: 'nowrap', 
                                      textOverflow: 'ellipsis', 
                                      overflow: 'hidden',
                                      cursor: 'pointer',  
                                      textAlign: 'left',
                                      color: theme.palette.primary.light
                                    }}
                                    onClick={()=>handleDownloadAdditionalDoc(file.url, file?.name)}
                                  >
                                    {index + 1}. {file?.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )) : null}
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ mt: 3.5, display: 'flex', justifyContent: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                variant='outlined'
                shape='rounded'
                onChange={handlePageChange}
                hidePrevButton
                hideNextButton
                sx={{
                  '& .MuiPaginationItem-root': {
                    backgroundColor: theme.palette.grey[800],
                    color: theme.palette.background.default,
                    border: 'none',
                    ':hover':{
                      backgroundColor: theme.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[400],
                      color: theme.palette.grey[800],
                    }
                  },
                  '& .MuiPaginationItem-root.Mui-selected': {
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.background.default,
                    border: 'none',
                    ':hover':{
                      backgroundColor: theme.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[400],
                      color: theme.palette.grey[800],
                    }
                  },
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box sx={{ width:'100%', py: 4, px: 1, display: 'flex', justifyContent: 'center' }}>
            <LargeHeading title='No invoice found.' />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Invoices;

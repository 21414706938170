import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Loader from './components/loader';
import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: "https://eu.i.posthog.com",
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  (process.env?.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'LOCAL') ? (
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  ) : (process.env?.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' && process.env.REACT_APP_PUBLIC_POSTHOG_KEY) ? (
    <React.StrictMode>
      <Suspense fallback={<Loader />}>
        <PostHogProvider 
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <App />
        </PostHogProvider>
      </Suspense>
    </React.StrictMode>
  ) : (
    <React.StrictMode>
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    </React.StrictMode>
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import { Box, CssBaseline, useTheme } from '@mui/material';
import SideBar from './sidebar';
import Header from './header';
import MobileHeader from './mobile/header';

const drawerWidth = 212;
const drawerClosedWidth = 65;

const CommonLayout = (props) => {
  const { children, menus, pageTitle, isCommonTopbar = true, headerLeftAppbar, headerRightAppbar, headerTopbarSx, customSidebar, termsConditionDialogue, isSidebarOpened } = props;
  const theme = useTheme();
  const { pathname } = useLocation();
  const [sidebarMenus, setSidebarMenus] = useState(null);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(isSidebarOpened !== null ? isSidebarOpened : true);
  }, [isSidebarOpened]);

  useEffect(() => {
    setSidebarMenus(menus ? menus : null);
  }, [menus]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 250);
  }, [pathname]); 

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />
      {isMobile || isTablet ? <></> 
        : customSidebar ? (
        <customSidebar.type {...customSidebar.props} drawerWidth={drawerWidth} drawerClosedWidth={drawerClosedWidth} open={open} setOpen={setOpen} />
      ) : (
        <SideBar menus={sidebarMenus} drawerWidth={drawerWidth} drawerClosedWidth={drawerClosedWidth} open={open} setOpen={setOpen} />
      )}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: (isMobile || isTablet) ? '100%' : `calc(100% - ${open ? drawerWidth : drawerClosedWidth}px)`,
        overflowX: (isMobile || isTablet) && 'hidden',
      }}>
        <Box sx={{p: isMobile || isTablet ? 0 : 1}}>
          {isMobile || isTablet ? 
            <Box sx={{width: '100%', position: 'fixed', zIndex: 2, display: 'contents'}}>
              <MobileHeader 
                pageTitle={pageTitle}
                isCommonTopbar={isCommonTopbar}
                headerLeftAppbar={headerLeftAppbar}
                headerRightAppbar={headerRightAppbar}
                headerTopbarSx={headerTopbarSx}
              />
            </Box>
            :
            <Header
              pageTitle={pageTitle}
              isCommonTopbar={isCommonTopbar}
              headerLeftAppbar={headerLeftAppbar}
              headerRightAppbar={headerRightAppbar}
              headerTopbarSx={headerTopbarSx}
            />
          }
        </Box>
        <Box component='main' sx={{ flexGrow: 1, p: 2, mt: 0, zIndex: 1 }}>
          {children}
        </Box>
      </Box>
      {termsConditionDialogue ? (<termsConditionDialogue.type {...termsConditionDialogue.props} />):('')}
    </Box>
  );
};

export default CommonLayout;
import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Store Booking Details to Layover
 * 
 * @param {*} layoverId   Layover ID
 * @param {*} request     Request
 * @returns 
 */
export const storeBookingDetailsToLayover = async(layoverId, request = {}) => {
  try {
    const response = await api.post(`/layovers/${layoverId}/booking-details`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get Booking Details from Layover
 * 
 * @param {*} layoverId   Layover ID
 * @param {*} request     Request
 * @returns 
 */
export const getBookingDetailsFromLayover = async(layoverId, request = {}) => {
  try {
    const response = await api.get(`/layovers/${layoverId}/booking-details`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Confirm Layover Booking
 * 
 * @param {*} layoverId   Layover ID
 * @returns 
 */
export const confirmLayoverBooking = async(layoverId) => {
  try {
    const response = await api.post(`/layovers/${layoverId}/booking`);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message && e.response.status !== 424) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Update Layover Booking
 * 
 * @param {*} layoverId   Layover ID
 * @returns 
 */
export const updateLayoverBooking = async(layoverId) => {
  try {
    const response = await api.post(`/layovers/${layoverId}/update-layover`);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message && e.response.status !== 424) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Verify layover booking token
 * 
 * @param {*} token     Booking Token
 * @param {*} request   Request
 * @returns 
 */
export const verifyLayoverBookingToken = async(token, request = {}) => {
  try {
    const response = await api.get(`/layover-bookings/verifyToken/layover/${token}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && (e.response.status !== 404 && e.response.status !== 400) && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Verify layover booking airline token
 * 
 * @param {*} token     Booking Token
 * @param {*} request   Request
 * @returns 
 */
export const verifyLayoverBookingAirlineToken = async(token, request = {}) => {
  try {
    const response = await api.get(`/layover-bookings/verifyToken/airline/${token}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && (e.response.status !== 404 && e.response.status !== 400) && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Check layover booking by token
 * 
 * @param {*} token     Token
 * @param {*} request   Request
 * @returns 
 */
export const checkLayoverBookingByToken = async(token, request = {}) => {
  try {
    const response = await api.get(`/layover-bookings/token/${token}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * get hotels for passenger
 * 
 * @param {*} layoverId     integer
 * @param {*} request   Request
 * @returns 
 */
export const getHotelsForPassenger = async(layoverId, request = {}, preferredLang) => {
  try {
    const response = await api.post(`/layover-bookings/${layoverId}/available-hotels?lang=${preferredLang}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && (e.response.status !== 404 && e.response.status !== 400) && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * confirm the hotels for passenger
 * 
 * @param {*} hotelId     integer 
 * @param {*} request   Request
 * @returns 
 */
export const confirmBookingHotel = async(hotelId, request = {}) => {
  try {
    const response = await api.post(`/layover-bookings/${hotelId}/book-hotels`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && (e.response.status !== 404 && e.response.status !== 400) && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get Passenger Details from booking code
 * 
 * @param {*} bookingCode   booking code
 * @param {*} request     Request
 * @returns 
 */
export const getPassengerDetails = async(bookingCode, request = {}) => {
  try {
    const response = await api.get(`/layover-passenger-bookings/${bookingCode}/get-booking`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && (e.response.status !== 404 && e.response.status !== 400) && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * update passenger mail from booking code
 * 
 * @param {*} layoverId   layover code
 * @param {*} passengerMail passenger mail to update
 * @param {*} request       Request
 * @returns 
 */
export const confirmPassengerEmail = async(layoverId, passengerMail, request = {}) => {
  try {
    const response = await api.get(`/layover-bookings/${layoverId}/check-passenger-booking?email=${passengerMail}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && (e.response.status !== 404 && e.response.status !== 400) && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * update passenger mail from booking code
 * 
 * @param {*} bookingCode   booking code
 * @param {*} request       Request
 * @returns 
 */
export const getUpdatePassengerEmail = async(bookingCode, request = {}) => {
  try {
    const response = await api.post(`/layover-passenger-bookings/${bookingCode}/update-passenger-email`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && (e.response.status !== 404 && e.response.status !== 400) && e?.messageage) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * cancel passenger booking
 * 
 * @param {*} passengerBooking   passenger booking to be deleted
 * @returns 
 */
export const cancelPassengerBooking = async(bookingId) => {
  try {
    const response = await api.delete(`/layover-passenger-bookings/${bookingId}/cancel-booking`);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && (e.response.status !== 404 && e.response.status !== 400) && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
  }
};

/**
 * update passenger booking
 * 
 * @param {*} bookingId   booking id
 * @param {*} request       Request
 * @returns 
 */
export const updatePassengerAllocation = async(bookingId, request = {}) => {
  try {
    const response = await api.post(`/layover-passenger-bookings/${bookingId}/paxReAllocation`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && (e.response.status !== 404 && e.response.status !== 400) && e?.messageage) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};
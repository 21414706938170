import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Chip, Tab, Tabs, styled, useTheme } from '@mui/material';
import HotelTable from './layoverTable';
import { Heading } from '../../components/heading';
import { HotelContext } from '../../../../context/hotel';
import { getLayoversByFilter } from '../../../../services/layover';
import { useLoader } from '../../../../providers/loader';
import { toast } from 'react-toastify';
import { maxToastTime } from '../../../../helper/constant';
import { isMobile, isTablet } from 'react-device-detect';

const StyledTab = styled(Tab)(({ theme }) => ({
  ...theme.typography.body1,
  paddingRight: 0,
  paddingLeft: 0,
  color: theme.palette.table.header,
  marginRight: theme.spacing(4),
  '&.Mui-selected': {
    borderBottomColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    '& .MuiChip-root':{
      background: theme.palette.info.dark,
    }
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  background: theme.palette.grey[800],
  color: theme.palette.common.white,
  height: 'auto',
  paddingTop: theme.spacing(.5),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(.5),
  paddingLeft: theme.spacing(1),
  '& .MuiChip-label': {
    padding: 0
  }
}));

const StyledTabLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'none',
  '& .MuiChip-root': {
    marginLeft: theme.spacing(1)
  }
}));

const LayoverMenu = () => {
  const theme = useTheme()
  const { hotel, setIsCommonTopbar } = useContext(HotelContext);
  const { startLoader, stopLoader } = useLoader();
  const [loading, setLoading] = useState(false);
  const [layovers, setLayovers] = useState({recentLayovers: [], allLayovers: []});
  const [layoversLength, setLayoversLength] = useState({recent:0, all:0});
  const [selectedTab, setSelectedTab] = useState(0);
  const [page, setPage] = useState(0);
  let isCurrentDeviceMobileOrTab = isMobile || isTablet ? true : false;

  useEffect(() => {
    setIsCommonTopbar(false)
  }, [setIsCommonTopbar])

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
    setPage(0);
  }

  /**
   * Call API to get the recent layovers (by filters) from API
   * 
   * @param {*} hotelId   Hotel Id
   * @param {*} request   Request
   */
  const getLayoversByFilterFromApi = useCallback(async (hotelId, request) => {
    try {
      const response = await getLayoversByFilter(hotelId, request);
      return response;
    } catch (e) {
      throw e
    }
  }, [])

  useEffect(() => {
    if (hotel?.id) {
      setLoading(true);
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          // Get recent layovers
          const responseRecentLayovers = await getLayoversByFilterFromApi(hotel.id, { show: 'recent' });
          // Get all layovers
          const responseAllLayovers = await getLayoversByFilterFromApi(hotel.id, { show: 'all' });
          resolve({
            recentLayovers: (responseRecentLayovers?.recentBookingDetails && responseRecentLayovers.recentBookingDetails.length > 0) ? responseRecentLayovers.recentBookingDetails : [],
            allLayovers: (responseAllLayovers?.recentBookingDetails && responseAllLayovers.recentBookingDetails.length > 0) ? responseAllLayovers.recentBookingDetails : []
          })
        } catch (e) {
          reject(e)
        }
      }).then((response) => {
        setLayovers(response);
        setLayoversLength({
          recent: response?.recentLayovers.length || 0,
          all: response?.allLayovers.length || 0
        })
      }).catch((e) => {
        if (e?.response?.data?.error?.message?.toLowerCase()?.includes('not published')) {
          return;
        } else {
          toast.error(e?.response?.data?.error?.message, {
            autoClose: maxToastTime,
            toastId: 'layover-bookings-flag',
          });
        }
      }).finally(() => {
        stopLoader();
        setLoading(false);
      });
    }
  }, [hotel, startLoader, stopLoader, getLayoversByFilterFromApi]);

  return (
    <Box sx={{ width: '100%', px: 2, py: 2, backgroundColor: theme.mode === 'light' && theme.palette.common.white, borderRadius: theme.borderRadius[10] }}>
      {(isCurrentDeviceMobileOrTab || isTablet) ? null : (<Heading title='Layover Management' />)}

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.primary.light
          }
        }}
        sx={{ 
          ml: theme.spacing(isCurrentDeviceMobileOrTab ? 1 : 2),
          mt: theme.spacing(isCurrentDeviceMobileOrTab ? 4 : 1),
          mb: 2
        }}
      >
        <StyledTab
          label={
            <StyledTabLabel as='span'>
              Recent layovers <StyledChip label={layoversLength?.recent || 0} />
            </StyledTabLabel>
          }
        />
        <StyledTab
          label={
            <StyledTabLabel as='span'>
              All layovers <StyledChip label={layoversLength?.all || 0} />
            </StyledTabLabel>
          }
        />
      </Tabs>

      <Box>
        <HotelTable layovers={selectedTab === 0 ? layovers?.recentLayovers : layovers?.allLayovers} page={page} setPage={setPage} 
          noLayoverFoundMessage={loading ? 'Loading Layovers for the Hotel' : selectedTab === 0 ? 'There are no recent layovers for this hotel.' : 'There are no layovers for this hotel.'} 
        />
      </Box>
    </Box>
  )
}

export default LayoverMenu;

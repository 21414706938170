import { Accordion as MUIAccordion, AccordionSummary, AccordionDetails, styled } from '@mui/material';
import { useEffect, useState, memo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// StyledAccordion Component
const StyledAccordion = styled(MUIAccordion)(({ theme }) => ({
  width: '100%',
  borderRadius: '7px',
  boxShadow: 'none',
  borderRadius: theme.borderRadius[10],
  overflow: 'hidden',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 'auto',
  },
  '& .MuiAccordionSummary-root': {
    backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
  },
  '& .css-15v22id-MuiAccordionDetails-root': {
    padding: 0,
  },
  '& .css-o4b71y-MuiAccordionSummary-content': {
    ...theme.typography.fontSemiBold600,
    color: theme.palette.dashboardWidgetHeadline,
  },
}));

// Accordion Component
const Accordion = (props) => {
  const { expanded = true, onChange, summary, details, accordionProps, ...rest } = props;
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const handleChange = (panel) => (event, isExpanded) => {
    setIsExpanded(isExpanded ? panel : false);
    if (onChange) {
      onChange(isExpanded);
    }
  };

  return (
    <StyledAccordion
      expanded={isExpanded}
      onChange={handleChange(true)}
      {...accordionProps}
      {...rest}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails>
        {details}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default memo(Accordion);

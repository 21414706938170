import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CommonLayout from '../common';
import { getMenus, storage } from '../../utils';
import { SuperAdminContext } from '../../context/superAdmin';
import { setUser } from '../../redux/actions/user';
import { updateSelfUser } from '../../services/user';
import { TermsConditionContext } from '../../context/termsCondition';
import TermsConditionDialogue from '../common/termsConditionDialogue';

// passing function from parent to child (YES and NO)
let handleDirtyModalAgree;
const onclickDirtyFormModalAgree = (callback) => {
  handleDirtyModalAgree = callback;
};

let handleDirtyModalDisagree;
const onclickDirtyFormModalDisAgree = (callback) => {
  handleDirtyModalDisagree = callback;
};

const SuperAdminLayout = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [sidebarMenus, setSidebarMenus] = useState(null);
  const [isCommonTopbar, setIsCommonTopbar] = useState(true);
  const [headerPageTitle, setHeaderPageTitle] = useState('');
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [dirtyDialogOpen, setDirtyDialogOpen] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [isSidebarOpened, setIsSidebarOpened] = useState(true);

  useEffect(() => {
    setIsSidebarOpened(storage.getData('sidebarOpened'));
  }, [])
  
  useEffect(() => {
    if (!isCommonTopbar) {
      setHeaderPageTitle('')
    }
  }, [setHeaderPageTitle, isCommonTopbar])

  useEffect(() => {
    if (!user.user?.id) {
      navigate('/user/login');
    }
    if(user.user?.role?.type) {
      const menus = getMenus(user.user.role.type);
      setSidebarMenus(menus);
    }
    setTermsDialogOpen(!user.user?.terms_accepted)
  }, [navigate, user.user])

  /**
   * Update terms_accepted status via udpate self user API
   * 
   * @param {*} 
   */
  const confirmStatus = async () => {
    try {
      await updateSelfUser({terms_accepted: true});
      dispatch(
        setUser({
          ...user,
          user: {
            ...user.user,
            terms_accepted: true
          }
        })
      );
    } catch (e) {}
  };

  return (
    <SuperAdminContext.Provider value={{ setIsCommonTopbar, setHeaderPageTitle, isFormDirty, setIsFormDirty, 
        dirtyDialogOpen, setDirtyDialogOpen, onclickDirtyFormModalAgree, onclickDirtyFormModalDisAgree, handleDirtyModalAgree, handleDirtyModalDisagree,
        isSidebarOpened, setIsSidebarOpened }}>
      <TermsConditionContext.Provider value={{ termsDialogOpen, setTermsDialogOpen}}>
        <CommonLayout
          menus={sidebarMenus}
          isCommonTopbar={isCommonTopbar}
          isSidebarOpened={isSidebarOpened}
          pageTitle={headerPageTitle}
          termsConditionDialogue={<TermsConditionDialogue confirmStatus={confirmStatus}/>}
        >
          {children}
        </CommonLayout>
      </TermsConditionContext.Provider>
    </SuperAdminContext.Provider>
  );
};

export default SuperAdminLayout;

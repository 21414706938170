import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Typography, styled, useTheme } from '@mui/material';

const MenuItemText = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMedium
}));

function Dropdown(props) {
  const { variant, btnColor, startIcon, endIcon, options, text, menuProps, btnSx, itemSx, boxSx, isDivider = false, isReadOnly } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{
      height: '100%',
      ...boxSx
    }}>
      <Button
        buttoncolor={btnColor}
        variant={variant}
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={startIcon ? startIcon : ''}
        endIcon={endIcon ? endIcon : ''}
        aria-readonly={isReadOnly}
        sx={{
          cursor: isReadOnly ? 'default' : 'pointer',
          color: theme.palette.alert.contrastText,
          textTransform: 'capitalize',
          alignItems: 'stretch',
          ...btnSx
        }}
      >
        {text}
      </Button>
      {isReadOnly ? '' : (
        <Menu
          id='demo-positioned-menu'
          aria-labelledby='demo-positioned-button'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          {...menuProps}
          // anchorOrigin={{
          //   vertical: 'top',
          //   horizontal: 'left',
          // }}
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'left',
          // }}
        >
          {options ? (
            options.map((option, index, options) => (
              <MenuItem
                key={`${option.name}_${index}`}
                onClick={() => {
                  handleClose();
                  if (option?.onClickOption) {
                    option.onClickOption(option?.key);
                  }
                }}
                divider={(isDivider && (index + 1 !== options.length)) ? true : false}
                sx={{...itemSx, backgroundColor: theme.mode === 'light' && theme.palette.bg[400]}}
              >
                <MenuItemText>{option.name}</MenuItemText>
              </MenuItem>
            ))
          ) : ('')}
        </Menu>
      )}
    </Box>
  );
}

export default memo(Dropdown);
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AirlineContext } from '../../../../context/airline';
import { Box, IconButton, Tab, Tabs, Typography, styled, useTheme } from '@mui/material';
import { useLoader } from '../../../../providers/loader';
import HotelSelection from './hotelSelection';
import BookingDetails from './bookingDetails';
import ReviewAndConfirm from './reviewAndConfirm';
import { getLayoverDetails } from '../../../../services/layover';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getBookingDetailsFromLayover } from '../../../../services/layover/bookings';
import { AlertDialog } from '../../../../components';
import moment from 'moment';

const StyledTab = styled(Tab)(({ theme }) => ({
  paddingRight: 0,
  paddingLeft: 0,
  marginRight: theme.spacing(3),
  '&.Mui-selected': {
    color: theme.palette.alert.main,
    '& .MuiTypography-root': {
      ...theme.typography.fontMedium600,
      color: theme.palette.menu.dark,
    }
  }
}));

const StyledTabName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  textTransform: 'capitalize',
  color: theme.palette.menu.dark,
  '&.tab-hotel-currency': {
    ...theme.typography.textxxSmall,
    '& .hotel-currency-symbol': {
      ...theme.typography.body1,
      ...theme.typography.fontSemiBold600
    }
  }
}));

function StyledTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}
 
const LayoverHotelsBooking = () => {
  let { layoverId } = useParams();
  const navigate = useNavigate();
  const { setIsCommonTopbar, setIsAirlineDropdownDisabled, setIsAirportDropdownDisabled } = useContext(AirlineContext);
  const theme = useTheme();
  const { startLoader, stopLoader } = useLoader();
  const [isReviewTabDisabled, setIsReviewTabDisabled] = useState(true);
  const [tabValue, setTabValue] = useState('1');
  const [dialogPrevDayLayover, setDialogPrevDayLayover] = useState(false)
  const [layover, setLayover] = useState(null);
  const [isLayoverAccessible, setIsLayoverAccessible] = useState(null);
  // const MAX_TIME_ON_PAGE = 10 * 60 * 1000;
  const today = moment().startOf('day');
  let { state } = useLocation();

  const handlePrevDayLayoverGoBackBtn = () => {
    navigate(`/`);
    setDialogPrevDayLayover(false)
  }

  useEffect(() => {
    setIsCommonTopbar(false);
    setIsAirlineDropdownDisabled(true);
    setIsAirportDropdownDisabled(true);
  }, [setIsCommonTopbar, setIsAirlineDropdownDisabled, setIsAirportDropdownDisabled])

  // useEffect(() => {
  //   if (tabValue === '2' || tabValue === '3') {
  //     const timer = setTimeout(() => {
  //       setTabValue('1')
  //     }, MAX_TIME_ON_PAGE);

  //     return () => clearTimeout(timer);
  //   }
  // }, [tabValue]);

  const handleTabChange = (e, val) => {
    setTabValue(val);
  };

  /**
   * Get layover details from API
   */
  const getLayoverFromApi = useCallback(async (layoverId) => {
    try {
      let request = {
        'populate[0]': 'airline_id',
        'populate[1]': 'airport_id'
      };
      const response = await getLayoverDetails(layoverId, {params: request});
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  useEffect(() => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        const response = await getLayoverFromApi(layoverId)
        if (response && response?.data) {
          setLayover(response.data)
          setIsLayoverAccessible(true)
          if(!today.isSame(response.data.createdAt, 'day')){
            setDialogPrevDayLayover(true)
          }
        }
        resolve()
      } catch (e) {
        setIsLayoverAccessible(false)
        reject(e)
      }
    }).then(response => {
    }).catch((e) => {
    }).finally(() => {
      stopLoader();
    });
  }, [layoverId, startLoader, stopLoader, getLayoverFromApi]);

  /**
   * Get booking details from layover via API
   * 
   * @param {*} layoverId  Layover Id
   */
  const getBookingDetailsFromLayoverViaApi = useCallback(async (layoverId) => {
    try {
      const response = await getBookingDetailsFromLayover(layoverId);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  useEffect(() => {
    if (layoverId) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await getBookingDetailsFromLayoverViaApi(layoverId);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      }).then((response) => {
          if (response && response?.passanger_airport_departure_time && response?.luggage_type) {
            setIsReviewTabDisabled(false)
          }
        }).catch((e) => {
          setIsReviewTabDisabled(true);
        }).finally(() => {
          stopLoader();
        });
    }
  }, [layoverId, startLoader, stopLoader, getBookingDetailsFromLayoverViaApi]);

  // back button added with tab and page navigation
  const handleBackButtonClick = (propTabValue) => {
    if (propTabValue === 1 && state?.updateLayover) {
      navigate(`/layovers/${layoverId}/details`);
    }else if (propTabValue === 3 && state?.updateLayover) {
      setTabValue('1')
    }else if (propTabValue === 1 && !state?.updateLayover) {
      navigate(`/layover/${layoverId}/hotels`);
    } else {
      setTabValue((propTabValue - 1).toString());
    }
  }

  return (
    <Box sx={{p: 2, backgroundColor: theme.mode === 'light' && theme.palette.common.white, borderRadius: theme.borderRadius[10]}}>
      <IconButton sx={{ border: 1, borderRadius:'50%', mb: 3 }} onClick={() => handleBackButtonClick(parseInt(tabValue))}>
        <KeyboardBackspaceIcon />
      </IconButton>

      {(isLayoverAccessible && layover) ? (
        <Box sx={{p: 1, backgroundColor: theme.mode === 'light' && theme.palette.bg[400], borderRadius: theme.borderRadius[10]}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex' }}>
            <Tabs
              value={tabValue}
              variant='scrollable'
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.palette.primary.light
                }
              }}
              scrollButtons={false}
            >
              <StyledTab
                value='1'
                label={
                  <StyledTabName>1. Hotel Selection</StyledTabName>
                }
              />
              {!state?.updateLayover ? (
                <StyledTab
                  value='2'
                  label={
                    <StyledTabName>2. Booking Details</StyledTabName>
                  }
                />
              ) : (null)}
              <StyledTab
                disabled={isReviewTabDisabled}
                value='3'
                label={
                  <StyledTabName>{state?.updateLayover ? '2' : '3'}. Review & Confirm</StyledTabName>
                }
              />
            </Tabs>
          </Box>
          <StyledTabPanel value={tabValue} index='1'>
            <HotelSelection
              layover={layover}
              layoverId={layoverId}
              updateLayover={state?.updateLayover}
              onSave={() => {
                return state?.updateLayover ? setTabValue('3') : setTabValue('2')
              }}
            />
          </StyledTabPanel>
          {!state?.updateLayover ? (
            <StyledTabPanel value={tabValue} index='2'>
              <BookingDetails
                setIsReviewTabDisabled={setIsReviewTabDisabled}
                layoverId={layoverId}
                airlineId={layover?.airline_id?.id}
                onSave={() => setTabValue('3')}
              />
            </StyledTabPanel>
          ) : (null)}
          <StyledTabPanel value={tabValue} index='3'>
            <ReviewAndConfirm
              layoverId={layoverId}
              updateLayover={state?.updateLayover}
            />
          </StyledTabPanel>
        </Box>
      ) : (
        <>
          {isLayoverAccessible === false ? (
            <Box>
              <Typography variant='subtitle2'>
                You do not have access to this layover. Please contact the administrator if you believe this is incorrect.
              </Typography>
            </Box>
          ) : (null)}
        </>
      )}

      <AlertDialog
        title='Room Availability Update'
        dialogTitleProps={{
          sx: {
            ...theme.typography.textxxLarge,
            paddingTop: theme.spacing(5),
            color: theme.palette.roseGold[100],
            paddingBottom: theme.spacing(2)
          }
        }}
        open={dialogPrevDayLayover}
        onDisagree={handlePrevDayLayoverGoBackBtn}
        noBtnText={'Go back to create New Layover'}
        sx={{
          textAlign: 'center'
        }}
        fullWidth={true}
        maxWidth={'xs'}
        stretchBtn
      >
        <Box>
          <Typography sx={{pb:1, lineHeight: theme.lineHeight[1.2]}} variant='body1'>
            This draft layover is outdated and not available any longer. Please create a new layover from the Dashboard page.
          </Typography>
        </Box>
      </AlertDialog>
    </Box>
  );
};

export default LayoverHotelsBooking;
import React, { useCallback, useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, useTheme, Typography, Chip } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from 'react-router-dom';
import { LargeHeading } from '../../../hotel/components/heading';
import { getHotelsInLayover } from '../../../../services/layover/hotels';
import formatTimestamp from '../../../../utils/formatTimestamp';

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  fontWeight: theme.typography.semiBold,
  textTransform: 'uppercase',
  color: theme.palette.table.header,
  borderBottom: 'unset',
  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
}));

const Para = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  color: theme.palette.table.header,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottomColor: theme.palette.divider,
  color: theme.palette.text.primary,
  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
}));

const LayoverTable = ({ layovers, selectedTab, recentPage, setRecentPage, allPage, setAllPage, isCurrentDeviceMobileOrTab, noLayoverFoundMessage }) => {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleChangePage = (event, newPage) => {
    if (selectedTab === 0) {
      setRecentPage(newPage - 1);
    } else {
      setAllPage(newPage - 1);
    }
  };

  const currentPage = selectedTab === 0 ? recentPage : allPage;

  const rowsPerPage = 10;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, layovers.length - page * rowsPerPage);

  const handleRowClick = async (row) => {
    const response = await getHotelsInLayoverViaApi(row?.layoverId);
    if (row?.status === 'draft') {
      if (response?.length === 0) {
        navigate(`/layover/${row?.layoverId}/hotels`);
      } else {
        navigate(`/layover/${row?.layoverId}/booking`);
      }
    } else {
      navigate(`/layovers/${row?.layoverId}/details`);
    }
  };

  /**
   * Get hotels in layover via API
   * 
   * @param {*} layoverId  Layover Id
   */
  const getHotelsInLayoverViaApi = useCallback(async (layoverId) => {
    try {
      const response = await getHotelsInLayover(layoverId);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  const handleStatusClick = async (row) => {
    const response = await getHotelsInLayoverViaApi(row?.layoverId);
    if (row?.status === 'draft') {
      if (response?.length === 0) {
        navigate(`/layover/${row?.layoverId}/hotels`);
      } else {
        navigate(`/layover/${row?.layoverId}/booking`);
      }
    } else {
      navigate(`/layovers/${row?.layoverId}/details`);
    }
  };

  return (
    <Box sx={{ padding: theme.spacing(isCurrentDeviceMobileOrTab ? 1 : 2) }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell sx={{ width: '18%' }}>Booking Date & Time</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '25%', textAlign:'center' }}>Layover Details</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Registered Pax</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '20%', textAlign:'center' }}>Latest Return to Airport</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ textAlign:'center' }}>Status</StyledTableHeaderCell>
            </TableRow>
          </TableHead>

          {layovers && layovers?.length > 0 && (
            <TableBody>
              {layovers.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                  onClick={() => handleRowClick(row)}
                >
                  <StyledTableCell sx={{ width: '18%' }}>{formatTimestamp(row?.bookingDate)}</StyledTableCell>
                  <StyledTableCell sx={{ width: '25%', textAlign:'center' }}>
                    {row?.flightCode} <br />
                    <Para>{row?.toAirportCode}</Para>
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '15%', textAlign:'center' }}>
                    {row?.registeredPax ? row?.registeredPax : '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '20%', textAlign:'center' }}>
                    {row?.isDifferentReturnTimes === null ? row?.latestReturnToAirport : (
                      formatTimestamp(
                        null, // Exclude row?.bookingDate
                        row?.isDifferentReturnTimes,
                        row?.passanger_airport_return_date,
                        row?.passanger_airport_return_time,
                        row?.latestReturnToAirport
                      )
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign:'center' }}>
                    <Chip
                      label={`${row?.status === 'draft' ? 'Draft' : row?.status === 'closed' ? 'Closed' : 'In Progress'}`}
                      color={row?.status === 'draft' ? 'error' : row?.status === 'closed' ? 'warning' : 'success'}
                      onClick={() => handleStatusClick(row)}
                      sx={{height:'auto', py:.5, color: theme.mode === 'light' ? theme.palette.common.white : 'default'}}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>

        {layovers && layovers?.length === 0 && (
          <Box sx={{ width: '100%', py: 28, display: 'flex', justifyContent: 'center' }}>
            <LargeHeading title={noLayoverFoundMessage} />
          </Box>
        )}
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, marginBottom: 2 }}>
        <Pagination
          count={Math.ceil(layovers.length / rowsPerPage)}
          page={currentPage + 1}
          onChange={handleChangePage}
          variant='outlined'
          shape='rounded'
          hidePrevButton
          hideNextButton
          sx={{
            '& .MuiPaginationItem-root': {
              backgroundColor: theme.palette.grey[800],
              color: theme.palette.background.default,
              border: 'none',
              ':hover':{
                backgroundColor: theme.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[400],
                color: theme.palette.grey[800],
              }
            },
            '& .MuiPaginationItem-root.Mui-selected': {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.background.default,
              border: 'none',
              ':hover':{
                backgroundColor: theme.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[400],
                color: theme.palette.grey[800],
              }
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default LayoverTable;

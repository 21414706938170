import { Button, Card, CardActions, CardContent, Divider, Typography, styled, useTheme } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { arrayColumn } from '../../../../utils';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '3px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiCardContent-root': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.palette.text.primary,
  padding: 0
}));
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.mode === 'light' ? theme.palette.sidebarBg : null
}));

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.menu.main,
}));

const FrontDeskUserCard = (props) => {
  const { user, removeUser } = props;
  const theme = useTheme();

  return (
    <StyledCard>
      <StyledCardContent>
        {user?.hotels ? (
          <StyledText variant='caption' sx={{...theme.typography.textMedium, }}>{arrayColumn(user.hotels, 'name').join(', ')}</StyledText>
        ) : ('')}
      </StyledCardContent>
      <Divider />
      <CardActions sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        p: 2,
        backgroundColor: theme.mode === 'light' ? theme.palette.bg[400] : theme.palette.background.default
      }}>
        <Typography
          variant='body1'
          sx={{
            ...theme.typography.textLarge,
            marginBottom: theme.spacing(1)
          }}
        >
          {`${user.first_name} ${user.last_name}`}
        </Typography>
        <Typography
          variant='caption'
          sx={{
            ...theme.typography.textMedium,
            color: theme.palette.grey[500],
            marginBottom: theme.spacing(2)
          }}
          style={{marginLeft: 0}}
        >
          {user.email}
        </Typography>
        <StyledButton
          style={{marginLeft: 0}}
          onClick={() => removeUser(arrayColumn(user.hotels, 'id'), user.id)}
        >
          <RemoveCircleOutlineIcon sx={{mr: 1, color: theme.palette.roseGold[100]}} />
          <Typography
            variant='caption'
            sx={theme.typography.textxxSmall}
          >
            Remove User
          </Typography>
        </StyledButton>
      </CardActions>
    </StyledCard>
  );
};

export default FrontDeskUserCard;
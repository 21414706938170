import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userRoles } from '../helper/constant';
import HotelLayout from '../layouts/hotelView';
import AirlineLayout from '../layouts/airlineView';
import SuperAdminLayout from '../layouts/superAdminView';
import { getPathFromURL, storage } from '../utils';
import { checkPermissions } from '../utils/checkPermissions';

const AuthenticatedRoute = (props) => {
  const { userRole } = props;
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const path = getPathFromURL(window.location.href)
  const permission = checkPermissions(path)
  let currentStoredSaProfile = null;
  if (userRole === userRoles.superAdmin && storage.getData('superAdminActiveProfile')) {
    currentStoredSaProfile = storage.getData('superAdminActiveProfile');
  }

  useEffect(() => {
    if (!user?.user || !user?.user?.id) {
      navigate('/user/login');
    }
  }, [user, navigate])

  const renderSwitch = (role) => {
    switch(true) {
      case role === userRoles.hotelFrontDesk:
      case role === userRoles.hotelAdmin:
      case (role === userRoles.superAdmin && currentStoredSaProfile === userRoles.hotelAdmin):
        return (
          <HotelLayout>
            <Outlet context={[permission]}/>
          </HotelLayout>
        );
      case role === userRoles.airlineGroundStaff:
      case role === userRoles.airlineAdmin:
        case (role === userRoles.superAdmin && currentStoredSaProfile === userRoles.airlineAdmin):
        return (
          <AirlineLayout>
            <Outlet context={[permission]}/>
          </AirlineLayout>
        );
      case role === userRoles.superAdmin:
        return (
          <SuperAdminLayout>
            <Outlet context={[permission]}/>
          </SuperAdminLayout>
        );
      default:
        return (
          <Navigate to='/' />
        );
    }
  }

  return renderSwitch(userRole);
}

export default AuthenticatedRoute;
import { useState, useCallback, useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Heading } from '../components/heading';
import downloadPDF from '../../../utils/downloadPDF';
import PdfViewer from '../../../components/pdfViewer';
import { ActionButton, AlertDialog } from '../../../components';
import { checkPermissions } from '../../../utils/checkPermissions';
import { getInvoiceDetails, updateInvoiceStatus } from '../../../services/hotel/hotelBillingManagement';
import { useLoader } from '../../../providers/loader';
import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../../../helper/constant';
import { deleteImage } from '../../../services/hotel';
import { prepareImageUrlByPath } from '../../../utils';

const InvoiceDetails = () => {
  const theme = useTheme();
  const { invoiceId } = useParams()
  const navigate = useNavigate()
  const billingMangementDeleteInvoiceButton = checkPermissions('billingMangementDeleteInvoiceButton')
  const { startLoader, stopLoader } = useLoader();
  const [billingDetails, setBillingDetails] = useState(null)
  const [pdfUrl, setPdfUrl] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)

  // download invoice
  const handleDownloadInvoice = async (pdfUrl) => {
    await downloadPDF(pdfUrl);
  };
  
  /**
   * get hotel invoice data from API
   */
  const getInvoiceDetailsFromApi = useCallback(async (hotelId) => {
    try {
      const response = await getInvoiceDetails(hotelId);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

   /**
   *  delete upload invoice from API
   */
   const deleteInvoiceFromApi = useCallback(async (id) => {
    try {
      const response = await deleteImage(id);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * upload additional files
   */
  const updateInvoiceStatusFromApi = useCallback(async (id, request) => {
    try {
      const response = await updateInvoiceStatus(id, request);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  // delete the invoice also changes the invoice status and rediect to billing management
  const handleDeleteInvoice = async (invoiceId, id) => {
    startLoader();
    new Promise(async (resolve, reject) => {
      const response = await deleteInvoiceFromApi(id);
      if(response){
        try{
          await updateInvoiceStatusFromApi(invoiceId, {current_invoice_stage: 'IN_PROGRESS'})
          setPdfUrl('')
          navigate('/billing')
        }catch(err){
          reject(err)
        }
      }
      resolve(response)
    }).then((res) =>{
      toast.success(toastMessages.hotel.BillingManagementUploadInvoice.deleteSuccess, {
        autoClose: maxToastTime,
        toastId: 'invoice-removed-success'
      })
    }).catch((e) => {
    }).finally(() => {
      stopLoader();
    });
  };

  useEffect(() => {
    if (invoiceId) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await getInvoiceDetailsFromApi(invoiceId)
          resolve(response)
        } catch (e) {
          reject(e)
        }
      }).then(response => {
        setBillingDetails(response?.data)
        if(response?.data?.invoice?.url){
          const tempUrl = process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(response?.data?.invoice?.url) : response?.data?.invoice?.url
          setPdfUrl(tempUrl)
        }
      }).catch((e) => {
      }).finally(() => {
        stopLoader();
      });
    }
  }, [startLoader, stopLoader]);

  return (
    <Box sx={{ px: 3, my: 2 }}>
      <Grid container spacing={2}>
        <Grid md={6} sx={{ overflow: 'hidden', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1,
              p: 1,
            }}
          >
            <Heading title='Invoice Details' />
            { billingMangementDeleteInvoiceButton?.view ? (
              <ActionButton
                startIcon={<DeleteIcon sx={{ color: theme.palette.roseGold[100] }} />}
                onClick={()=>setDialogOpen(true)}
                sx={{
                  ...theme.typography.textLarge
                }}
              >Delete Invoice</ActionButton>
            ):(null)}
          </Box>
        </Grid>

        <Grid md={6} sx={{ overflow: 'hidden' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1,
              p: 1,
            }}
          >
            <Heading title='PDF Preview' />
            <ActionButton
              startIcon={<FileDownloadIcon sx={{ color: theme.palette.roseGold[100] }} />}
              onClick={()=>handleDownloadInvoice(pdfUrl)}
              sx={{
                ...theme.typography.textLarge
              }}
            >Download PDF</ActionButton>
          </Box>

          <PdfViewer pdfUrl={pdfUrl} />
        </Grid>
      </Grid>

      {/* Render Confirm Dialog */}
      <AlertDialog
        title='Are you sure?'
        open={dialogOpen}
        onAgree={() => handleDeleteInvoice(billingDetails?.id, billingDetails?.invoice?.id)}
        onDisagree={() => setDialogOpen(false)}
        yesBtnText='Yes, Delete'
        noBtnText='No, Go back'
        sx={{
          textAlign: 'center'
        }}
        fullWidth={true}
        maxWidth={'xs'}
        stretchBtn
      >
        <Typography sx={{lineHeight: '1.37rem', py: 2}} variant='subtitle2'>
          Are you sure, you want to delete Invoice permanently?
        </Typography>
      </AlertDialog>
    </Box>
  );
};

export default InvoiceDetails;

import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, Divider, Grid, styled, useTheme } from '@mui/material';
import { useLoader } from '../../../../providers/loader';
import { HeadingInput } from '../../components/headingInput';
import { Button, Datepicker, Input, Radio, Select, Timepicker } from '../../../../components';
// import { IMaskInput } from 'react-imask';
import { InputEndAdornment } from '../../components/endAdornment';
import { SubHeading } from '../../components/heading';
import moment from 'moment';
import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../../../../helper/constant';
import { getBookingDetailsFromLayover, storeBookingDetailsToLayover } from '../../../../services/layover/bookings';
import dayjs from 'dayjs';
import { getAirlineDetails } from '../../../../services/airline';

export const luggageOptions = [
  {
    key: 'HAND',
    name: 'Hand-luggage only'
  },
  {
    key: 'HAND_AND_1_CHECKED',
    name: '1 checked bag + hand-luggage'
  },
  {
    key: 'HAND_AND_2_CHECKED',
    name: '2 checked bags + hand-luggage'
  }
];

const StyledHeadingInput = styled(HeadingInput)(({ theme }) => ({
  ...theme.typography.textSmall
}));

// const MaskCustomInput = forwardRef(function MaskCustomInput(props, ref) {
//   const { onChange, ...other } = props;
//   return (
//     <IMaskInput
//       {...other}
//       mask='(000) 000-0000'
//       unmask={true}
//       inputRef={ref}
//       onAccept={(value) => onChange({ target: { name: props.name, value } })}
//       overwrite
//     />
//   );
// })

const BookingDetails = (props) => {
  const { layoverId, airlineId, onSave, setIsReviewTabDisabled } = props;
  const formRef = useRef(null);
  const theme = useTheme();
  const { startLoader, stopLoader } = useLoader();
  const [resetFormData, setResetFormData] = useState({formBookingDetails: {}});
  const [isWakeUpCall, setIsWakeUpCall] = useState(0);
  const [airline, setAirline] = useState({});

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setFocus,
    setValue,
    getValues,
    trigger,
    watch
  } = useForm({
    shouldUnregister: true,
    mode: 'all'
  });

  // enabling the review tab if input values there
  useEffect(() => {
    if (getValues('formBookingDetails[luggage_type]') && getValues('formBookingDetails[passanger_airport_departure_time]')) {
      setIsReviewTabDisabled(false);
    }
  }, [getValues, setIsReviewTabDisabled]);

  /**
   * Get airline details via API
   * 
   * @param {*} airlineId   Airline Id
   */
  const getAirlineDetailsViaApi = useCallback(async (airlineId) => {
    try {
      let request = {
        'populate[0]': 'billing_city',
        'populate[1]': 'billing_country'
      };
      const response = await getAirlineDetails(airlineId, {params: request});
      return response
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Store booking details to layover via API
   * 
   * @param {*} layoverId  Layover Id
   * @param {*} request    Request
   */
  const storeBookingDetailsToLayoverViaApi = useCallback(async (layoverId, request) => {
    try {
      const response = await storeBookingDetailsToLayover(layoverId, request);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Get booking details from layover via API
   * 
   * @param {*} layoverId  Layover Id
   */
  const getBookingDetailsFromLayoverViaApi = useCallback(async (layoverId) => {
    try {
      const response = await getBookingDetailsFromLayover(layoverId);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  // Call API's on form submit
  const onSubmit = useCallback(async (data) => {
    if (data?.formBookingDetails) {
      let request = {...data?.formBookingDetails};
      request.request_wake_up_call = Number(request.request_wake_up_call);
      // Convert timings
      if (request.request_wake_up_call) {
        if (data.formBookingDetails?.wake_up_call_time?.$d) {
          request.wake_up_call_time = moment(data.formBookingDetails.wake_up_call_time.$d).format('HH:mm:ss.SSS')
        }
      } else {
        delete request?.wake_up_call_time;
      }
      let departure_date = data.formBookingDetails?.passanger_airport_departure_date?.$d ? data.formBookingDetails?.passanger_airport_departure_date?.$d : dayjs(new Date());
      if (departure_date && data.formBookingDetails?.passanger_airport_departure_time?.$d) {
        request.passanger_airport_departure_date = moment(departure_date).format('YYYY-MM-DD')
        request.passanger_airport_departure_time = moment(data.formBookingDetails.passanger_airport_departure_time.$d).format('HH:mm:ss.SSS')
      } else {
        delete request?.passanger_airport_departure_date;
        delete request?.passanger_airport_departure_time;
      }
      if (data.formBookingDetails?.passanger_airport_return_date?.$d && data.formBookingDetails?.passanger_airport_return_time?.$d) {
        request.passanger_airport_return_date = moment(data.formBookingDetails.passanger_airport_return_date.$d).format('YYYY-MM-DD')
        request.passanger_airport_return_time = moment(data.formBookingDetails.passanger_airport_return_time.$d).format('HH:mm:ss.SSS')
      } else {
        delete request?.passanger_airport_return_date;
        delete request?.passanger_airport_return_time;
      }

      // Call API to store details
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          await storeBookingDetailsToLayoverViaApi(layoverId, request)
          resolve()
        } catch (e) {
          reject()
        }
      }).then(response => {
        setIsReviewTabDisabled(false);
        toast.success(toastMessages.airline.storeBookingDetailsToLayover.success, {
          autoClose: maxToastTime,
          toastId: 'update-airline-store-booking-details-success',
        })
        if (onSave) { onSave() }
      }).catch((e) => {
        toast.error(toastMessages.airline.storeBookingDetailsToLayover.error, {
          autoClose: maxToastTime,
          toastId: 'update-airline-store-booking-details-error',
        })
      }).finally(() => {
        stopLoader();
      });
    }
  }, [startLoader, stopLoader, layoverId, storeBookingDetailsToLayoverViaApi, setIsReviewTabDisabled, onSave]);

  useEffect(() => {
    // Call API to get booking details
    startLoader();
    new Promise(async (resolve, reject) => {
      let responseAirline = {};
      try {
        if (airlineId) {
          responseAirline = await getAirlineDetailsViaApi(airlineId);
          if (responseAirline && responseAirline?.data) {
            setAirline(responseAirline.data)
          }
        }
        const response = await getBookingDetailsFromLayoverViaApi(layoverId)
        resolve({
          bookingDetails: response,
          airline: responseAirline?.data
        })
      } catch (e) {
        reject({
          error: e,
          airline: responseAirline?.data
        })
      }
    }).then(response => {
      let tempResetFormData = {
        formBookingDetails: {...resetFormData.formBookingDetails}
      };
      // If bookingDetails exists, then set values as per the booking details otherwise set details from airline
      // NOTE: BOOKING DETAILS ARE PRESENT IN THE LAYOVER TABLE IN THE BACKEND, HENCE IT IS HARD TO DETECT THAT BOOKING DETAILS EXISTS OR NOT. SO, WE WILL CONSIDER IF COMPANY NAME & BILLING NAME EXISTS IT MEANS BOOKING DETAILS EXISTS
      if (response.bookingDetails?.billing_name) {
        tempResetFormData.formBookingDetails = {...tempResetFormData.formBookingDetails, ...response.bookingDetails};
        tempResetFormData.formBookingDetails['passanger_airport_departure_date'] = response.bookingDetails?.passanger_airport_departure_date ? dayjs(response.bookingDetails.passanger_airport_departure_date, 'YYYY-MM-DD') : '';
        tempResetFormData.formBookingDetails['passanger_airport_departure_time'] = response.bookingDetails?.passanger_airport_departure_time ? dayjs(response.bookingDetails.passanger_airport_departure_time, 'HH:mm:ss') : '';
        tempResetFormData.formBookingDetails['passanger_airport_return_date'] = response.bookingDetails?.passanger_airport_return_date ? dayjs(response.bookingDetails.passanger_airport_return_date, 'YYYY-MM-DD') : '';
        tempResetFormData.formBookingDetails['passanger_airport_return_time'] = response.bookingDetails?.passanger_airport_return_time ? dayjs(response.bookingDetails.passanger_airport_return_time, 'HH:mm:ss') : '';
        tempResetFormData.formBookingDetails['different_return_times'] = response.bookingDetails?.different_return_times ? response.bookingDetails.different_return_times : '';
        tempResetFormData.formBookingDetails['request_wake_up_call'] = response.bookingDetails?.request_wake_up_call ? 1 : 0;
        setIsWakeUpCall(response.bookingDetails?.request_wake_up_call ? 1 : 0);
        tempResetFormData.formBookingDetails['wake_up_call_time'] = response.bookingDetails?.wake_up_call_time ? dayjs(response.bookingDetails.wake_up_call_time, 'HH:mm:ss') : '';
      } else if (response?.airline && Object.keys(response.airline).length > 0) {
        tempResetFormData.formBookingDetails['billing_name'] = response.airline?.billing_name ? response.airline.billing_name : '';
        tempResetFormData.formBookingDetails['contact_number'] = response.airline?.contact_phone ? response.airline.contact_phone : '';
        tempResetFormData.formBookingDetails['billing_address'] = response.airline?.billing_address ? response.airline.billing_address : '';
        tempResetFormData.formBookingDetails['billing_phone_number'] = response.airline?.billing_phone_number ? response.airline?.billing_phone_number : '';
        tempResetFormData.formBookingDetails['billing_city'] = response.airline?.billing_city?.city_name ? response.airline.billing_city.city_name : '';
        tempResetFormData.formBookingDetails['billing_country'] = response.airline?.billing_country?.name ? response.airline.billing_country.name : '';
      }
      setResetFormData(tempResetFormData);
    }).catch((obj) => {
      /**
       * NOTE: If response doesn't exists, reset the values as mentioned in https://croowy.atlassian.net/jira/software/projects/LAYOV/boards/4/backlog?selectedIssue=LAYOV-39
       *   Company name is pre-filled and can be changed
       *   Contact number is pre-filled and can be changed
       *   Passenger Airport departure date is pre-set to today
       *   Passenger Airport time is empty and highlighted with a red border. A click on the field opens the time picker which is pre-set to the current local time
       *   Passenger Return to Airport date is empty and highlighted with a red border. A click on the field opens the time picker which is pre-set to tomorrow
       *   Passenger Return to Airport time is empty and highlighted with a red border. A click on the field opens the time picker
       *   Are there different return times? Is not selected and highlighted in red
       *   Request wakeup call?
       *      Yes, by default
       *      If not is selected, then the “Set wakeup call timing” is disabled
       *   Set wakeup call timing. by default it’s empty and highlighted in red. When clicking on it the time picker is preset to Passenger Return to Airport Time - 3h
       */
      let tempResetFormData = {
        formBookingDetails: {...resetFormData.formBookingDetails}
      };
      tempResetFormData.formBookingDetails['passanger_airport_departure_date'] = dayjs(new Date());
      tempResetFormData.formBookingDetails['request_wake_up_call'] = 1;
      setIsWakeUpCall(1);
      if (obj?.airline && Object.keys(obj.airline).length > 0) {
        tempResetFormData.formBookingDetails['billing_name'] = obj.airline?.billing_name ? obj.airline.billing_name : '';
        tempResetFormData.formBookingDetails['contact_number'] = obj.airline?.contact_phone ? obj.airline.contact_phone : '';
        tempResetFormData.formBookingDetails['billing_address'] = obj.airline?.billing_address ? obj.airline.billing_address : '';
        tempResetFormData.formBookingDetails['billing_phone_number'] = obj.airline?.billing_phone_number ? obj.airline?.billing_phone_number : '';
        tempResetFormData.formBookingDetails['billing_city'] = obj.airline?.billing_city?.city_name ? obj.airline.billing_city.city_name : '';
        tempResetFormData.formBookingDetails['billing_country'] = obj.airline?.billing_country?.name ? obj.airline.billing_country.name : '';
      }
      setResetFormData(tempResetFormData);
    }).finally(() => {
      stopLoader();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startLoader, stopLoader, layoverId, airlineId, getBookingDetailsFromLayoverViaApi, getAirlineDetailsViaApi])

  // Provide values to reset the form when API calls completed
  useEffect(() => {
    if (Object.keys(resetFormData).length > 0) {
      reset(resetFormData);
    }
    setTimeout(() => {
      trigger([
        'formBookingDetails[passanger_airport_departure_date]',
        'formBookingDetails[passanger_airport_departure_time]',
        'formBookingDetails[different_return_times]',
        'formBookingDetails[wake_up_call_time]',
        'formBookingDetails[luggage_type]'
      ]);
    }, 10)
  }, [reset, resetFormData, trigger]);

  const currentDate = dayjs();
  const currentTime = dayjs().startOf('minute');
  const departureDate = watch('formBookingDetails[passanger_airport_departure_date]') ? watch('formBookingDetails[passanger_airport_departure_date]') : currentDate;
  const departureTime = watch('formBookingDetails[passanger_airport_departure_time]');
  const returnDate = watch('formBookingDetails[passanger_airport_return_date]');
  const returnTime = watch('formBookingDetails[passanger_airport_return_time]');

  // handling departure-time validations - Layov 209
  useEffect(() => {
    if (departureDate && departureTime) {
      const departureTimeObj = dayjs(departureTime, 'hh:mm a');
      if (!departureTimeObj.isValid()) {
        return;
      } else {
        if (dayjs(departureDate).startOf('day').isSame(currentDate, 'day')) {
          if (departureTimeObj.isAfter(currentTime)) {
            if (departureTimeObj.format('HH:mm') !== dayjs(watch('formBookingDetails[passanger_airport_departure_time]')).format('HH:mm')) {
              setValue('formBookingDetails[passanger_airport_departure_time]', departureTimeObj);
            }
          } else {
            setValue('formBookingDetails[passanger_airport_departure_time]', undefined);
          }
        } else {
          if (departureTimeObj.format('HH:mm') !== dayjs(watch('formBookingDetails[passanger_airport_departure_time]')).format('HH:mm')) {
            setValue('formBookingDetails[passanger_airport_departure_time]', departureTimeObj);
          }
        }
      }
    }
  }, [departureDate, departureTime, setValue, currentDate, currentTime, watch]);

  // handling return-time validations - Layov 209
  useEffect(() => {
    if (returnDate && returnTime && departureDate && departureTime) {
      const returnTimeObj = dayjs(returnTime, 'hh:mm a');
      if (!returnTimeObj.isValid()) {
        return;
      } else {
        if (returnTimeObj.isBefore(departureTime)) {
          if (dayjs(returnDate).isSame(currentDate, 'day')) {
            if (returnTimeObj.isAfter(currentTime)) {
              if (returnTimeObj.format('HH:mm') !== dayjs(watch('formBookingDetails[passanger_airport_return_time]')).format('HH:mm')) {
                setValue('formBookingDetails[passanger_airport_return_time]', returnTimeObj);
              }
            } else {
              setValue('formBookingDetails[passanger_airport_return_time]', undefined);
            }
          } else {
            if (returnTimeObj.format('HH:mm') !== dayjs(watch('formBookingDetails[passanger_airport_return_time]')).format('HH:mm')) {
              setValue('formBookingDetails[passanger_airport_return_time]', returnTimeObj);
            }
          }
        }
      }
    }
    
    // if user filled return time and return date then different return time will set NO
    if(returnDate && returnTime){
      setValue('formBookingDetails[different_return_times]', 'NO');
    }
  }, [returnDate, returnTime, setValue, currentDate, currentTime, watch, departureDate, departureTime]);

  return (
    <Box sx={{pt:3,px:2,pb:1, backgroundColor:theme.mode === 'light' ? theme.palette.bg[400] : 'default'}}>
      <FormProvider {...{control, handleSubmit, formState: { errors }, setFocus}}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <Grid container>
            <Grid item xs={12} sx={{mb:4}}>
              <Grid container columnSpacing={8} sx={{mt:0}}>
                <Grid item xs={6}>
                  <Grid item xs={12} sx={{py:1}}>
                    <StyledHeadingInput title='Company Name' />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={`formBookingDetails[billing_name]`}
                      control={control}
                      disabled
                      render={({ field }) => (
                        <Input
                          disabled
                          formControlProps={{
                            sx: {
                              marginBottom: 0
                            }
                          }}
                          isError={!!errors?.formBookingDetails?.billing_name}
                          errorMessage={errors?.formBookingDetails?.billing_name && 'Please enter valid company name'}
                          inputRef={field.ref}
                          {...field}
                          sx={{ 
                            // borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                            backgroundColor: theme.mode === 'light' ? theme.palette.common.white : 'default'
                          }}
                        />
                      )}
                      rules={{
                        required: true
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={12} sx={{py:1}}>
                    <StyledHeadingInput title='Contact Number' />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={`formBookingDetails[contact_number]`}
                      control={control}
                      disabled
                      render={({ field }) => (
                        <Input
                          disabled
                          formControlProps={{
                            sx: {
                              marginBottom: 0
                            }
                          }}
                          sx={{
                            // borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                            backgroundColor: theme.mode === 'light' ? theme.palette.common.white : 'default'
                          }}
                          isError={!!errors?.formBookingDetails?.contact_number}
                          errorMessage={errors?.formBookingDetails?.contact_number && 'Please enter valid contact number'}
                          inputRef={field.ref}
                          {...field}
                          // inputComponent={MaskCustomInput}
                          // placeholder={`(___) ___-____`}
                        />
                      )}
                      rules={{
                        required: true
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{mb:4}}>
              <Grid container columnSpacing={8} sx={{mt:0}}>
                <Grid item xs={6}>
                  <Grid item xs={12} sx={{py:1}}>
                    <StyledHeadingInput title='Passenger Airport departure' />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container columnSpacing={4}>
                      <Grid item xs={6}>
                        <Controller
                          name={`formBookingDetails[passanger_airport_departure_date]`}
                          control={control}
                          render={({ field }) => (
                            <Datepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'DD.MM.YY'}
                              placeholder={'DD.MM.YY'}
                              minDate={currentDate}
                              maxDate={dayjs().add(1, 'day')}
                              inputRef={field.ref}
                              {...field}
                              sx={{ 
                                // borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                                backgroundColor: theme.mode === 'light' ? theme.palette.common.white : 'default'
                              }}
                              value={field.value || dayjs(new Date())} 
                              startAdornment={
                                <InputEndAdornment position='end' sx={{ textTransform: 'capitalize', pl: 2 }}>
                                  Date
                                </InputEndAdornment>
                              }
                              onOpen={() => {
                                if (!resetFormData.formBookingDetails.hasOwnProperty('passanger_airport_departure_date')) {
                                  setValue(`formBookingDetails[passanger_airport_departure_date]`, dayjs(new Date()))
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name={`formBookingDetails[passanger_airport_departure_time]`}
                          control={control}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                },
                                onFocus: () => {
                                  setValue('formBookingDetails[passanger_airport_departure_time]', departureTime ? dayjs(departureTime, 'hh:mm a') : dayjs(currentTime, 'hh:mm a'));
                                }
                              }}
                              format={'hh:mm a'}
                              minTime={dayjs(departureDate).startOf('day').isSame(currentDate, 'day') ? currentTime : dayjs().startOf('day')}
                              isError={!!errors?.formBookingDetails?.passanger_airport_departure_time}
                              inputRef={field.ref}
                              {...field}
                              sx={{ 
                                // borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                                backgroundColor: theme.mode === 'light' ? theme.palette.common.white : 'default'
                              }}
                              startAdornment={
                                <InputEndAdornment position='end' sx={{textTransform:'capitalize', pl:2}}>
                                  Time
                                </InputEndAdornment>
                              }
                            />
                          )}
                          rules={{
                            required: true
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={12} sx={{py:1}}>
                    <StyledHeadingInput title='Passenger Return to Airport ' />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container columnSpacing={4}>
                      <Grid item xs={6}>
                        <Controller
                          name={`formBookingDetails[passanger_airport_return_date]`}
                          control={control}
                          render={({ field }) => (
                            <Datepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              sx={{ 
                                // borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                                backgroundColor: theme.mode === 'light' ? theme.palette.common.white : 'default'
                              }}
                              format={'DD.MM.YY'}
                              placeholder={'DD.MM.YY'}
                              minDate={(() => {
                                const departureDate = getValues('formBookingDetails[passanger_airport_departure_date]')
                                  ? dayjs(getValues('formBookingDetails[passanger_airport_departure_date]'))
                                  : currentDate;
                                return departureDate.isAfter(currentDate) ? departureDate : currentDate;
                              })()}
                              maxDate={(() => {
                                const departureDate = getValues('formBookingDetails[passanger_airport_departure_date]')
                                  ? dayjs(getValues('formBookingDetails[passanger_airport_departure_date]'))
                                  : currentDate;
                                return departureDate.isAfter(currentDate) ? departureDate.add(1, 'day') : currentDate.add(1, 'day');
                              })()}
                              inputRef={field.ref}
                              {...field}
                              startAdornment={
                                <InputEndAdornment position='end' sx={{textTransform:'capitalize', pl:2}}>
                                  Date
                                </InputEndAdornment>
                              }
                              onOpen={() => {
                                if (!resetFormData.formBookingDetails.hasOwnProperty('passanger_airport_return_date')) {
                                  setValue(`formBookingDetails[passanger_airport_return_date]`, dayjs().add(1, 'day'))
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name={`formBookingDetails[passanger_airport_return_time]`}
                          control={control}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                },
                                onFocus: () => {
                                  setValue('formBookingDetails[passanger_airport_return_time]', returnTime ? dayjs(returnTime, 'hh:mm a') : dayjs(currentTime, 'hh:mm a'));
                                }
                              }}
                              sx={{ 
                                // borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                                backgroundColor: theme.mode === 'light' ? theme.palette.common.white : 'default'
                              }}
                              format={'hh:mm a'}
                              minTime={(() => {
                                if (departureDate && returnDate) {
                                  if ((departureDate.isSame(currentDate, 'day') && returnDate.isSame(currentDate, 'day')) || (departureDate.isSame(returnDate, 'day'))) {
                                    return departureTime ? departureTime : currentTime;
                                  }
                                  return dayjs().startOf('day');
                                }
                              })()}
                              inputRef={field.ref}
                              {...field}
                              disabled={!returnDate}
                              startAdornment={
                                <InputEndAdornment position='end' sx={{ textTransform: 'capitalize', pl: 2 }}>
                                  Time
                                </InputEndAdornment>
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{mb:4}}>
              <Grid container columnSpacing={8} sx={{mt:0}}>
                <Grid item xs={6}>
                  <Grid item xs={12} sx={{py:1}}>
                    <StyledHeadingInput title='Are there different return times' />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={`formBookingDetails[different_return_times]`}
                      control={control}
                      defaultValue={'LATER'}
                      render={({ field }) => (
                        <Radio
                          options={[
                            {
                              value: 'YES',
                              label: 'Yes'
                            },
                            {
                              value: 'NO',
                              label: 'No'
                            },
                            {
                              value: 'LATER',
                              label: 'To be announced later',
                              disabled: returnTime && returnDate
                            }
                          ]}
                          isError={!!errors?.formBookingDetails?.different_return_times}
                          inputRef={field.ref}
                          {...field}
                        />
                      )}
                      rules={{
                        required: true
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={12} sx={{py:1}}>
                    <StyledHeadingInput title='Request wakeup call?' />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={`formBookingDetails[request_wake_up_call]`}
                      control={control}
                      defaultValue={isWakeUpCall}
                      render={({ field }) => (
                        <Radio
                          options={[
                            {
                              value: 1,
                              label: 'Yes'
                            },
                            {
                              value: 0,
                              label: 'No'
                            }
                          ]}
                          inputRef={field.ref}
                          {...field}
                          onChange={(e) => {
                            setIsWakeUpCall(Number(e.target.value))
                            field.onChange(e)
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{mb:4}}>
              <Grid container columnSpacing={8} sx={{mt:0}}>
                <Grid item xs={6}>
                  <Grid item xs={12} sx={{py:1}}>
                    <StyledHeadingInput title='What luggage does the majority of passengers carry?' />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={`formBookingDetails[luggage_type]`}
                      control={control}
                      defaultValue={''}
                      render={({ field }) => (
                        <Select
                          placeholder={'Select Luggage Type'}
                          options={luggageOptions}
                          isError={!!errors?.formBookingDetails?.luggage_type}
                          inputRef={field.ref}
                          {...field}
                          formControlProps={{
                            marginBottom: '0 !important'
                          }}
                          sx={{ 
                            // borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                            backgroundColor: theme.mode === 'light' ? theme.palette.common.white : 'default'
                          }}
                        />
                      )}
                      rules={{
                        required: true
                      }}
                    />
                  </Grid>
                </Grid>
                {isWakeUpCall ? (
                  <Grid item xs={6}>
                    <Grid item xs={12} sx={{py:1}}>
                      <StyledHeadingInput title='Set wakeup call timing' />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name={`formBookingDetails[wake_up_call_time]`}
                        control={control}
                        render={({ field }) => (
                          <Timepicker
                            inputProps={{
                              formControlProps: {
                                sx: {
                                  marginBottom: 0
                                }
                              }
                            }}
                            sx={{ 
                              // borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                              backgroundColor: theme.mode === 'light' ? theme.palette.common.white : 'default'
                            }}
                            format={'hh:mm a'}
                            isError={!!errors?.formBookingDetails?.wake_up_call_time}
                            inputRef={field.ref}
                            {...field}
                            startAdornment={
                              <InputEndAdornment position='end' sx={{textTransform:'capitalize', pl:2}}>
                                Time
                              </InputEndAdornment>
                            }
                            onOpen={() => {
                              if (!resetFormData.formBookingDetails.hasOwnProperty('passanger_airport_return_time') && !resetFormData.formBookingDetails.hasOwnProperty('wake_up_call_time')) {
                                let parTime = getValues(`formBookingDetails[passanger_airport_return_time]`)
                                setValue(`formBookingDetails[wake_up_call_time]`, dayjs(parTime).subtract('3', 'hour'))
                              }
                            }}
                          />
                        )}
                        rules={{
                          required: isWakeUpCall ? true : false
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (null)}
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{mb:4}}>
              <Divider variant='fullWidth' />
            </Grid>

            <Grid item xs={12} sx={{mb:2}}>
              <SubHeading title='Billing Details' />
            </Grid>

            <Grid item xs={12} sx={{mb:4}}>
              <Grid container columnSpacing={8} sx={{mt:0}}>
                <Grid item xs={6}>
                  <Grid item xs={12} sx={{py:1}}>
                    <StyledHeadingInput title='Address' />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      disabled
                      formControlProps={{
                        sx: {
                          marginBottom: 0
                        }
                      }}
                      sx={{
                        // borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                        backgroundColor: theme.mode === 'light' ? theme.palette.common.white : 'default'
                      }}
                      value={airline?.billing_address ? airline.billing_address : ''}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={12} sx={{py:1}}>
                    <StyledHeadingInput title='Billing Phone Number' />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      disabled
                      formControlProps={{
                        sx: {
                          marginBottom: 0
                        }
                      }}
                      sx={{
                        // borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                        backgroundColor: theme.mode === 'light' ? theme.palette.common.white : 'default'
                      }}
                      // inputComponent={MaskCustomInput}
                      // placeholder={`(___) ___-____`}
                      value={airline?.billing_phone_number ? airline?.billing_phone_number : ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{mb:4}}>
              <Grid container columnSpacing={8} sx={{mt:0}}>
                <Grid item xs={6}>
                  <Grid container columnSpacing={4}>
                    <Grid item xs={6}>
                      <Grid item xs={12} sx={{py:1}}>
                        <StyledHeadingInput title='City' />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          disabled
                          formControlProps={{
                            sx: {
                              marginBottom: 0
                            }
                          }}
                          sx={{
                            // borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                            backgroundColor: theme.mode === 'light' ? theme.palette.common.white : 'default'
                          }}
                          value={airline?.billing_city?.city_name ? airline.billing_city.city_name : ''}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid item xs={12} sx={{py:1}}>
                        <StyledHeadingInput title='Country' />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          disabled
                          formControlProps={{
                            sx: {
                              marginBottom: 0
                            }
                          }}
                          sx={{
                            // borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                            backgroundColor: theme.mode === 'light' ? theme.palette.common.white : 'default'
                          }}
                          value={airline?.billing_country?.name ? airline.billing_country.name : ''}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container columnSpacing={8}>
                <Grid item xs={6}>
                  <Button
                    type='submit'
                    variant='contained'
                    sx={{
                      width: '100%',
                      '&.MuiButton-contained': {
                        ...theme.typography.fontMedium600
                      }
                    }}
                  >
                    Save and Continue
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

export default BookingDetails;
import React, { memo, useEffect, useState } from 'react';
import { FormControl, InputBase, InputLabel, Typography, styled } from '@mui/material';

const FormControlContainer = styled(FormControl, {
  shouldForwardProp: (prop) => ['isLabel'].indexOf(prop) === -1
})(({ theme, isLabel }) => ({
  display: 'flex',
  marginBottom: '20px',
  '& .MuiFormLabel-root': {
    color: theme.palette.text.secondary,
    ...theme.typography.inputLabel,
    '& + .MuiInputBase-root': {
      marginTop: isLabel ? '18px' : '0'
    }
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.text.secondary
  },
}));

const BootstrapInput = styled(InputBase)(({ theme, isError }) => ({
  backgroundColor: theme.palette.grey['A900'],
  borderRadius: theme.borderRadius[5],
  border: `1px solid ${isError ? theme.palette.error.main + '! important' : theme.palette.grey['A900']}`,
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    ...theme.typography.inputText,
    padding: '10px 15px'
  },
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  ...theme.typography.inlineErrorMesage,
  textAlign: 'left',
  color: theme.palette.error.main
}));

const Input = (props) => {
  const { label, isError, errorMessage, formControlProps } = props;
  const [inputLabel, setInputLabel] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');

  useEffect(() => {
    if (label) { setInputLabel(label) }
    if (isError) { setValidationErrorMessage(errorMessage ? errorMessage : '') }
  }, [label, isError, errorMessage]);

  return (
    <FormControlContainer variant='standard' isLabel={!!inputLabel} {...formControlProps}>
      {inputLabel ? (
        <InputLabel shrink htmlFor='bootstrap-input'>
          {inputLabel}
        </InputLabel>
      ) : ('')}
      <BootstrapInput
        {...props}
      />
      {(isError && validationErrorMessage) && 
        <ErrorMessage variant='subtitle2'>
          {validationErrorMessage}
        </ErrorMessage>
      }
    </FormControlContainer>
  );
};

export default memo(Input);
import { Box, Grid, Typography, styled, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { symbol as getCurrencySymbol } from 'better-currency-codes';
import { CheckBox, Slider } from '../../../../components';
import formatPrice from '../../../../utils/formatPrice';

const StyledFilterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.filter.main,
  borderRadius: theme.borderRadius[2],
  padding: theme.spacing(2)
}))

const StyledCheckBox = styled(CheckBox)(({ theme }) => ({
  color:theme.palette.menu.main,
  '&.Mui-checked': {
    color: theme.palette.menu.main,
  },
}))

const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-track': {
    backgroundColor: theme.palette.menu.main
  },
  '& .MuiSlider-thumb': {
    border: `1.5px solid ${theme.palette.menu.main}`
  },
}))

const StyledMainLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[650],
  textTransform: 'uppercase'
}))

const marksForNoShow = [
  {
    value: 0,
    toolTipLabel: 'Poor: 5',
    label: 'Poor',
  },
  {
    value: 1,
    toolTipLabel: 'Okay: 4',
    label: 'Okay',
  },
  {
    value: 2,
    toolTipLabel: 'Good: 3',
    label: 'Good',
  },
  {
    value: 3,
    toolTipLabel: 'Great: 2',
    label: 'Great',
  },
  {
    value: 4,
    toolTipLabel: 'Excellent: 1',
    label: 'Excellent',
  },
];

const Filters = (props) => {
  const { currency } = props;
  const { control } = useFormContext();
  const theme = useTheme();
  const minPriceRange = 0;
  const maxPriceRange = 1000;
  const minDrivingTimeRange = 0;
  const maxDrivingTimeRange = 50;

  /**
   * Get layover currency symbol
   * 
   * @param {*} currencyCode  Currency Code
   */
  const getLayoverCurrencySymbol = (currencyCode) => {
    let layoverCurrency = '';
    if (currencyCode) {
      const currencySymbol = getCurrencySymbol({ code: currencyCode, numeric: false });
      layoverCurrency = currencySymbol?.native ? currencySymbol.native : currencyCode;
    }
    return layoverCurrency;
  };

  return (
    <StyledFilterContainer>
      <Grid container>
        <Grid item xs={12} sx={{mb:3}}>
          <StyledMainLabel sx={{mb:1}}>Meals</StyledMainLabel>
          <Grid container>
            <Grid item xs={12} sx={{py:1}}>
              <Controller
                name={`formFilters[isBreakfast]`}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <StyledCheckBox
                    inputRef={field.ref}
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    label={
                      <Typography variant='caption' sx={{
                        ...theme.typography.textxSmall,
                        color: theme.palette.menu.main,
                        ml: 1
                      }}>
                        Breakfast
                      </Typography>
                    }
                    formControlLabelProps={{
                      sx: {
                        m: 0
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{py:1}}>
              <Controller
                name={`formFilters[isEarlyBirdBreakfast]`}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <StyledCheckBox
                    inputRef={field.ref}
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    label={
                      <Typography variant='caption' sx={{
                        ...theme.typography.textxSmall,
                        color: theme.palette.menu.main,
                        ml: 1
                      }}>
                        Early Bird Breakfast
                      </Typography>
                    }
                    formControlLabelProps={{
                      sx: {
                        m: 0
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{py:1}}>
              <Controller
                name={`formFilters[isLunch]`}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <StyledCheckBox
                    inputRef={field.ref}
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    label={
                      <Typography variant='caption' sx={{
                        ...theme.typography.textxSmall,
                        color: theme.palette.menu.main,
                        ml: 1
                      }}>
                        Lunch
                      </Typography>
                    }
                    formControlLabelProps={{
                      sx: {
                        m: 0
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{py:1}}>
              <Controller
                name={`formFilters[isDinner]`}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <StyledCheckBox
                    inputRef={field.ref}
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    label={
                      <Typography variant='caption' sx={{
                        ...theme.typography.textxSmall,
                        color: theme.palette.menu.main,
                        ml: 1
                      }}>
                        Dinner
                      </Typography>
                    }
                    formControlLabelProps={{
                      sx: {
                        m: 0
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{mb:3}}>
          <StyledMainLabel sx={{mb:1}}>Rooms</StyledMainLabel>
          <Grid container>
            <Grid item xs={12} sx={{py:1}}>
              <Controller
                name={`formFilters[isSingleRoom]`}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <StyledCheckBox
                    inputRef={field.ref}
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    label={
                      <Typography variant='caption' sx={{
                        ...theme.typography.textxSmall,
                        color: theme.palette.menu.main,
                        ml: 1
                      }}>
                        Single Room
                      </Typography>
                    }
                    formControlLabelProps={{
                      sx: {
                        m: 0
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{py:1}}>
              <Controller
                name={`formFilters[isDoubleRoom]`}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <StyledCheckBox
                    inputRef={field.ref}
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    label={
                      <Typography variant='caption' sx={{
                        ...theme.typography.textxSmall,
                        color: theme.palette.menu.main,
                        ml: 1
                      }}>
                        Double Room
                      </Typography>
                    }
                    formControlLabelProps={{
                      sx: {
                        m: 0
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{mb:3}}>
          <StyledMainLabel sx={{mb:1}}>Room Rate</StyledMainLabel>
          <Grid container>
            <Grid item xs={12} sx={{py:1}}>
              <Typography variant='caption' sx={{
                ...theme.typography.textxSmall,
                color: theme.palette.menu.main,

              }}>
                {`${currency?.alphabetic_code ? getLayoverCurrencySymbol(currency.alphabetic_code) : ''}${formatPrice(minPriceRange)}`} - {`${currency?.alphabetic_code ? getLayoverCurrencySymbol(currency.alphabetic_code) : ''}${formatPrice(maxPriceRange)}`}
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{py:1}}>
              <Controller
                name={`formFilters[price_range]`}
                control={control}
                defaultValue={[minPriceRange, maxPriceRange]}
                render={({ field }) => (
                  <StyledSlider
                    {...field}
                    min={minPriceRange}
                    max={maxPriceRange}
                    step={10}
                    inputRef={field.ref}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    formControlProps={{
                      sx: {
                        marginBottom: 0
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{mb:3}}>
          <StyledMainLabel sx={{mb:1}}>No Show</StyledMainLabel>
          <Grid container sx={{py:1}}>
            <Grid item xs={12} sx={{'& .MuiFormControl-root': { pl: 0.3}}}>
              <Controller
                name={`formFilters[no_show]`}
                control={control}
                defaultValue={[0, 4]}
                render={({ field }) => (
                  <StyledSlider
                    {...field}
                    inputRef={field.ref}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    valueLabelFormat={(sliderValue)=>marksForNoShow?.[sliderValue]?.toolTipLabel}
                    marks={marksForNoShow}
                    max={4}
                    sx={{
                      pl: 0,
                      '& .MuiSlider-rail': {
                        left: 0,
                        width: '100%',
                      },
                      '& .MuiSlider-mark': {
                        backgroundColor: theme.palette.grey[300],
                        height: '20%',
                      },
                      '& .MuiSlider-markLabel':{
                        ...theme.typography.textxxSmall,
                        color: theme.palette.grey[200]
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{mb:3}}>
          <StyledMainLabel sx={{mb:1}}>Driving Time</StyledMainLabel>
          <Grid container>
            <Grid item xs={12} sx={{py:1}}>
              <Typography variant='caption' sx={{
                ...theme.typography.textxSmall,
                color: theme.palette.menu.main,
              }}>
                {minDrivingTimeRange} min - {maxDrivingTimeRange} mins
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{py:1}}>
              <Controller
                name={`formFilters[driving_time_range]`}
                control={control}
                defaultValue={[minDrivingTimeRange, maxDrivingTimeRange]}
                render={({ field }) => (
                  <StyledSlider
                    {...field}
                    min={minDrivingTimeRange}
                    max={maxDrivingTimeRange}
                    step={1}
                    inputRef={field.ref}
                    value={field.value[1]}
                    onChange={(newValue) => field.onChange([minDrivingTimeRange, newValue])}
                    formControlProps={{
                      sx: {
                        marginBottom: 0,
                        '& .MuiSlider-track': {
                          left:`-${theme.spacing(1.5)} !important`
                        },
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item xs={12} sx={{mb:3}}>
          <StyledMainLabel sx={{mb:1}}>Additional Facilities</StyledMainLabel>
          <Grid container>
            <Grid item xs={12} sx={{py:1}}>
              <Controller
                name={`formFilters[isPetsAllowed]`}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <StyledCheckBox
                    inputRef={field.ref}
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    label={
                      <Typography variant='caption' sx={{
                        ...theme.typography.textxSmall,
                        color: theme.palette.menu.main,
                        ml: 1
                      }}>
                        Pets Allowed
                      </Typography>
                    }
                    formControlLabelProps={{
                      sx: {
                        m: 0
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{py:1}}>
              <Controller
                name={`formFilters[isRoomForDisabled]`}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <StyledCheckBox
                    inputRef={field.ref}
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    label={
                      <Typography variant='caption' sx={{
                        ...theme.typography.textxSmall,
                        color: theme.palette.menu.main,
                        ml: 1
                      }}>
                        Rooms for Disabled
                      </Typography>
                    }
                    formControlLabelProps={{
                      sx: {
                        m: 0
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{mb:3}}>
          <StyledMainLabel sx={{mb:1}}>Transportation</StyledMainLabel>
          <Grid container>
            <Grid item xs={12} sx={{py:1}}>
              <Controller
                name={`formFilters[isShuttleService]`}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <StyledCheckBox
                    inputRef={field.ref}
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    label={
                      <Typography variant='caption' sx={{
                        ...theme.typography.textxSmall,
                        color: theme.palette.menu.main,
                        ml: 1
                      }}>
                        Shuttle Availability
                      </Typography>
                    }
                    formControlLabelProps={{
                      sx: {
                        m: 0
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledFilterContainer>
  );
};

export default Filters;
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Tab, Tabs, Typography, styled, useTheme } from '@mui/material';
import { HotelContext } from '../../../context/hotel';
import { Heading } from '../components/heading';
import { codeData as getCurrencyCodeData } from 'better-currency-codes';
import RatesCharges from './ratesCharges';
import HotelTimings from './hotelTimings';
import FoodBeverages from './foodBeverages';
import Transportation from './transportation';
import AirlineContracts from './airlineContracts';
import { useNavigate, useOutletContext } from 'react-router-dom';

const StyledTab = styled(Tab)(({ theme }) => ({
  paddingRight: 0,
  paddingLeft: 0,
  marginRight: theme.spacing(3),
  '&.Mui-selected': {
    color: theme.palette.menu.light,
    '& .MuiTypography-root': {
      ...theme.typography.fontMedium600,
      color: theme.palette.menu.light,
    }
  }
}));

const StyledTabName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  textTransform: 'capitalize',
  color: theme.palette.menu.dark,
  '&.tab-hotel-currency': {
    ...theme.typography.textxxSmall,
    '& .hotel-currency-symbol': {
      ...theme.typography.body1,
      ...theme.typography.fontSemiBold600
    }
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  ...theme.typography.textLarge,
  textTransform: 'capitalize',
  color: theme.palette.roseGold[100]
}));

function StyledTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

const RatesConditions = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { hotel, setIsCommonTopbar, isFormDirty, setIsFormDirty, dirtyDialogOpen, setDirtyDialogOpen, onclickDirtyFormModalAgree, onclickDirtyFormModalDisAgree, setRateChargesNextTabValue } = useContext(HotelContext);
  const submitRef = useRef(null);
  const [tabValue, setTabValue] = useState('1');
  const [currency, setCurrency] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [permission] = useOutletContext()

  // show/hide select hotel dropdown in the header
  useEffect(() => {
    setIsCommonTopbar(false)
  }, [setIsCommonTopbar])

  // getting local currency
  useEffect(() => {
    let hotelCurrency = '';
    let hotelCurrencySymbol = '';
    if (hotel?.currency?.alphabetic_code) {
      const currencyCodeData = getCurrencyCodeData({ code: hotel.currency.alphabetic_code, numeric: false });
      hotelCurrency = currencyCodeData?.name?.unit?.plural ? currencyCodeData.name.unit.plural : hotel.currency.alphabetic_code;
      hotelCurrencySymbol = currencyCodeData?.symbol?.native ? currencyCodeData.symbol.native : '';
    }
    setCurrency(hotelCurrency);
    setCurrencySymbol(hotelCurrencySymbol)
  }, [hotel?.currency?.alphabetic_code]);

  // tab onclick event
  const handleTabChange = (e, tabVal) => {
    if (tabVal) {
      if (isFormDirty) {
        setDirtyDialogOpen(true);
        setRateChargesNextTabValue(tabVal);
      } else {
        setTabValue(tabVal);
      }
    }
  };

  // If select YES on Confirmation Modal
  const onAgree = useCallback(() => {
    onclickDirtyFormModalAgree((param) => {
      submitRef?.current?.saveChanges();
      setDirtyDialogOpen(false);
      setIsFormDirty(false);
      if (param) {
        if (param.selectedSidebarMenu) {
          navigate(param.selectedSidebarMenu);
        } else if (param?.nextTabValue) {
          setTabValue(param?.nextTabValue);
        }
      }
    });
  }, [onclickDirtyFormModalAgree]);

  // If select NO on Confirmation Modal
  const onDisAgree = useCallback(() => {
    onclickDirtyFormModalDisAgree((param) => {
      setDirtyDialogOpen(false);
      setIsFormDirty(false);
      if (param) {
        if (param.selectedSidebarMenu) {
          navigate(param?.selectedSidebarMenu);
        } else if (param?.nextTabValue) {
          setTabValue(param?.nextTabValue);
        }
      }
    });
  }, [onclickDirtyFormModalDisAgree]);

  useEffect(() => {
    onAgree();
    onDisAgree();
  }, [onAgree, onDisAgree])

  return (
    <Box sx={{py: 1, px: 2, backgroundColor: theme.mode === 'light' && theme.palette.common.white, borderRadius: theme.borderRadius[10]}}>
      {permission?.edit ? (
        <Box sx={{
          display: 'flex'
        }}>
          <Heading variant='body2' title='Rates & Conditions for Booking' sx={{flexGrow: 1}} />
          <StyledButton
            variant='text'
            onClick={() => submitRef?.current?.saveChanges()}
          >Save Changes</StyledButton>
        </Box>
      ) : (null)}
      {hotel ? (
        <Box sx={{pt: 2, px: 1, backgroundColor: theme.mode === 'light' && theme.palette.bg[400], borderRadius: theme.borderRadius[10]}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex' }}>
            <Tabs
              value={tabValue}
              variant='scrollable'
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.palette.menu.light
                }
              }}
              scrollButtons={false}
            >
              <StyledTab
                value='1'
                label={
                  <StyledTabName>Rates & Charges</StyledTabName>
                }
              />
              <StyledTab
                value='2'
                label={
                  <StyledTabName>Hotel Timings</StyledTabName>
                }
              />
              <StyledTab
                value='3'
                label={
                  <StyledTabName>Food & Beverage</StyledTabName>
                }
              />
              <StyledTab
                value='4'
                label={
                  <StyledTabName>Transportation</StyledTabName>
                }
              />
              <StyledTab
                value='5'
                label={
                  <StyledTabName>Airline Contracts</StyledTabName>
                }
              />
            </Tabs>
            {currency ? (
              <StyledTab
                label={
                  <StyledTabName className='tab-hotel-currency'>
                    Currency In: <span className='hotel-currency-symbol'>{`${currencySymbol ? `${currencySymbol} ` : ''}${currency}`}</span>
                  </StyledTabName>
                }
                disabled
                sx={{
                  flexGrow: 1,
                  alignItems: 'end',
                  m: 0,
                  ml: 2,
                  maxWidth: 'unset'
                }}
              />
            ) : ('')}
          </Box>
          <StyledTabPanel value={tabValue} index='1'>
            <RatesCharges hotel={hotel} ref={submitRef} permission={permission} dirtyDialogOpen={dirtyDialogOpen} />
          </StyledTabPanel>
          <StyledTabPanel value={tabValue} index='2'>
            <HotelTimings hotel={hotel} ref={submitRef} permission={permission}/>
          </StyledTabPanel>
          <StyledTabPanel value={tabValue} index='3'>
            <FoodBeverages hotel={hotel} ref={submitRef} permission={permission}/>
          </StyledTabPanel>
          <StyledTabPanel value={tabValue} index='4'>
            <Transportation hotel={hotel} ref={submitRef} permission={permission}/>
          </StyledTabPanel>
          <StyledTabPanel value={tabValue} index='5'>
            <AirlineContracts hotel={hotel} ref={submitRef} permission={permission}/>
          </StyledTabPanel>
        </Box>
      ) : (null)}

      <Box sx={{my:2, display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <Box></Box>
        { permission?.edit ? (
          <Box>
            <StyledButton variant='text' onClick={() => submitRef?.current?.saveChanges()}>Save Changes</StyledButton>
          </Box>
        ):(null)}
      </Box>
    </Box>
  );
};

export default RatesConditions;
import { Box, Typography, useTheme, Grid, styled } from '@mui/material'
import hotelDefaultImg from '../../assets/images/hotel_default.png';
import AccessibleIcon from '@mui/icons-material/Accessible';
import PetsIcon from '@mui/icons-material/Pets';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { prepareImageUrlByPath } from '../../utils'

const StyledFacilityIcoBox = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.common.black}88`,
  borderRadius: theme.borderRadius[3],
  padding: theme.spacing(1),
  height: theme.spacing(4),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(1)
}))


export default function HotelCard({hotel}) {
  const theme = useTheme()

  /**
   * Get first hotel image from the array of images
   * 
   * @param {*} hotel   Hotel Object
   */
  const getHotelImage = (hotel) => {
    let url = hotelDefaultImg;
    if (hotel?.images?.[0]?.formats?.small?.url) {
      url = process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(hotel.images[0].formats.small.url) : hotel.images[0].formats.small.url
    }
    return url;
  };

  return (
    <Grid container sx={{
      backgroundImage: `url(${getHotelImage(hotel)})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: 200,
      borderRadius: 1, 
      p: 1,
      position: 'relative',
    }}>
      <Box sx={{
        position: 'absolute',
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
      }}>
        {hotel?.is_by_foot ? (
          <StyledFacilityIcoBox>
            <DirectionsWalkIcon />
          </StyledFacilityIcoBox>
        ) : (null)}
        {hotel?.is_room_for_disabled ? (
          <StyledFacilityIcoBox>
            <AccessibleIcon />
          </StyledFacilityIcoBox>
        ) : (null)}
        {hotel?.is_pets ? (
          <StyledFacilityIcoBox>
            <PetsIcon />
          </StyledFacilityIcoBox>
        ) : (null)}
        {(hotel?.isSnacks || hotel?.isDinner || hotel?.isLunch || hotel?.isBreakfast || hotel?.isEarlyBirdBreakfast) ? (
          <StyledFacilityIcoBox>
            <LocalDiningIcon />
          </StyledFacilityIcoBox>
        ) : (null)}
        {(hotel?.hotel_transportation === 'SHUTTLE') ? (
          <StyledFacilityIcoBox>
            <DirectionsBusIcon />
          </StyledFacilityIcoBox>
        ) : (null)}
      </Box>
      <Box sx={{
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(1),
      }}>
        {(hotel?.distance_min) ? (
          <StyledFacilityIcoBox>
            <FlightTakeoffIcon />
            <Typography variant='body2'>{hotel?.distance_min} min</Typography>
          </StyledFacilityIcoBox>
        ) : (null)}
      </Box>
    </Grid>
  )
}

import { useEffect, memo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, styled } from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.mode === 'light' ? theme.palette.bg[400] : theme.palette.menu.default,
    backgroundImage: 'unset'
  }
}));
const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.menu.dark,
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  ...theme.typography.fontSemiBold500,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3)
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => ['stretchBtn'].indexOf(prop) === -1
})(({ theme, stretchBtn, disabled = false }) => ({
  ...theme.typography.textMedium,
  ...theme.typography.fontMedium600,
  color: theme.mode === 'light' ? theme.palette.common.white : theme.palette.alert.main,
  backgroundColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
  flexGrow: stretchBtn ? 1 : 0,
  textTransform: 'capitalize',
  padding: theme.spacing(2),
  '&.btn-agree': {
    color: theme.mode === 'light' ? theme.palette.common.white : theme.palette.roseGold[100],
    backgroundColor: theme.mode === 'light' ? theme.palette.roseGold[100] : 'default',
    ':hover' : {
      opacity:.8
    }
  },
  '&.btn-disagree': {
    color: theme.mode === 'light' ? theme.palette.common.white : theme.palette.alert.main,
    backgroundColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
    ':hover' : {
      opacity:.8
    }
  },
  opacity: disabled ? .25 : 1
}));

const AlertDialog = (props) => {
  const { open, yesBtnText, noBtnText, title, children, onAgree, onDisagree, stretchBtn, dialogTitleProps, dialogContentProps, disAgreeButtonProps, agreeButtonProps } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [yesButtonText, setYesButtonText] = useState('Agree');
  const [noButtonText, setNoButtonText] = useState('Disagree');
  const [dialogTitle, setDialogTitle] = useState(null);

  useEffect(() => {
    setIsOpen(open);
    if (yesBtnText) { setYesButtonText(yesBtnText) }
    if (noBtnText) { setNoButtonText(noBtnText) }
    if (title) { setDialogTitle(title) }
  }, [open, yesBtnText, noBtnText, title]);

  const handleClose = () => {
    setIsOpen(false);
    if (onDisagree) { onDisagree() }
  };

  const handleAgree = () => {
    setIsOpen(false);
    if (onAgree) { onAgree() }
  };

  return (
    <StyledDialog
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      open={isOpen}
      {...props}
      onClose={handleClose}
    >
      {dialogTitle && (
        <StyledDialogTitle id='alert-dialog-title' {...dialogTitleProps}>
          {dialogTitle}
        </StyledDialogTitle>
      )}
      <DialogContent {...dialogContentProps}>
        <StyledDialogContentText id='alert-dialog-description'>
          {children}
        </StyledDialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        {onDisagree ? (
          <StyledButton onClick={handleClose} stretchBtn={stretchBtn} {...disAgreeButtonProps} className='btn-disagree'>{noButtonText}</StyledButton>
        ) : (null)}
        {onAgree ? (
          <StyledButton onClick={handleAgree} stretchBtn={stretchBtn} {...agreeButtonProps} className='btn-agree'>{yesButtonText}</StyledButton>
        ) : (null)}
      </DialogActions>
    </StyledDialog>
  );
};

export default memo(AlertDialog);
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, useTheme, Button, Grid, Typography, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import GetAppIcon from '@mui/icons-material/GetApp';
import { SubHeading } from '../../../hotel/components/heading';
import Input from '../../../../components/input';
import { InputEndAdornment } from '../../../hotel/components/endAdornment';
import AirlineListingCard from '../airlineListingCard';
import AirlineGroundStaffCard from '../airlineGroundStaffCard';
import { useLoader } from '../../../../providers/loader';
import Select from '../../../../components/select';
import { AirlineContext } from '../../../../context/airline';
import { arrayColumn, getSiteUrl, prepareImageUrlByPath } from '../../../../utils';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { maxToastTime, toastMessages, userRoles } from '../../../../helper/constant';
import { getAirlineDetails, getAirlineUsers, inviteAirlineUsers, updateArline, updateArlineUsers, updateQRSetting } from '../../../../services/airline';
import { Radio } from '../../../../components';
import saveAs from 'file-saver';
import { Document, Page, Image, StyleSheet, pdf, Text, Link as PdfLink } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#ffffff',
  },
  image: {
    width: 300,
    height: 300,
  },
  airlineName: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 20
  },
  addPassengerLink: {
    fontSize: 20
  }
});

const RoseGoldButton = styled(Button)(({ theme }) => ({
  ...theme.typography.textLarge,
  textTransform: 'none',
  color: theme.palette.roseGold[100],
  paddingRight: 0,
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(0.5),
  }
}));

const Settings = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const { setIsCommonTopbar, setIsAirlineDropdownDisabled, airlines, airports, airlineId, airportId, airline } = useContext(AirlineContext);
  const [frontDeskUsersResponse, setFrontDeskUsersResponse] = useState(null);
  const { startLoader, stopLoader } = useLoader();
  const [airlineOptions, setAirlineOptions] = useState([]);
  const [airportOptions, setAirportOptions] = useState([]);
  const [frontDeskUsers, setFrontDeskUsers] = useState(null);
  const [qrTypeForAirline, setQRTypeForAirline] = useState('');
  const [qrLinkForAirline, setQRLinkForAirline] = useState('');
  const [qrURLForUniqueAirline, setQrURLForUniqueAirline] = useState(null)
  
  useEffect(() => {
    setIsCommonTopbar(false);
    setIsAirlineDropdownDisabled(false);
  }, [setIsCommonTopbar])
  
  const {
    control,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm();

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (first, field) => first || (errors[field] ? field : null),
      null
    );
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const printQrCode = async (filename, img, airlineName, addPassengerLink) => {
    const image = await img
    const blob = await pdf(<QrDocument image={image} airlineName={airlineName} addPassengerLink={addPassengerLink} />).toBlob()
    saveAs(blob, `${filename}.pdf`)
  };

  /**
   * Get airline details using API
   * 
   * @param {*} id  Airline Id
  */
  const getAirlineDetailsViaAPI = useCallback(async (id, request) => {
    if (id) {
      try {
        const response = await getAirlineDetails(id, request);
        return response;
      } catch (e) {
        throw e
      }
    }
  }, []);

  /**
   * Update airline details using API
   * 
   * @param {*} id  Airline Id
  */
  const updateArlineViaAPI = useCallback(async (id, request) => {
    if (id) {
      try {
        const response = await updateArline(id, request);
        return response;
      } catch (e) {
        throw e
      }
    }
  }, []);

  /**
   * Update QR code Generation setting using API
   * 
   * @param {*} id  Airline Id
  */
  const updateQRSettingViaAPI = useCallback(async (airlineId, airportId) => {
    if (airlineId && airportId) {
      try {
        const response = await updateQRSetting(airlineId, airportId);
        setQRLinkForAirline(response?.layoverPassengerBookingUrl)
        return response;
      } catch (e) {
        throw e
      }
    }
  }, []);

  /**
   * Get airline front desk users
   * 
   * @param {*} airlineIds    Airline Ids
  */
  const getAirlineFrontDeskUsers = useCallback(async (airlineIds) => {
    if (airlineIds) {
      let request = {
        airlines: airlineIds.join(','),
        roleTypes: userRoles.airlineGroundStaff
      };
      const response = await getAirlineUsers({params: request});
      if (response && response?.airlines && Object.keys(response.airlines).length) {
        setFrontDeskUsersResponse(response.airlines);
      }
    }
  }, []);

  /**
   * Invite airline front desk users
   * 
   * @param {*} airlineId      Airline Id
   * @param {*} request      Request
  */
  const inviteAirlineFrontDeskUsers = useCallback(async (airlineId, request) => {
    if (airlineId && request) {
      try {
        const response = await inviteAirlineUsers(airlineId, request);
        return response;
      } catch (e) {
        throw e
      }
    }
  }, []);

  // preparing airlines as a options for Dropdowm
  useEffect(() => {
    if (airlines && airlines.length) {
      const formattedAirlines = airlines.map((airline) => {
        return {
          key: airline.id,
          name: airline.name
        };
      });
      setAirlineOptions(formattedAirlines);
    }
  }, [airlines]);

  // preparing airports as a options for Dropdowm
  useEffect(() => {
    if (airports && airports.length) {
      const formattedAirports = airports.map((airline) => {
        return {
          key: airline.id,
          name: airline.name
        };
      });
      setAirportOptions(formattedAirports);
    }
  }, [airports]);

  useEffect(() => {
    if (airlines && airlines.length) {
      // Get airline front desk users
      const airlineIds = arrayColumn(airlines, 'id');
      getAirlineFrontDeskUsers(airlineIds);
    }
  }, [airlines, getAirlineFrontDeskUsers]);

  useEffect(() => {
    if (airlines && airlines.length && frontDeskUsersResponse && Object.keys(frontDeskUsersResponse).length) {
      let tempAirlines = {};
      for (const airline of airlines) {
        tempAirlines[airline.id] = {
          id: airline.id,
          name: airline.name
        };
      }
      let airlineFrontDeskUsers = {};
      for (const airlineId in frontDeskUsersResponse) {
        if (tempAirlines?.[airlineId] && frontDeskUsersResponse[airlineId]?.users && frontDeskUsersResponse[airlineId].users.length) {
          for (const airlineUser of frontDeskUsersResponse[airlineId].users) {
            if (airlineUser?.confirmed && !airlineUser?.blocked && airlineUser.id !== user.user?.id) {
              if (!airlineFrontDeskUsers?.[airlineUser.id]) {
                airlineFrontDeskUsers[airlineUser.id] = {...airlineUser, airlines: []};
              }
              airlineFrontDeskUsers[airlineUser.id].airlines.push(tempAirlines[airlineId]);
            }
          }
        }
      }
      setFrontDeskUsers(airlineFrontDeskUsers)
    }
  }, [user.user, airlines, frontDeskUsersResponse]);

  /**
   * Remove airline front desk users
   * 
   * @param {*} airlineId      airline Id
   * @param {*} request      Request
  */
  const removeAirlineFrontDeskUsers = useCallback(async (airlineId, request) => {
    if (airlineId && request) {
      try {
        const response = await updateArlineUsers(airlineId, request);
        setFrontDeskUsers(oldFrontDeskUsers => {
          let tempFrontDeskUsers = _.cloneDeep(oldFrontDeskUsers)
          for (const userId of request.DeleteRequest.userIds) {
            if (tempFrontDeskUsers[userId]?.airlines && tempFrontDeskUsers[userId].airlines.length > 0) {
              for (const airlineKey in tempFrontDeskUsers[userId].airlines) {
                const airline = tempFrontDeskUsers[userId].airlines[airlineKey]
                if (Number(airline.id) === Number(airlineId)) {
                  (tempFrontDeskUsers[userId].airlines).splice(airlineKey, 1);
                }
              }
              if (tempFrontDeskUsers[userId]?.airlines.length <= 0) {
                delete tempFrontDeskUsers[userId];
              }
            }
          }
          return tempFrontDeskUsers;
        });
        return response;
      } catch (e) {
        throw e
      }
    }
  }, []);

  const removeUser = useCallback(async (airlineIds, userId) => {
    if (airlineIds && airlineIds.length && userId) {
      let promises = [];

      for (const airlineId of airlineIds) {
        const request = {
          DeleteRequest: {
            userIds: [ userId ]
          }
        };
        promises.push(removeAirlineFrontDeskUsers(airlineId, request))
      }

      if (promises.length) {
        startLoader();
        await Promise.all(promises).then(() => {
          toast.success(toastMessages.airline.removeAirlineGroundStaff.success, {
            autoClose: maxToastTime,
            toastId: 'remove-airline-ground-staff-user-success',
          })
        }).catch((error) => {
          toast.success(toastMessages.airline.removeAirlineGroundStaff.error, {
            autoClose: maxToastTime,
            toastId: 'remove-airline-ground-staff-user-error',
          })
        }).finally(() => {
          stopLoader();
        });
      }
    }
  }, [removeAirlineFrontDeskUsers, startLoader, stopLoader]);

  // Call API to send invite email to airline staff here
  const onSubmit = useCallback((data) => {
    // Call API to send an invite email to the Airline staff
    if (data && data?.airlineId) {
      // Call API
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const request = {
            ...data,
            inviterId: user.user.id,
            role: userRoles.airlineGroundStaff,
            callbackUrl: `${getSiteUrl()}/user/accept-invite`
          }
          const response = await inviteAirlineFrontDeskUsers(data.airlineId, request);
          resolve(response)
        } catch(e) {
          reject(e)
        }
      }).then((response) => {
        toast.success(toastMessages.airline.inviteAirlineGroundStaff.success, {
          autoClose: maxToastTime,
          toastId: 'invite-airline-ground-staff-user-success',
        })
      }).catch((error) => {
        toast.error(toastMessages.airline.inviteAirlineGroundStaff.error, {
          autoClose: maxToastTime,
          toastId: 'invite-airline-ground-staff-user-error',
        })
      }).finally(() => {
        stopLoader();
      });
    }
  }, [startLoader, stopLoader, user.user, inviteAirlineFrontDeskUsers]);

  // fetching airline details by using airlineId and airportId to prefill QR options and image for unique per airline
  const fetchLastAccessedAirlineDetails = async (airlineId, airportId) => {
    const airlineDetails = await getAirlineDetailsViaAPI(airlineId);
    if (airlineDetails && airlineDetails?.data) {
      setQRTypeForAirline(airlineDetails.data.qr_code_type);
    }
    if(airlineDetails.data.qr_code_type === 'unique_per_airline_airport'){
      const responseUniqueAirline = await updateQRSettingViaAPI(airlineId, airportId)
      setQrURLForUniqueAirline(responseUniqueAirline?.qr_code?.url)
    }else{
      setQrURLForUniqueAirline(null)
    }
  };

  useEffect(() => {
    if (airlineId && airportId) {
      fetchLastAccessedAirlineDetails(airlineId, airportId);
    }
  }, [airlineId, airportId]);

  // Call API to update qr code type for airline here
  const onSubmitQRtypeForAirline = useCallback((qrCodeTypeProp) => {
    if (qrCodeTypeProp && airlineId) {
      // Call API
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const request = {
            qr_code_type: qrCodeTypeProp,
          }
          const response = await updateArlineViaAPI(airlineId, request);
          if(qrCodeTypeProp === 'unique_per_airline_airport'){
            const responseUniqueAirline = await updateQRSettingViaAPI(airlineId, airportId)
            setQrURLForUniqueAirline(responseUniqueAirline?.qr_code?.url)
          }else {
            setQrURLForUniqueAirline(null)
          }
          resolve(response)
        } catch(e) {
          reject(e)
        }
      }).then((response) => {
        toast.success(toastMessages.airline.qrCodeSettingForAirline.success, {
          autoClose: maxToastTime,
          toastId: 'update-qr-code-type-for-airline-success',
        })
      }).catch((error) => {
        toast.error(toastMessages.airline.qrCodeSettingForAirline.error, {
          autoClose: maxToastTime,
          toastId: 'update-qr-code-type-for-airline-error',
        })
      }).finally(() => {
        stopLoader();
      });
    }
  }, [startLoader, stopLoader, airlineId, updateArlineViaAPI]);

  const onCopyLayoverLink = (link) => {
    navigator.clipboard.writeText(link);
  };

  return (
    <Box sx={{ p: 3, backgroundColor: theme.mode === 'light' && theme.palette.common.white, borderRadius: theme.borderRadius[10] }}>
      <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', pt: '0 !important' }}>
        <Grid item xs={12} sx={{ py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
          <SubHeading title='QR Code Generation Setting' />
        </Grid>
        <Grid item xs={12} sx={{ py: 1 }}>
          <Radio
            value={qrTypeForAirline}
            options={[
              {
                value: 'unique_per_airline_airport',
                label: 'Unique Per Airline'
              },
              {
                value: 'unique_per_layover',
                label: 'Unique Per Layover'
              }
            ]}
            onChange={(e) => {
              setQRTypeForAirline(e.target.value);
              onSubmitQRtypeForAirline(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      {qrURLForUniqueAirline ? (
        <Box>
          <Grid item xs={12}>
            <Box sx={{pb:3}}>
              <img
                src={process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(qrURLForUniqueAirline) : qrURLForUniqueAirline}
                loading='lazy'
                alt='QR'
                style={{
                  height: theme.spacing(19),
                  width: theme.spacing(19),
                  marginLeft:theme.spacing(2)
                }}
              />
            </Box>
          </Grid>
        </Box>
      ):(null)}
      
      {qrTypeForAirline === 'unique_per_airline_airport' ? (
        <Box>
          <RoseGoldButton sx={{px:1}}
            onClick={() => printQrCode(`QrCode for ${airlineId}`,
              (process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(qrURLForUniqueAirline) : qrURLForUniqueAirline),
              airline?.name, qrLinkForAirline
            )}
            variant='text'
            startIcon={<GetAppIcon />}
          >
            Download QR Code
          </RoseGoldButton>
          <RoseGoldButton variant='text' onClick={() => onCopyLayoverLink(qrLinkForAirline)
          }> Copy QR Code Link </RoseGoldButton>
        </Box>
      ) :(null)}

      {/* this section will be hidden for now only LAYOV-190 */}
      {/* <Grid item xs={12} sx={{ marginTop: 4, color: theme.mode === 'light' ? theme.palette.background.default : 'inherit' }}>>
        <SubHeading title='Manage Airlines' />
        <Grid container spacing={2}>
          {(airlines && airlines.length) ? (
            airlines.map((airline, index) => (
              <Grid item xs={4} key={`airlines-${index}`}>
                <AirlineListingCard val={airline} />
              </Grid>
            ))): (
              <Grid item xs={12}>
                <Typography variant='subtitle2'>
                  You do not have access to any airline. Please contact the administrator if you believe this is incorrect.
                </Typography>
              </Grid>
            )
          }
        </Grid>
      </Grid> */}

      <Box sx={{ mt: 5 }}>
        <SubHeading title='Airline Ground Staff' />
        <Grid item xs={12} sx={{ mt: 2.5 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Controller
                  name='airlineId'
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder='Select airline'
                      options={airlineOptions}
                      formControlProps={{
                        sx: {
                          marginBottom: 0,
                        },
                      }}
                      // sx={{
                      //   backgroundColor: theme.palette.bg[200]
                      // }}
                      isError={!!errors.airlineId}
                      errorMessage={
                        errors.airlineId && 'Please select a airline'
                      }
                      inputRef={field.ref}
                      selectProps={{
                        onChange: field.onChange,
                        autoFocus: true,
                        value: field.value ? field.value : '',
                      }}
                      {...field}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name='airportId'
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder='Select airport'
                      options={airportOptions}
                      formControlProps={{
                        sx: {
                          marginBottom: 0,
                        },
                      }}
                      // sx={{
                      //   backgroundColor: theme.palette.bg[200]
                      // }}
                      isError={!!errors.airportId}
                      errorMessage={
                        errors.airportId && 'Please select a airport'
                      }
                      inputRef={field.ref}
                      selectProps={{
                        onChange: field.onChange,
                        autoFocus: true,
                        value: field.value ? field.value : '',
                      }}
                      {...field}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name='emailAddress'
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder='Enter email address'
                      formControlProps={{
                        sx: {
                          marginBottom: 0,
                        },
                      }}
                      sx={{
                        backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
                      }}
                      isError={!!errors.emailAddress}
                      errorMessage={
                        errors.emailAddress &&
                        'Please enter a valid email address'
                      }
                      inputRef={field.ref}
                      autoFocus
                      {...field}
                      endAdornment={
                        <InputEndAdornment
                          position='end'
                          color={theme.palette.primary.light}
                          backgroundColor={theme.mode === 'light' && theme.palette.warning.dark}
                        >
                          <Button type='submit'>Send Invite</Button>
                        </InputEndAdornment>
                      }
                    />
                  )}
                  rules={{
                    required: true,
                    pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Box>

      <Grid item xs={12} sx={{ marginTop: 4 }}>
        <Grid container spacing={2}>
          {(frontDeskUsers && Object.keys(frontDeskUsers).length) ? (
            Object.keys(frontDeskUsers).map((userId, i) => {
              let frontDeskUser = frontDeskUsers[userId]
              return (
                <Grid item xs={4} key={`fdu-${i}`}>
                  <AirlineGroundStaffCard
                    airlineGroundStaffMember={frontDeskUser}
                    removeUser={removeUser}
                  />
                </Grid>
              )
            })
          ) : (null)}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;

const QrDocument = ({image, airlineName, addPassengerLink}) => {
  return(
    <Document>
      <Page size='A4' style={styles.page}>
        <Text style={styles.airlineName}>{airlineName}</Text>
        <Image 
          style={styles.image}
          src={image}
          cache={false} allowDangerousPaths={true}
        />
        <PdfLink style={styles.addPassengerLink} src={addPassengerLink}>{addPassengerLink}</PdfLink>
      </Page>
    </Document>
  )
};
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useLoader } from '../../../providers/loader';
import moment from 'moment';
import { Heading } from '../components/heading';
import { HotelContext } from '../../../context/hotel';
import { Input } from '../../../components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import SetReminder from './setReminder';
import RoomRates from './roomRates';
import AvailableRooms from './availableRooms';
import SelectMealsAndShuttle from './selectMealsAndShuttle';
import { getAvailability, updateAvailability } from '../../../services/hotel/hotelAvailability';
import { getHotelRoomTypes } from '../../../services/hotel/hotelRoomTypes';
import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../../../helper/constant';
import _ from 'lodash';
import { getHotelReminder, setHotelReminder } from '../../../services/hotel/hotelReminders';
import { codeData as getCurrencyCodeData } from 'better-currency-codes';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const cellWidth = 100;
const stickyColWidth = 200;
// This is used to wait for auto-save the values entered into the input field
const maxTypingTimeout = 2000;

const mealOptions= [
  {
    key: 0,
    name: 'No'
  },
  {
    key: 1,
    name: 'Yes'
  }
];

const StyledButton = styled(Button)(({ theme }) => ({
  ...theme.typography.textLarge,
  textTransform: 'capitalize',
  color: theme.palette.roseGold[100],
  '& .MuiButton-startIcon': {
    marginRight: '4px'
  }
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: '96vh',
  border: 'unset',
  boxShadow: 'unset',
  marginTop: theme.spacing(4)
}));

const StyledTable = styled(Table)(({ theme }) => ({
  tableLayout: 'fixed',
  width: '100%',
  backgroundColor: theme.palette.background.default,
  border: 'unset'
}));

const StyledStickyTableHeaderCell = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  width: stickyColWidth,
  border: 'unset',
  padding: 0,
  zIndex: '5'
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  width: cellWidth,
  borderRadius: 4,
  border: `1px solid ${theme.palette.table.divider}`,
  padding: '5px 10px',
  zIndex: '4',
  backgroundColor:theme.palette.alert.light
}));

const StyledStickyTableCell = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  backgroundColor: theme.palette.background.default,
  border: 'unset',
  paddingLeft: 0,
  height: '100%',
  zIndex: '3',
  '& .btn-row-visible-ico': {
    marginTop: '-13px',
    '& .row-visible-ico': {
      color: theme.palette.icon.light
    }
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  width: cellWidth,
  border: 'unset',
  padding: '8px 0'
}));

const TableCellBox = styled(Box, {
  shouldForwardProp: (prop) => ['inactive'].indexOf(prop) === -1
})(({ theme, inactive }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRight: `1px solid ${theme.palette.table.divider}`,
  padding: '8px',
  textAlign: 'center',
  // opacity: inactive ? .5 : 1
}));

const StyledHeaderColTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  textTransform: 'uppercase'
}));

const StyledSubHeadingBox = styled(Box)(({ theme }) => ({
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingRight: '8px',
  paddingLeft: '8px',
  borderLeft: `1px solid ${theme.palette.grey[800]}`,
  height: '100%',
  display: 'flex',
  alignItems: 'center'
}));

const Availability = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const formRef = useRef(null);
  const { hotel, setIsCommonTopbar } = useContext(HotelContext);
  const { startLoader, stopLoader } = useLoader();
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().add(14, 'days').format('YYYY-MM-DD'));
  const [roomAvailability, setRoomAvailability] = useState(null);
  const [availability, setAvailability] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reminder, setReminder] = useState(null);
  const [currency, setCurrency] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    shouldUnregister: true,
    mode: 'all'
  });

  useEffect(() => {
    setIsCommonTopbar(false)
  }, [setIsCommonTopbar])

  useEffect(() => {
    let hotelCurrency = '';
    if (hotel?.currency?.alphabetic_code) {
      const currencyCodeData = getCurrencyCodeData({ code: hotel.currency.alphabetic_code, numeric: false });
      hotelCurrency = currencyCodeData?.name?.unit?.plural ? currencyCodeData.name.unit.plural : hotel.currency.alphabetic_code;
    }
    setCurrency(hotelCurrency);
  }, [hotel?.currency?.alphabetic_code]);

  /**
   * Get hotel room types from API
   * 
   * @param {*} hotelId   Hotel id 
   */
  const getHotelRoomTypesFromApi = useCallback(async (hotelId) => {
    try {
      const request = {
        'pagination[page]': '1',
        'pagination[pageSize]': '10000',
      };
      const response = await getHotelRoomTypes(hotelId, request);
      if (response?.data && response.data.length) {
        return response.data;
      }
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Call API to get the availability
   * 
   * @param {*} hotelId   Hotel Id
   * @param {*} fromDate  From date
   * @param {*} toDate    To date
   */
  const getAvailabilityFromApi = useCallback(async (hotelId, fromDate, toDate) => {
    try {
      const request = {
        hotelId: hotelId,
        startDate: fromDate,
        endDate: toDate
      };
      const response = await getAvailability({params: request});
      if (response?.availability) {
        return response.availability;
      }
    } catch (e) {
      throw e
    }
  }, [])

  /**
   * Call API to update the availability
   * 
   * @param {*} hotelId   Hotel Id
   * @param {*} request   Request
   */
  const updateAvailabilityFromApi = useCallback(async (hotelId, request) => {
    try {
      if (request && Object.keys(request)) {
        request = {
          hotelId: hotelId,
          ...request
        };
        const response = await updateAvailability(request);
        if (response?.availability) {
          return response.availability;
        }
      }
    } catch (e) {
      throw e
    }
  }, [])

  /**
   * Call API to set reminder for the availability
   * 
   * @param {*} hotelId   Hotel Id
   * @param {*} request   Request
   */
  const setHotelReminderFromApi = useCallback(async (hotelId, request) => {
    try {
      if (request && Object.keys(request)) {
        const response = await setHotelReminder(hotelId, request);
        return response;
      }
    } catch (e) {
      throw e
    }
  }, [])

  /**
   * Call API to get the reminder
   *
   * @param {*} hotelId   Hotel Id
   */
    const getHotelReminderFromApi = useCallback(async (hotelId) => {
      try {
        if (hotelId) {
          const request = {hotelId: hotelId};
          const response = await getHotelReminder(hotelId, request);
          if (response?.remider) {
            return response.remider;
          }
        }
      } catch (e) {
        throw e;
      }
    }, []);

    // fetching hotel reminder using get api from services
    useEffect(() => {
      if (hotel && hotel?.id) {
        startLoader();
        new Promise((resolve, reject) => {
          getHotelReminderFromApi(hotel.id)
            .then((response) => {
              setReminder(response);
              resolve(response);
            })
            .catch((error) => {
              setReminder(null);
              console.error('Error fetching hotel reminder:', error);
              // reject(error);
            });
        })
        .finally(() => {
          stopLoader();
        });
      } else {
        setReminder(null);
      }
    }, [hotel, startLoader, stopLoader, getHotelReminderFromApi]);

  /**
   * Add n number of days to startDate and endDate
   * 
   * @param {*} n   Number of days to be add in dates
   */
  const addDays = (n) => {
    setStartDate(moment(startDate).add(n, 'days').format('YYYY-MM-DD'));
    setEndDate(moment(endDate).add(n, 'days').format('YYYY-MM-DD'));
  };


  /**
   * Subtract n number of days from startDate and endDate
   * 
   * @param {*} n   Number of days subtract from dates
   */
  const subtractDays = (n) => {
    setStartDate(moment(startDate).subtract(n, 'days').format('YYYY-MM-DD'));
    setEndDate(moment(endDate).subtract(n, 'days').format('YYYY-MM-DD'));
  };

  const isDateInactive = (availabilityArr, date) => { return ((availabilityArr?.[date] && availabilityArr?.[date]?.is_open_for_layover) ? false : true) };

  // Call API to set the reminder
  const onSetReminder = useCallback((hotelId, reminderRequest) => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        const response = await setHotelReminderFromApi(hotelId, reminderRequest);
        resolve(response)
      } catch(e) {
        reject(e)
      }
    }).then((response) => {
      toast.success(toastMessages.hotel.updateHotelReminder.success, {
        autoClose: maxToastTime,
        toastId: 'update-hotel-reminder',
      })
    }).catch((e) => {
      toast.error(toastMessages.hotel.updateHotelReminder.error, {
        autoClose: maxToastTime,
        toastId: 'update-hotel-reminder',
      })
    }).finally(() => {
      stopLoader();
    });
  }, [startLoader, stopLoader, setHotelReminderFromApi]);

  // Call API's on form submit
  // NOTE: THIS FUNCTION IS NOT USED FOR NOW. AS DISCUSSED OVER A JIRA TICKET LAYOV-25 WITH HERMANN, WE NEED TO AUTO SAVE THE DATA ON FIELD CHANGE
  const onSubmit = useCallback(async (data) => {
    console.log('form data', data)
  }, []);

  // Call API on foem field change
  const onFieldChange = useCallback(async (hotelId, request = {}) => {
    // Call API
    if (hotelId && request && Object.keys(request) && request?.date) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await updateAvailabilityFromApi(hotelId, request);
          resolve(response)
        } catch(e) {
          reject(e)
        }
      }).then((response) => {
        // Update availability in the state variables
        if (request?.roomTypes) {
          setRoomAvailability(oldRoomAvailability => {
            let tempRoomAvailability = _.cloneDeep(oldRoomAvailability)
            for (const roomType of request.roomTypes) {
              if (roomType?.roomTypeId && tempRoomAvailability?.[`room_${roomType.roomTypeId}`]) {
                if (roomType?.available_rooms !== undefined && tempRoomAvailability[`room_${roomType.roomTypeId}`]?.availableRooms?.[request.date] !== undefined) {
                  tempRoomAvailability[`room_${roomType.roomTypeId}`].availableRooms[request.date] = Number(roomType.available_rooms);
                } else if (roomType?.price !== undefined && tempRoomAvailability[`room_${roomType.roomTypeId}`]?.prices?.[request.date] !== undefined) {
                  tempRoomAvailability[`room_${roomType.roomTypeId}`].prices[request.date] = Number(roomType.price);
                }
              }
            }
            return tempRoomAvailability;
          })
        } else {
          setAvailability(oldAvailability => {
            let tempAvailability = _.cloneDeep(oldAvailability)
            if (tempAvailability?.[request.date]) {
              tempAvailability[request.date] = {
                ...tempAvailability[request.date],
                ...request
              }
            }
            return tempAvailability;
          })
        }
        toast.success(toastMessages.hotel.updateHotelAvailability.success, {
          autoClose: maxToastTime,
          toastId: 'update-hotel-availability',
        })
      }).catch((e) => {
        toast.error(toastMessages.hotel.updateHotelAvailability.error, {
          autoClose: maxToastTime,
          toastId: 'update-hotel-availability',
        })
      }).finally(() => {
        stopLoader();
      });
    }
  }, [startLoader, stopLoader, updateAvailabilityFromApi]);

  // for available rooms
  const forwardRoomFromFirstInputToAllInputsInRow = (roomId, firstInputValue, date) => {
    const updatedRoomAvailability = { ...roomAvailability };
    Object.keys(updatedRoomAvailability).forEach((roomKey) => {
      const room = updatedRoomAvailability[roomKey];
      const roomAvailableRooms = { ...room.availableRooms };
      Object.keys(roomAvailableRooms).forEach((roomDate) => {
        if (roomDate !== date) {
          roomAvailableRooms[roomDate] = firstInputValue;
        }
      });
      updatedRoomAvailability[roomKey] = { ...room, availableRooms: roomAvailableRooms };
    });
    Object.keys(updatedRoomAvailability).forEach((index) => {
      const room = updatedRoomAvailability[index];
      if (room.id === roomId) {
        Object.entries(room.availableRooms).forEach(([roomDate, availableRooms]) => {
          const request = {
            date: roomDate,
            roomTypes: [{ available_rooms: firstInputValue, roomTypeId: room.id }],
          };
          onFieldChange(hotel.id, request);
        });
      }
    });
  };

  // for room prices
  const forwardPriceFromFirstInputToAllInputsInRow = (roomId, firstInputValue, date) => {
    const updatedRoomAvailability = { ...roomAvailability };
    Object.keys(updatedRoomAvailability).forEach((roomKey) => {
      const room = updatedRoomAvailability[roomKey];
      const updatedPrices = { ...room.prices };
      Object.keys(updatedPrices).forEach((roomDate) => {
        if (roomDate !== date) {
          updatedPrices[roomDate] = firstInputValue;
        }
      });
      updatedRoomAvailability[roomKey] = { ...room, prices: updatedPrices };
    });
    Object.keys(updatedRoomAvailability).forEach((roomKey) => {
      const room = updatedRoomAvailability[roomKey];
      if (room.id === roomId) {
        Object.entries(room.prices).forEach(([roomDate, roomPrice]) => {
          const request = {
            date: roomDate,
            roomTypes: [{ price: firstInputValue, roomTypeId: room.id }],
          };
          onFieldChange(hotel.id, request);
        });
      }
    });
  };

  useEffect(() => {
    // reset()
    // reset()
    if (hotel?.id) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await getAvailabilityFromApi(hotel.id, startDate, endDate)
          let tempRoomAvailability = {};
          // Call API to get room types
          try {
            const roomResponse = await getHotelRoomTypesFromApi(hotel.id);
            if (roomResponse && roomResponse.length > 0) {
              for(const room of roomResponse) {
                tempRoomAvailability[`room_${room?.id}`] = {...room, availableRooms: {}, prices: {}};
              }
            }
          } catch(e) {
            throw e
          }
          for (const dateKey in response) {
            if (response[dateKey]?.roomTypes) {
              for (const roomType of response[dateKey]?.roomTypes) {
                if (tempRoomAvailability && tempRoomAvailability?.[`room_${roomType?.roomTypeId}`]) {
                  tempRoomAvailability[`room_${roomType.roomTypeId}`].availableRooms[response[dateKey].date] = roomType.available_rooms;
                  tempRoomAvailability[`room_${roomType.roomTypeId}`].prices[response[dateKey].date] = roomType.price;
                }
              }
              delete response[dateKey]?.roomTypes;
            }
          }

          if (tempRoomAvailability) {
            // Convert the object into an array of key-value pairs
            let sortedEntries = Object.entries(tempRoomAvailability).sort(
              (firstObj, secondObj) => firstObj[1].max_adults - secondObj[1].max_adults
            );
            
            // Convert the sorted entries back into an object
            let sortedRoomAvailability = Object.fromEntries(sortedEntries);
            setRoomAvailability(sortedRoomAvailability)
          }
          resolve(response)
        } catch (e) {
          reject(e)
        }
      }).then((response) => {
        setAvailability(response)
      }).catch((e) => {
      }).finally(() => {
        stopLoader();
      });
    }
  }, [hotel, startLoader, stopLoader, getAvailabilityFromApi, getHotelRoomTypesFromApi, startDate, endDate]);

  return (
    <Box sx={{py: 1, backgroundColor: theme.mode === 'light' && theme.palette.common.white, borderRadius: theme.borderRadius[10]}}>
      {hotel ? (
        <Box>
          <Box sx={{
            display: 'flex',
            px: 2
          }}>
            <Heading title='Availability Management' sx={{flexGrow: 1}} />
            {availability ? (
              <Box>
                <StyledButton
                  variant='text'
                  onClick={() => navigate('/rates-conditions')}
                  startIcon={<EditIcon />}
                  sx={{mr:2}}
                >Edit Defaults</StyledButton>
                <StyledButton
                  variant='text'
                  startIcon={<InsertInvitationIcon />}
                  onClick={() => {
                    setDialogOpen(true)
                  }}
                >Set Reminder</StyledButton>
              </Box>
            ) : (null)}
          </Box>
          {availability ? (
            <FormProvider {...{control, handleSubmit, formState: { errors }, setValue}}>
              <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                <Box sx={{
                  pl: 1,
                  pr: 2
                }}>
                  <Controller
                    name={'hotelId'}
                    control={control}
                    defaultValue={hotel.id}
                    render={({ field }) => (
                      <Input
                        type='hidden'
                        formControlProps={{sx: {m:0}}}
                        sx={{border:0}}
                        {...field}
                      />
                    )}
                  />
                  <StyledTableContainer component={Paper}>
                    <StyledTable
                      stickyHeader
                      aria-label='Availability Management'
                    >
                      <TableHead>
                        <TableRow>
                          <StyledStickyTableHeaderCell>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                              <IconButton onClick={() => subtractDays(15)}>
                                <ChevronLeftIcon />
                              </IconButton>
                              <Typography sx={{
                                ...theme.typography.textMedium,
                                ...theme.typography.fontMedium600,
                                color: theme.palette.menu.light,
                                flexGrow: 1,
                                textAlign: 'center'
                              }}>
                                {moment(startDate).format('MMM DD, YYYY')}
                                <br/>-<br/>
                                {moment(endDate).format('MMM DD, YYYY')}
                              </Typography>
                              <IconButton onClick={() => addDays(15)}>
                                <ChevronRightIcon />
                              </IconButton>
                            </Box>
                          </StyledStickyTableHeaderCell>
                          {(availability && Object.keys(availability).length) ? (
                            Object.keys(availability).map((key, i) => {
                              let date = availability[key]?.date
                              return (
                                <StyledTableHeaderCell key={`${i}-${date}`}>
                                  {moment(date).format('DD')}
                                  <br/>
                                  {moment(date).format('dd')[0]}
                                </StyledTableHeaderCell>
                              )
                            })
                          ) : (null)}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {/* <TableRow>
                          <StyledStickyTableCell
                            component='th'
                            scope='row'
                          >
                            <Box sx={{display: 'flex', py: 1}}>
                              <IconButton className='btn-row-visible-ico'>
                                <VisibilityIcon className='row-visible-ico' />
                              </IconButton>
                              <StyledHeaderColTypography variant='caption'>
                                Open for Layover Bookings
                              </StyledHeaderColTypography>
                            </Box>
                          </StyledStickyTableCell>
                          {(availability && Object.keys(availability).length) ? (
                            Object.keys(availability).map((key, i) => {
                              let date = availability[key]?.date
                              let isOpen = availability[key]?.is_open_for_layover ? 1 : 0
                              return (
                                <StyledTableCell key={`ofl-${isOpen}-${date}`} >
                                  <TableCellBox sx={{backgroundColor: theme.palette.alert.light}}>
                                    <OpenBookings
                                      date={date}
                                      defaultValue={isOpen}
                                      hotelId={hotel.id}
                                      onChange={onFieldChange}
                                    />
                                  </TableCellBox>
                                </StyledTableCell>
                              )
                            })
                          ) : (null)}
                        </TableRow> */}

                        <TableRow>
                          <StyledStickyTableCell
                            component='td'
                            scope='row'
                            sx={{pb: 0}}
                            colSpan={10}
                          >
                            <Box sx={{display: 'flex'}}>
                              <IconButton className='btn-row-visible-ico'>
                                <VisibilityIcon className='row-visible-ico' />
                              </IconButton>
                              <StyledHeaderColTypography variant='caption'>
                                Available Rooms
                              </StyledHeaderColTypography>
                            </Box>
                          </StyledStickyTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                        
                        {roomAvailability ? (
                          (roomAvailability && Object.keys(roomAvailability).length) ? (
                            Object.keys(roomAvailability).map((roomKey, i) => {
                              let room = roomAvailability[roomKey]
                              return (
                                <TableRow sx={{height:'100%'}} key={i}>
                                  <StyledStickyTableCell
                                    component='td'
                                    scope='row'
                                    sx={{py: 0, pr: 1, pl: 5}}
                                  >
                                    <StyledSubHeadingBox>
                                      {room?.name && room.name.toLowerCase().includes('room') ? `${room.name}s` : room.name}
                                    </StyledSubHeadingBox>
                                  </StyledStickyTableCell>
                                  {Object.keys(room?.availableRooms).map((date, i) => {
                                    const availableRooms = room.availableRooms[date]
                                    return (
                                      <StyledTableCell key={`rts-${date}`}>
                                        <TableCellBox inactive={isDateInactive(availability, date)} sx={{p:0, display: 'flex'}}>
                                          <AvailableRooms
                                            date={date}
                                            roomTypeId={room.id}
                                            defaultValue={availableRooms}
                                            // disabled={isDateInactive(availability, date)}
                                            timeout={maxTypingTimeout}
                                            hotelId={hotel.id}
                                            onChange={onFieldChange}
                                          />
                                          {i === 0 ? (
                                            <Tooltip title='Insert same value for next 14 days.' arrow>
                                              <IconButton onClick={() => forwardRoomFromFirstInputToAllInputsInRow(room?.id, availableRooms, date)}>
                                                <KeyboardDoubleArrowRightIcon sx={{color: theme.palette.menu.main}}/>
                                              </IconButton>
                                            </Tooltip>
                                          ) : <></>}
                                        </TableCellBox>
                                      </StyledTableCell>
                                    )
                                  })}
                                </TableRow>
                              )
                            })
                          ) : (null)
                        ) : (null)}

                        <TableRow>
                          <StyledStickyTableCell
                            component='td'
                            scope='row'
                            sx={{pt:5,pb: 0}}
                            colSpan={10}
                          >
                            <Box sx={{display: 'flex'}}>
                              <IconButton className='btn-row-visible-ico'>
                                <VisibilityIcon className='row-visible-ico' />
                              </IconButton>
                              <StyledHeaderColTypography variant='caption'>
                                Room rate Including VAT & Taxes without Meals in {currency}
                              </StyledHeaderColTypography>
                            </Box>
                          </StyledStickyTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>

                        {roomAvailability ? (
                          (roomAvailability && Object.keys(roomAvailability).length) ? (
                            Object.keys(roomAvailability).map((roomKey, i) => {
                              let room = roomAvailability[roomKey]
                              return (
                                <TableRow sx={{height:'100%'}} key={i}>
                                  <StyledStickyTableCell
                                    component='td'
                                    scope='row'
                                    sx={{py: 0, pr: 1, pl: 5}}
                                  >
                                    <StyledSubHeadingBox>
                                      {room.name}
                                    </StyledSubHeadingBox>
                                  </StyledStickyTableCell>
                                  {Object.keys(room?.prices).map((date, i) => {
                                    const price = room.prices[date]
                                    return (
                                      <StyledTableCell key={`rts-${date}`}>
                                        <TableCellBox inactive={isDateInactive(availability, date)} sx={{p:0, display: 'flex'}}>
                                          <RoomRates
                                            date={date}
                                            roomTypeId={room.id}
                                            defaultValue={price}
                                            // disabled={isDateInactive(availability, date)}
                                            timeout={maxTypingTimeout}
                                            hotelId={hotel.id}
                                            onChange={onFieldChange}
                                          />
                                          {i === 0 ? (
                                            <Tooltip title='Insert same value for next 14 days.' arrow>
                                              <IconButton onClick={() => forwardPriceFromFirstInputToAllInputsInRow(room?.id, price, date)}>
                                                <KeyboardDoubleArrowRightIcon sx={{color: theme.palette.menu.main}}/>
                                              </IconButton>
                                            </Tooltip>
                                          ) : <></>}
                                        </TableCellBox>
                                      </StyledTableCell>
                                    )
                                  })}
                                </TableRow>
                              )
                            })
                          ) : (null)
                        ) : (null)}

                        <TableRow>
                          <StyledStickyTableCell
                            component='td'
                            scope='row'
                            sx={{pt:5,pb: 0}}
                            colSpan={10}
                          >
                            <Box sx={{display: 'flex'}}>
                              <IconButton className='btn-row-visible-ico'>
                                <VisibilityIcon className='row-visible-ico' />
                              </IconButton>
                              <StyledHeaderColTypography variant='caption'>
                                Food Availability
                              </StyledHeaderColTypography>
                            </Box>
                          </StyledStickyTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>

                        <TableRow sx={{height:'100%'}}>
                          <StyledStickyTableCell
                            component='td'
                            scope='row'
                            sx={{py: 0, pr: 1, pl: 5}}
                          >
                            <StyledSubHeadingBox>
                              Breakfast
                            </StyledSubHeadingBox>
                          </StyledStickyTableCell>
                          {(availability && Object.keys(availability).length) ? (
                            Object.keys(availability).map((key, i) => {
                              let date = availability[key]?.date
                              let isBreakfast = availability[key]?.is_breakfast ? 1 : 0
                              return (
                                <StyledTableCell key={`rts-${date}`}>
                                  <TableCellBox inactive={isDateInactive(availability, date)} sx={{background: theme.palette.alert.light}}>
                                    <SelectMealsAndShuttle
                                      entity='is_breakfast'
                                      date={date}
                                      defaultValue={isBreakfast}
                                      options={mealOptions}
                                      // disabled={isDateInactive(availability, date)}
                                      hotelId={hotel.id}
                                      onChange={onFieldChange}
                                    />
                                  </TableCellBox>
                                </StyledTableCell>
                              )
                            })
                          ) : (null)}
                        </TableRow>

                        <TableRow sx={{height:'100%'}}>
                          <StyledStickyTableCell
                            component='td'
                            scope='row'
                            sx={{py: 0, pr: 1, pl: 5}}
                          >
                            <StyledSubHeadingBox>
                              Lunch
                            </StyledSubHeadingBox>
                          </StyledStickyTableCell>
                          {(availability && Object.keys(availability).length) ? (
                            Object.keys(availability).map((key, i) => {
                              let date = availability[key]?.date
                              let isLunch = availability[key]?.is_lunch ? 1 : 0
                              return (
                                <StyledTableCell key={`rts-${date}`}>
                                  <TableCellBox inactive={isDateInactive(availability, date)} sx={{background: theme.palette.alert.light}}>
                                    <SelectMealsAndShuttle
                                      entity='is_lunch'
                                      date={date}
                                      defaultValue={isLunch}
                                      options={mealOptions}
                                      // disabled={isDateInactive(availability, date)}
                                      hotelId={hotel.id}
                                      onChange={onFieldChange}
                                    />
                                  </TableCellBox>
                                </StyledTableCell>
                              )
                            })
                          ) : (null)}
                        </TableRow>

                        <TableRow sx={{height:'100%'}}>
                          <StyledStickyTableCell
                            component='td'
                            scope='row'
                            sx={{py: 0, pr: 1, pl: 5}}
                          >
                            <StyledSubHeadingBox>
                              Dinner
                            </StyledSubHeadingBox>
                          </StyledStickyTableCell>
                          {(availability && Object.keys(availability).length) ? (
                            Object.keys(availability).map((key, i) => {
                              let date = availability[key]?.date
                              let isDinner = availability[key]?.is_dinner ? 1 : 0
                              return (
                                <StyledTableCell key={`rts-${date}`}>
                                  <TableCellBox inactive={isDateInactive(availability, date)} sx={{background: theme.palette.alert.light}}>
                                    <SelectMealsAndShuttle
                                      entity='is_dinner'
                                      date={date}
                                      defaultValue={isDinner}
                                      options={mealOptions}
                                      // disabled={isDateInactive(availability, date)}
                                      hotelId={hotel.id}
                                      onChange={onFieldChange}
                                    />
                                  </TableCellBox>
                                </StyledTableCell>
                              )
                            })
                          ) : (null)}
                        </TableRow>

                        <TableRow>
                          <StyledStickyTableCell
                            component='td'
                            scope='row'
                            sx={{pt:5,pb: 0}}
                          >
                            <Box sx={{display: 'flex'}}>
                              <IconButton className='btn-row-visible-ico'>
                                <VisibilityIcon className='row-visible-ico' />
                              </IconButton>
                              <StyledHeaderColTypography variant='caption'>
                                Shuttle Service
                              </StyledHeaderColTypography>
                            </Box>
                          </StyledStickyTableCell>
                          {(availability && Object.keys(availability).length) ? (
                            Object.keys(availability).map((key, i) => {
                              let date = availability[key]?.date
                              let isShuttleService = availability[key]?.is_shuttle_service ? 1 : 0
                              return (
                                <StyledTableCell key={`rts-${date}`} sx={{pt:5}}>
                                  <TableCellBox inactive={isDateInactive(availability, date)} sx= {{background: theme.palette.alert.light}}>
                                    <SelectMealsAndShuttle
                                      entity='is_shuttle_service'
                                      date={date}
                                      defaultValue={isShuttleService}
                                      options={mealOptions}
                                      // disabled={isDateInactive(availability, date)}
                                      hotelId={hotel.id}
                                      onChange={onFieldChange}
                                    />
                                  </TableCellBox>
                                </StyledTableCell>
                              )
                            })
                          ) : (null)}
                        </TableRow>

                      </TableBody>
                    </StyledTable>
                  </StyledTableContainer>
                </Box>
              </form>
            </FormProvider>
          ) : (null)}

          {/* Render set daily reminder dialog box */}
          <SetReminder
            reminder={reminder}
            hotelId={hotel.id}
            open={dialogOpen}
            onDisagree={() => setDialogOpen(false)}
            onClose={() => setDialogOpen(false)}
            onAgree={onSetReminder}
          />
        </Box>
      ) : (null)}
    </Box>
  );
};

export default Availability;
import { useContext, useEffect, useState, useCallback } from 'react';
import { Box, useTheme, Grid } from '@mui/material';
import { Heading, SubHeading } from '../components/heading';
import BillingTable from './billingTable';
import { Search as SearchIcon } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../components/input';
import { InputEndAdornment } from '../components/endAdornment';
import { HotelContext } from '../../../context/hotel';
import { useLoader } from '../../../providers/loader';
import { getHotelBillingData } from '../../../services/hotel/hotelBillingManagement';

const Billing = () => {
  const theme = useTheme();
  const [invoiceData, setInvoiceData] = useState([])
  const [filteredData, setFilteredData] = useState([]);
  const { hotel, setIsCommonTopbar } = useContext(HotelContext);
  const { startLoader, stopLoader } = useLoader();
  const { control } = useForm();

  useEffect(() => {
    setIsCommonTopbar(false)
  }, [setIsCommonTopbar])
  
  // handle search by using the airline name
  const handleSearchChange = (e) => {
    const filtered = invoiceData?.filter((item) =>
      item.airlineName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  /**
   * get hotel invoice data from API
   */
  const getHotelBillingDataFromApi = useCallback(async (hotelId) => {
    try {
      const response = await getHotelBillingData(hotelId);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  useEffect(() => {
    if (hotel?.id) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await getHotelBillingDataFromApi(hotel?.id)
          resolve(response)
        } catch (e) {
          reject(e)
        }
      }).then(response => {
        setInvoiceData(response?.billingDetails)
        setFilteredData(response?.billingDetails)
      }).catch((e) => {
        setInvoiceData([])
        setFilteredData([])
      }).finally(() => {
        stopLoader();
      });
    }
  }, [startLoader, stopLoader, hotel, hotel?.id]);

  return (
    <Box sx={{ px: 2, py: 1, backgroundColor: theme.mode === 'light' && theme.palette.common.white, borderRadius: theme.borderRadius[10] }}>
      <Heading title='Billing Management' />

      <Box sx={{ py: 2 }}>
        <SubHeading title='Invoices' />
      </Box>

      <Grid container>
        <Grid item xs={12} sm={12} md={4}>
          <Controller
            name='searchInput'
            control={control}
            render={({ field }) => (
              <Input
                placeholder='Search'
                formControlProps={{
                  sx: { marginBottom: 0 },
                }}
                sx={{
                  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
                }}
                inputRef={field.ref}
                {...field}
                onChange={handleSearchChange}
                endAdornment={
                  <InputEndAdornment
                    position='end'
                    color={theme.palette.primary.light}
                  >
                    <SearchIcon />
                  </InputEndAdornment>
                }
              />
            )}
          />
        </Grid>
      </Grid>

      <Box sx={{ py: 2 }}>
        <BillingTable invoices={filteredData} setInvoiceTableData={setInvoiceData}/>
      </Box>
    </Box>
  );
};

export default Billing;

import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Get self user details
 * 
 * @param {*} request 
 * @returns 
 */
export const getSelfUser = async(request = {}) => {
  try {
    const response = await api.get('/users/me', request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
  }
};

/**
 * Update self user details
 * 
 * @param {*} request 
 * @returns 
 */
export const updateSelfUser = async(request = {}) => {
  try {
    const response = await api.put('/user/me', request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
  }
};

/**
 * Get users
 * 
 * @param {*} request 
 * @returns 
 */
export const getUsers = async(request = {}) => {
  try {
    const response = await api.get('/users', request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
  }
};

/**
 * Get Hotel users
 * 
 * @param {*} request 
 * @returns 
 */
export const getHotelUsers = async(request = {}) => {
  try {
    const response = await api.get('/hotels-users', request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Update hotel users
 * 
 * @param {*} hotelId   Hotel ID 
 * @param {*} request   request
 * @returns 
 */
export const updateHotelUsers = async(hotelId, request = {}) => {
  try {
    const response = await api.post(`/hotels/${hotelId}/users`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
  }
};

/**
 * Invite hotel users
 * 
 * @param {*} hotelId   Hotel ID 
 * @param {*} request   request
 * @returns 
 */
export const inviteHotelUsers = async(hotelId, request = {}) => {
  try {
    const response = await api.post(`/hotels/${hotelId}/invite-users`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Request for update the user password
 * 
 * @param {*} request 
 * @returns 
 */
export const resetPasswordRequest = async(request = {}) => {
  try {
    const response = await api.post('auth/forgot-password', request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
  }
};

/**
 * User change Password
 *
 * @param {*} request
 * @returns
 */
export const resetPassword = async (request) => {
  try {
    const response = await api.post('/auth/reset-password', request)
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status)
    } else {
      return response?.data
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      let message = e?.response?.data?.error?.message
        ? e.response.data.error.message
        : e.message
      handleApiError(e.response.status, message)
    }
    throw e
  }
}
// Maximum toasts allowed
export const maxToasts = 3;
// Maximum toast timing (in seconds)
export const maxToastTime = 10000;
// localStorage key identifier
export const lsKeyIdentifier = 'CLayover__proCL';

// All user roles
export const userRoles = {
  superAdmin: 'super_admin',
  hotelAdmin: 'hotel_admin',
  hotelFrontDesk: 'hotel_front_desk',
  airlineAdmin: 'airline_admin',
  airlineGroundStaff: 'airline_ground_staff'
};

// Toast messages
export const pageTitles = {
  hotel: {
    details: 'Hotel Details',
    editDetails: 'Edit Hotel Details',
    accountSettings: 'Account Settings',
    layoverDetails: 'Layover Details'
  },
  airline: {
    accountSettings: 'Account Settings',
    layoverDetails: 'Layover Details'
  },
  notFound: ''
};

// Toast messages
export const toastMessages = {
  networkError: 'Please check your internet connection',
  HTTPErrorStatus: {
    403: 'Something went wrong, please try after some time.',
    500: 'Server error, please try after some time.',
    400: 'Something went wrong, please try after some time.',
    401: 'Your account has been blocked by an administrator.',
    default: 'Something went wrong, please try after some time.',
  },
  login: {
    failed: 'Invalid username or password',
    invalidOtp: 'Invalid OTP',
    otpExpired: 'OTP Expired. Please re-send OTP.',
    otpSent: 'OTP sent to your email id'
  },
  logout: 'Successfully signed out',
  resetPassword: {
    success: 'Successfully changed password',
    error: 'Request for change password failed',
  },
  register: {
    success: 'Registration successful',
    error: 'Registration failed',
  },
  layoverBookings: {
    open: {
      success: 'Successfully opened the hotel for layover',
      error: 'Failed to open the hotel for layover',
      mandatoryInfoError: 'Failed to open the hotel for layover as mandatory information is missing'
    },
    close: {
      success: 'Successfully closed the hotel for layover',
      error: 'Failed to close the hotel for layover'
    }
  },
  hotel: {
    update: {
      success: 'Successfully updated the hotel details',
      error: 'Failed to update the hotel details, please contact support'
    },
    updateHotelRatesConditions: {
      success: 'Successfully updated the rates & charges'
    },
    updateHotelTimings: {
      success: 'Successfully updated the hotel timings'
    },
    updateHotelFoodBeverages: {
      success: 'Successfully updated the food and beverages'
    },
    updateHotelTransportation: {
      success: 'Successfully updated the transportation details',
      error: 'Failed to update the transportation details, please contact support'
    },
    updateHotelAirlineContracts: {
      success: 'Successfully updated the airline contracts',
      error: 'Failed to update the airline contracts details, please contact support'
    },
    updateHotelAvailability: {
      success: 'Successfully updated the availability',
      error: 'Failed to update the availability, please contact support'
    },
    updateHotelReminder: {
      success: 'Successfully updated the hotel reminder',
      error: 'Failed to update the hotel reminder, please contact support'
    },
    inviteHotelFrontDeskUsers: {
      success: 'Invite sent successfully',
      error: 'Failed to send the invite, please contact support'
    },
    addHotelFrontDeskUsers: {
      success: 'Successfully added the user access',
      error: 'Failed to add the user access, please contact support'
    },
    removeHotelFrontDeskUsers: {
      success: 'Successfully removed the user access',
      error: 'Failed to remove the user access, please contact support'
    },
    dashboardSetHotelClosedForLayover: {
      error: 'We set this hotel closed for layover as mandatory information is missing'
    },
    BillingManagementUploadInvoice: {
      success: 'Successfully uploaded the invoice',
      error: 'Failed to upload the invoice, please contact support',
      deleteSuccess: 'Successfully removed invoice PDF'
    },
    BillingManagementAdditionalFiles: {
      success: 'Successfully added Additional upload files',
      error: 'Failed to Additional upload files, please contact support',
      warning: 'Maximum 3 files are allowed',
      deleteSuccess: 'Successfully removed Additional upload files',
      deleteError: 'Failed to remove additional upload files'
    }
  },
  airline: {
    createLayover: {
      success: 'Layover created successfully',
      error: 'Failed to create the layover, please contact support'
    },
    updateLayover: {
      success: 'Layover updated successfully',
      error: 'Failed to update the layover, please contact support'
    },
    passengerCountLayover: {
      success: 'Passenger Count updated successfully',
      error: 'Failed to update the passenger count, please contact support'
    },
    addHotelToLayover: {
      success: 'Added the hotel to layover',
      error: 'Failed to add the hotel to layover, please contact support',
      limitExceedError: 'Selected passengers exceeded the total no. of passengers'
    },
    storeHotelSelectionToLayover: {
      success: 'Successfully stored the hotels in layover',
      error: 'Failed to store the hotels in layover, please contact support'
    },
    storeBookingDetailsToLayover: {
      success: 'Successfully stored the booking details in layover',
      error: 'Failed to store the booking details in layover, please contact support'
    },
    confirmLayoverBooking: {
      success: 'Successfully confirmed the layover booking',
      error: 'Failed to confirm the layover booking.'
    },
    updateLayoverBooking: {
      success: 'Successfully updated the layover booking',
      error: 'Failed to updated the layover booking.'
    },
    sendEmailToAllPassengers: {
      success: 'Message has been successfully scheduled for delivery',
      error: 'Failed to scheduled a message, please contact support'
    },
    inviteAirlineGroundStaff: {
      success: 'Invite sent successfully',
      error: 'Failed to send the invite, please contact support'
    },
    addAirlineGroundStaff: {
      success: 'Successfully added the user access',
      error: 'Failed to add the user access, please contact support'
    },
    removeAirlineGroundStaff: {
      success: 'Successfully removed the user access',
      error: 'Failed to remove the user access, please contact support'
    },
    qrCodeSettingForAirline: {
      success: 'QR Code Setting successfully updated',
      error: 'Failed to update QR code setting'
    }
  },
  imageDimensionError: 'Please upload an image with a minimum width of 750 pixels and a minimum height of 600 pixels.',
  attachmentsWarning: 'Maximum 5 files are allowed',
  addPassengerWarning: 'No more rooms available for booking',
};

export const weekdays = {
  'MON': {
    name: 'Monday'
  },
  'TUE': {
    name: 'Tuesday'
  },
  'WED': {
    name: 'Wednesday'
  },
  'THU': {
    name: 'Thursday'
  },
  'FRI': {
    name: 'Friday'
  },
  'SAT': {
    name: 'Saturday'
  },
  'SUN': {
    name: 'Sunday'
  }
};

export const languages = {
  'en': {
    name: 'English'
  },
  'fr': {
    name: 'French'
  },
  'de': {
    name: 'German'
  },
  'es': {
    name: 'Spanish'
  },
  'it': {
    name: 'Italian'
  },
  'pt': {
    name: 'Portuguese'
  },
  'ch': {
    name: 'Chinese (Mandarin)'
  },
  'ru': {
    name: 'Russian'
  },
  'jp': {
    name: 'Japanese'
  }
};

export const googleAddressReferenceKey = {
  address1: {
    referenceAllKey: true,
    key: ['premise', 'street_number', 'route'],
  },
  address2: {
    referenceAllKey: true,
    key: ['sublocality', 'sublocality_level_2', 'sublocality_level_1'],
  },
  city: {
    referenceAllKey: false,
    key: ['administrative_area_level_2', 'locality'],
  },
  state: {
    referenceAllKey: true,
    key: ['administrative_area_level_1'],
  },
  country: {
    referenceAllKey: true,
    key: ['country'],
  },
  postalCode: {
    referenceAllKey: true,
    key: ['postal_code'],
  },
};

export const defaultFilters = {
  'isBreakfast': 0,
  'isEarlyBirdBreakfast': 0,
  'isLunch': 0,
  'isDinner': 0,
  'isSingleRoom': 0,
  'isDoubleRoom': 0,
  'isPetsAllowed': 0,
  'isRoomForDisabled': 0,
  'isShuttleService': 0,
  'maxRoomRate': 1000,
  'noShowMin': 0,
  'noShowMax': 6,
  'maxDistanceMin': 50
}
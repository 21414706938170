import { useState, useCallback, useEffect } from 'react'
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Card,
  styled
} from '@mui/material'
import { getHotelsForPassenger, confirmBookingHotel } from '../../services/layover/bookings'
import { useForm, Controller } from 'react-hook-form'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { AlertDialog, Button, CheckBox, Radio } from '../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { useLoader } from '../../providers/loader';
import Cookies from 'js-cookie';
import NotFound from './notFound';
import HotelCard from './hotelCard';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { maxToastTime } from '../../helper/constant';
// import RoomLeft from './roomLeft';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2.5),
  margin: '0 auto',
}))

const Para = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.4),
  marginTop: theme.spacing(1.3),
  alignItems: 'flex-start',
  textAlign: 'left',
  lineHeight: theme.lineHeight[1.37]
}))

const RadioWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const StyledHotelCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[800]}`,
  padding: theme.spacing(1.3),
  marginBottom: theme.spacing(1.3),
  width: '100%',
}))

const StyledFacilityIcoBox = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.common.black}88`,
  borderRadius: theme.borderRadius[3],
  padding: theme.spacing(1),
  height: theme.spacing(4),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(1)
}))

const LinkedTo = styled(Button)(({ theme }) => ({
  textDecoration: 'none',
  color: 'white',
  marginBottom: theme.spacing(2.5),
  display: 'flex',
}))


function HotelSelection() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch
  } = useForm()
  const theme = useTheme()
  const navigate = useNavigate()
  let { iata, code } = useParams()
  const { t } = useTranslation()
  const { startLoader, stopLoader } = useLoader();
  const [hotelDetails, setHotelDetails] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [hotelLoading, setHotelLoading] = useState(false)
  const [hotelRoomSubmitted, setHotelRoomSubmitted] = useState(true)
  const [roomsAvailable, setRoomsAvailable] = useState(true)
  const [modifiedRequest, setModifiedRequest] = useState(null)
  const [showerrorPage, setShowerrorPage] = useState(false)
  const [placementStrategy, setPlacementStrategy] = useState('')
  const selectedHotels = watch('selectedHotels') || [];
  
  const handleOpen = () => {setDialogOpen(true)}

  const fetchHotelsData = () => {
    setModifiedRequest(null);
    startLoader();
    const savedWelcomeDetails = JSON.parse(Cookies.get('welcomeDetails') || '{}');
    const savedPassengerRoomsDetails = JSON.parse(Cookies.get('passengerRoomsDetails') || '{}');
    new Promise(async (resolve, reject) => {
      try {
        const response = await getHotelsForPassengerViaApi(savedWelcomeDetails?.id, savedPassengerRoomsDetails, savedWelcomeDetails.language);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    }).then(response => {
      setHotelDetails(response?.hotels);
      setPlacementStrategy(response?.passenger_placement_strategy === 'PASSENGER_CHOICE');
      setRoomsAvailable(response?.hotels.length === 1 ? true : response?.hotels?.some(hotel => hotel?.isRoomsFulfill));
      if (response?.modifiedRequest) {
        setModifiedRequest(response.modifiedRequest)
      }
    }).catch(e => {
      if (e?.response?.data?.error?.message?.toLowerCase()?.includes('layover expired')) {
        setShowerrorPage(true)
      } else {
        toast.error(t('notFoundToastMess'), {
          autoClose: maxToastTime,
          toastId: 'passenger-hotelSelection-flag',
        });
      }
    }).finally(() => {
      setHotelLoading(true);
      stopLoader();
    });
  }

  const onDialogDisagree = useCallback(() => {
    navigate(`/passenger/${iata}/${code}/booking`)
    setDialogOpen(false)
  }, [navigate, dialogOpen]);

  const onDialogWrongDisagree = useCallback(() => {
    setDialogOpen(false)
    fetchHotelsData()
  }, [dialogOpen]);

  const getHotelsForPassengerViaApi = useCallback(async (layoverId, rooms, preferredLang) => {
    try {
      const response = await getHotelsForPassenger(layoverId, rooms, preferredLang);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  const confirmBookingHotelViaApi = useCallback(async (layoverId, rooms) => {
    try {
      const response = await confirmBookingHotel(layoverId, rooms);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  const handleRadioChange = (hotelId) => {
    const updatedSelectedHotels = selectedHotels.includes(hotelId)
      ? selectedHotels.filter(id => id !== hotelId)
      : [...selectedHotels, hotelId]; 
    setValue('selectedHotels', updatedSelectedHotels);
  };
  
  const onSubmit = useCallback(async (data) => {
    if (data.selectedHotels) {
      startLoader();
      setHotelRoomSubmitted(true)
      const passengerInfoDetails = JSON.parse(Cookies.get('passengerInfo') || '{}');
      let savedPassengerRoomsDetails = JSON.parse(Cookies.get('passengerRoomsDetails') || '{}');
      if (modifiedRequest && modifiedRequest instanceof Array && modifiedRequest.length > 0) {
        savedPassengerRoomsDetails = modifiedRequest
      }
      const savedWelcomeDetails = JSON.parse(Cookies.get('welcomeDetails'));
      const { lead_passenger_first_name, lead_passenger_last_name, lead_passenger_email, is_passenger_disabled, is_family_room, is_pets } = passengerInfoDetails;
      const passengerPostData = await {
        preferred_language_code: savedWelcomeDetails?.language,
        lead_passenger_first_name,
        lead_passenger_last_name,
        lead_passenger_email,
        is_passenger_disabled,
        is_family_room,
        is_pets,
        'hotel_ids': placementStrategy && roomsAvailable ? [data.selectedHotels]: data.selectedHotels,
        'rooms': savedPassengerRoomsDetails
      }
      new Promise(async (resolve, reject) => {
        try {
          const response = await confirmBookingHotelViaApi(savedWelcomeDetails?.id, passengerPostData)
          resolve(response)
        } catch (e) {
          reject(e)
          setHotelRoomSubmitted(false)
        }
      }).then(response => {
        Cookies.set('passengerbookedRoom', JSON.stringify(response), { expires: 2 });
        Cookies.remove('passengerInfo')
        Cookies.remove('passengerRoomsDetails')
        Cookies.remove('welcomeDetails')
      }).catch((e) => {
        if (e?.response?.data?.error?.message?.toLowerCase()?.includes('layover expired')) {
          setShowerrorPage(true)
        } else {
          toast.error(e?.response?.data?.error?.message, {
            autoClose: maxToastTime,
            toastId: 'passenger-hotelSelection-flag',
          });
        }
        setHotelRoomSubmitted(false)
      }).finally(() => {
        stopLoader();
      });
      handleOpen()
      } else {
      setHotelRoomSubmitted(false)
      handleOpen()
    }
  },[hotelDetails, roomsAvailable, confirmBookingHotelViaApi, startLoader, stopLoader]) 
  
  useEffect(() => {
    fetchHotelsData()
  }, [setValue, startLoader, stopLoader, getHotelsForPassengerViaApi]);

  if(hotelLoading && !showerrorPage && (!hotelDetails || hotelDetails?.length === 0)){
    return <NotFound allRoomBooked={true}/>
  }
  if(showerrorPage){
    return <NotFound/>
  }

  return ( 
    <Container>
      <Grid sx={{ml: -4}} container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'>
        <LinkedTo onClick={() => navigate(`/passenger/${iata}/${code}/hotel-rooms`)}>
          <ChevronLeftIcon sx={{color: theme.mode === 'light' ? theme.palette.primary.main : 'inherit',}}/>
          <Typography
            sx={{
              ...theme.typography.dashboardHeadline,
              fontSize: theme.typography.h6,
              textTransform: 'none',
              color: theme.mode === 'light' ? theme.palette.primary.main : 'inherit',
            }}
            variant='caption'
          >
            {t('hotelSelection')}
          </Typography>
        </LinkedTo>
      </Grid>
      <Para>
        {hotelDetails?.length === 1 ? t('hotelSelectionSingleMess') : t('hotelSelectionMultiMess')}
      </Para>
      {!roomsAvailable ? (
        <Para sx={{color: theme.palette.red[100]}}>
          {t('roomDividingMess.firstPart', {noOfHotels: hotelDetails?.length})}
        </Para>
      ):(null)}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          {placementStrategy && roomsAvailable ? 
            <Grid item xs={12}>
              <RadioWrapper>
                <Controller
                  name={'selectedHotels'}
                  control={control}
                  rules={{required: t('hotelSelectErrorMess')}}
                  render={({ field }) => (
                    <Radio
                      formControlLabelProps={{
                        sx:{
                          alignItems: 'flex-start',
                          '.MuiRadio-root': {
                            mt: 2.4
                          }
                        }
                      }}
                      options={
                        hotelDetails?.map(hotel => ({
                          disabled: !hotel?.isRoomsFulfill,
                          value: hotel?.id,
                          label: (
                            <>
                              <Box width={'80vw'}>
                                <Typography
                                  variant='body1'
                                  sx={{ fontWeight: 'bold', marginTop: 2 }}
                                >
                                  {hotel?.name}
                                </Typography>
                                <Box sx={{ 
                                  mt: 1, mb: 2,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}>
                                  <Typography
                                    variant='body2'
                                  >
                                    {hotel?.city?.city_name}, {hotel?.city?.country_id?.name}
                                  </Typography>
                                  {/* <RoomLeft rooms={hotel?.rooms}/> */}
                                </Box>
                              </Box>
                              <StyledHotelCard sx={{ml: -4, width: '110% !important'}}>
                                <HotelCard hotel={hotel}/>
                              </StyledHotelCard>
                            </>
                          )
                        }))
                      }
                      {...field}
                    />
                  )}
                />
              </RadioWrapper>
            </Grid>
          : null}
          {!placementStrategy || !roomsAvailable ? 
            hotelDetails?.map((hotel, index) => (
              <Grid item xs={12} key={`p-h-i-${hotel?.id}`}>
                <RadioWrapper>
                  <Controller
                    name={`selectedHotels[${index}]`}
                    control={control}
                    defaultValue={hotel?.id}
                    render={({ field }) => (
                      <Radio
                        {...field}
                        checked={field.value}
                        onChange={() => handleRadioChange(hotel.id)}
                        options={[
                          {
                            value: hotel?.id,
                            label: (
                              <Box width={'80vw'}>
                                <Typography
                                  variant='body1'
                                  sx={{ fontWeight: 'bold', marginTop: 2 }}
                                >
                                  {hotel?.name}
                                </Typography>
                                <Box sx={{ 
                                  mt: 1, mb: 2,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}>
                                  <Typography
                                    variant='body2'
                                  >
                                    {hotel?.city?.city_name}, {hotel?.city?.country_id?.name}
                                  </Typography>
                                  {/* <RoomLeft rooms={hotel?.rooms}/> */}
                                </Box>
                              </Box>
                            ),
                          },
                        ]}
                      />
                    )}
                  />
                  <StyledHotelCard>
                    <HotelCard hotel={hotel}/>
                  </StyledHotelCard>
                </RadioWrapper>
              </Grid>
            ))
          : null}
          <Grid item alignItems='flex-start'>
            <Controller
              name='acceptTerms'
              control={control}
              defaultValue={false}
              rules={{ required: t('iAgreeTermsErrorMess') }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <CheckBox
                    checked={field.value}
                    formControlLabelProps={{ sx: {alignItems: 'flex-start', '.MuiCheckbox-root': {mt: 0.5}}}}
                    onChange={(e) => field.onChange(e.target.checked)}
                    sx={{ ml: 1}}
                    label={
                      <Typography sx={{ fontSize: theme.typography.body2, pl: 1, pt: 0, lineHeight: theme.lineHeight[1.2] }}>
                        {t('termAcceptedHotelSelection')}
                      </Typography>
                    }
                  />
                  {error ? (
                    <Typography color='error' sx={{ fontSize: theme.typography.body2, pl: 1 }}>
                      {error.message}
                    </Typography>
                  ):(null)}
                </>
              )}
            />
          </Grid>
          {errors.selectedHotels ? (
            <Typography variant='body2' color='error' sx={{ ml: 4}}>
              {errors.selectedHotels.message}
            </Typography>
          ):(null)}
        </Grid>

        {/* alert box */}

        {hotelRoomSubmitted ? (
          <AlertDialog
            title={t('alertDialog.hotelSelectionSuccess.title')}
            dialogTitleProps={{
              sx: {
                ...theme.typography.textxxLarge,
                ...theme.typography.fontSemiBold500,
                ...theme.typography.fontSemiBold500,
                pt: theme.spacing(5),
                color: theme.palette.success.main,
                pb: theme.spacing(2)
              }
            }}
            open={dialogOpen}
            onDisagree={onDialogDisagree}
            noBtnText={t('alertDialog.hotelSelectionSuccess.accept')}
            sx={{
              textAlign: 'center'
            }}
            fullWidth={true}
            maxWidth={'xs'}
            stretchBtn
          >
            <Box>
              <Typography sx={{pb:1}} variant='body1'>
              {t('alertDialog.hotelSelectionSuccess.message')}
              </Typography>
            </Box>
          </AlertDialog>
        ) : (
          <AlertDialog
            title={t('alertDialog.hotelSelectionFailure.title')}
            dialogTitleProps={{
              sx: {
                ...theme.typography.textxxLarge,
                paddingTop: theme.spacing(5),
                color: theme.palette.roseGold[100],
                paddingBottom: theme.spacing(2)
              }
            }}
            open={dialogOpen}
            onDisagree={onDialogWrongDisagree}
            noBtnText={t('alertDialog.hotelSelectionFailure.accept')}
            sx={{
              textAlign: 'center'
            }}
            fullWidth={true}
            maxWidth={'xs'}
            stretchBtn
          >
            <Box>
              <Typography sx={{pb:1}} variant='body1'>
                  {t('alertDialog.hotelSelectionFailure.message')}
              </Typography>
            </Box>
          </AlertDialog>
        )}
        <Button
          variant='contained'
          width='100%'
          type='submit'
          sx={{
            mt: 2,
            '&.MuiButton-contained': {
              ...theme.typography.muiTypography400
            }
          }}
          >
          {t('submit')}
        </Button>
      </form>
    </Container>
  )
}

export default HotelSelection

import React, { useEffect, useMemo, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MainRouter from './router';
import { StagingBanner } from './components';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getTheme from './styles/theme'
import ToastPopup from './components/toast';
import { maxToasts } from './helper/constant';
import { Provider } from 'react-redux';
import { LoaderProvider } from './providers/loader';
import { GoogleMapProvider } from './providers/googleMap';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { injectStore } from './services';
import { I18nextProvider } from 'react-i18next';
import i18n from './pages/passenger/i18n.js';

import './App.css';
import './styles/global.css';
import { storage } from './utils';
import { ThemeContext } from './context/themeContext';

injectStore(store);

function App() {
  // eslint-disable-next-line
  const [themeMode, setThemeMode] = useState('dark');
  const theme = useMemo(() => createTheme(getTheme(themeMode)), [themeMode]);

  useEffect(() => {
    const initialTheme = storage.getData('currentTheme') === 'light'  ? 'light' : 'dark';
    setThemeMode(initialTheme)
  },[storage])

  return (
    <>
      <HelmetProvider>
        {(process.env.REACT_APP_ENVIRONMENT === 'LOCAL' || process.env.REACT_APP_ENVIRONMENT === 'STAGING') ? (
          <>
            <Helmet>
              <meta name='robots' content='noindex' />
            </Helmet>
            {process.env.REACT_APP_SHOW_ENVIRONMENT_BANNER === 'true' && (
              <StagingBanner />
            )}
          </>
        ) : (null)}
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <ThemeContext.Provider value={{setThemeMode}}> 
                <LoaderProvider>
                  <I18nextProvider i18n={i18n}>
                    <GoogleMapProvider>
                      <CssBaseline />
                      <MainRouter />
                    </GoogleMapProvider>
                  </I18nextProvider>
                </LoaderProvider>
              </ThemeContext.Provider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
        <ToastPopup limit={maxToasts} />
      </HelmetProvider>
    </>
  );
}

export default App;

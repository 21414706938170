import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Typography, styled, useTheme } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { weekdays } from '../../../../../helper/constant';
import { Radio, TooltipClick } from '../../../../../components';

const StyledMainLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[800],
  textTransform: 'uppercase'
}))

const HotelSelectionHotelTransportation = (props) => {
  const { hotelId, updateLayover, hotel_transportation, transportation_schedules, is_shuttle_service, is_by_foot, transportation_type_cost_efficient, transportation_type_individual } = props;
  const { control } = useFormContext();
  const theme = useTheme();
  const [optionsToShow, setOptionsToShow] = useState([]);

  let transportationOptions = useMemo(() => ({
    BY_FOOT: {
      value: 'BY_FOOT',
      label: 'By Foot',
      note: 'Hotel is at the airport',
      disabled: false,
      tooltip: (
        <Box sx={{py:1}}>
          <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
            The hotel is directly at the airport and no separate transportation is required.
          </Typography>
          <br/>
          <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
            Croowy Companion will send the passengers a Google Maps link for navigation to the hotel.
          </Typography>
        </Box>
      )
    },
    SHUTTLE: {
      value: 'SHUTTLE',
      label: 'Hotel Shuttle',
      note: 'Available for this hotel',
      disabled: false,
      tooltip: (
        <Box sx={{py:1}}>
          <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
            The hotel provides a shuttle.
          </Typography>
          <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
            Croowy Companion will send the passengers a Google Maps link for navigation to the shuttle.
          </Typography>
        </Box>
      )
    },
    COST_EFFICIENT: {
      value: 'COST_EFFICIENT',
      label: 'Cost efficient',
      note: 'As few vehicles as possible',
      disabled: false,
      tooltip: (
        <Box sx={{py:1}}>
          <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
            We will use a mix of differently sized buses and taxis (depending on their availability) to transport your passengers cost efficiently.
          </Typography>
          <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
            In case taxis or Uber is used, we will assign one taxi to multiple people.
          </Typography>
          <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
            You will receive a Companion Message and Email from us with the details.
          </Typography>
          <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
            The passengers will also receive an email from us with the details.
          </Typography>
        </Box>
      )
    },
    INDIVIDUAL: {
      value: 'INDIVIDUAL',
      label: 'Individual',
      note: '1 Taxi/Uber per passenger group',
      disabled: false,
      tooltip: (
        <Box sx={{py:1}}>
          <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
            We will use taxis/Uber for each group of passengers. A group of passengers are people who registered as a group when scanning the layover QR code.
          </Typography>
          <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
            This provides a better experience to passengers, but comes at a higher cost than the “cost efficient” transportation option.
          </Typography>
          <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
            The passengers will receive an email from us with the details.
          </Typography>
        </Box>
      )
    },
    SELF_ORGANIZED: {
      value: 'SELF_ORGANIZED',
      label: 'Self organized',
      note: 'Organized by you or passenger',
      disabled: false,
      tooltip: (
        <Box sx={{py:1}}>
          <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
            Croowy will not organize transportation for this hotel. You or the passenger self-organizes transportation. In case you organize the transportation you are also responsible for communicating it to your passengers.
          </Typography>
        </Box>
      )
    }
  }), [theme.lineHeight]);

  // /**
  //  * Get transportation options
  //  * 
  //  * NOTE: As mentioned in the requirement - https://croowy.atlassian.net/jira/software/projects/LAYOV/boards/4/backlog?selectedIssue=LAYOV-38
  //  * Transportation:
  //  *   If the hotel does not offer a shuttle, then the shuttle option should be disabled
  //  */
  // const getTransportationOptions = useCallback(async () => {
  //   let options = _.cloneDeep(transportationOptions);
  //   if (is_shuttle_service) {
  //     options.SHUTTLE.note = 'Not available for this hotel';
  //     options.SHUTTLE.disabled = true;
  //   }
  //   // If by foot is enabled, disable all other options otherwise choose cost efficient as default option
  //   if (is_by_foot) {
  //     options.SHUTTLE.disabled = true;
  //     options.COST_EFFICIENT.disabled = true;
  //     options.INDIVIDUAL.disabled = true;
  //     options.SELF_ORGANIZED.disabled = true;
  //   } else if (is_shuttle_service) {
  //     options.BY_FOOT.disabled = true;
  //     if (transportation_schedules && transportation_schedules.length > 0) {
  //       let tempSchedules = {};
  //       for (const schedule of transportation_schedules) {
  //         if (schedule?.airport_terminal?.id && schedule?.airport_terminal?.Name && schedule?.weekday && schedule.weekday.length > 0 && schedule?.time && schedule.time.length > 0) {
  //           if (!tempSchedules.hasOwnProperty(schedule.airport_terminal.id)) {
  //             tempSchedules[schedule.airport_terminal.id] = {
  //               airport_terminal_name: schedule.airport_terminal.Name,
  //               schedules: []
  //             };
  //           }
  //           tempSchedules[schedule.airport_terminal.id].schedules.push({
  //             days: schedule.weekday.map(function(day) { return weekdays[day].name }),
  //             time: schedule.time.map(function(time) { return moment(time, 'HH:mm').format('hh:mm a') })
  //           })
  //         }
  //       }
  //       options.SHUTTLE.tooltip = (
  //         <Box sx={{py:1}}>
  //           <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
  //             The hotel provides a shuttle. Below you can find the schedule for today:
  //           </Typography>
  //           {Object.keys(tempSchedules).map((scheduleIndex, i) => {
  //             let terminal = tempSchedules[scheduleIndex]
  //             return (
  //               <>
  //                 <br/>
  //                 <Typography variant='subtitle2' sx={{lineHeight: theme.lineHeight[1.2], ...theme.typography.fontMedium600}}>
  //                   Terminal - {terminal.airport_terminal_name}
  //                 </Typography>
  //                 {terminal.schedules.map((schedule, i) => (
  //                   <Box sx={{mt:1}}>
  //                     <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
  //                       <b>Day</b>: {schedule.days.join(', ')}
  //                     </Typography>
  //                     <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
  //                       <b>Time</b>: {schedule.time.join(', ')}
  //                     </Typography>
  //                   </Box>
  //                 ))}
  //               </>
  //             );
  //           })}
  //           <br/>
  //           <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
  //             Croowy Companion will send the passengers a Google Maps link for navigation to the shuttle.
  //           </Typography>
  //         </Box>
  //       );
  //     }
  //   } else {
  //     options.BY_FOOT.disabled = true;
  //     options.SHUTTLE.disabled = true;
  //   }
  //   let optionsToShow = [];
  //   for (const optKey in options) {
  //     const opt = options[optKey];
  //     let note = opt.note;
  //     optionsToShow.push({
  //       value: opt.value,
  //       disabled: opt.disabled,
  //       label: (
  //         <Box component='span'>
  //           <Typography component='span'>{opt.label}</Typography>
  //           {(!opt.disabled && opt?.tooltip) ? (
  //             <TooltipClick
  //               title={opt.tooltip}
  //             />
  //           ) : (null)}
  //           <br/>
  //           <Typography component='span' sx={{
  //             color: theme.palette.table.header,
  //             ...theme.typography.textxSmall,
  //             lineHeight: theme.lineHeight[1.5]
  //           }}>
  //             {note}
  //           </Typography>
  //         </Box>
  //       )
  //     })
  //   }
  //   return optionsToShow;
  // }, [theme, transportation_schedules, is_by_foot, is_shuttle_service, transportationOptions]);

  /**
   * Is transportation pre-selected?
   * 
   * NOTE: As mentioned in the requirement - https://croowy.atlassian.net/jira/software/projects/LAYOV/boards/4/backlog?selectedIssue=LAYOV-38
   * Transportation:
   *  1. By foot: If the hotel is directly at the airport, we only show “by foot” as an option to the airline. The hotel can select this on their hotel profile. Otherwise “by foot” is hidden or disabled
   *  2. Shuttle: If there is a shuttle, we show this as the default option. We need to give the airline the option to choose other transportation options because the shuttle might not operate 24/7
   *  3. Cost efficient transportation: If there is no shuttle and the hotel is not at the airport, this is the default option
   *  4. Individual transportation: This option will probably not be used very often. Maybe for small layovers or for business class layovers
   *  5. Self-organized
   */
  const getPreSelectedTransportation = useCallback(() => {
    if (is_by_foot) {
      return transportationOptions.BY_FOOT.value;
    } else if (is_shuttle_service) {
      return transportationOptions.SHUTTLE.value;
    } else if (transportation_type_cost_efficient){
      return transportationOptions.COST_EFFICIENT.value;
    } else if (transportation_type_individual){
      return transportationOptions.INDIVIDUAL.value;
    } else {
      return transportationOptions.SELF_ORGANIZED.value;
    }
  }, [transportationOptions, is_by_foot, is_shuttle_service]);

  useEffect(() => {
    /**
     * NOTE: As mentioned in the requirement - https://croowy.atlassian.net/jira/software/projects/LAYOV/boards/4/backlog?selectedIssue=LAYOV-38
     * Transportation:
     *   If the hotel does not offer a shuttle, then the shuttle option should be disabled
     */
    let options = _.cloneDeep(transportationOptions);
    if (!is_shuttle_service) {
      options.SHUTTLE.note = 'Not available for this hotel';
      options.SHUTTLE.disabled = true;
    }
    options.COST_EFFICIENT.disabled = !transportation_type_cost_efficient;
    options.INDIVIDUAL.disabled = !transportation_type_individual;
    
    // If by foot is enabled, disable all other options otherwise choose cost efficient as default option
    if (is_by_foot) {
      options.SHUTTLE.disabled = true;
      options.COST_EFFICIENT.disabled = true;
      options.INDIVIDUAL.disabled = true;
      options.SELF_ORGANIZED.disabled = true;
    } else if (is_shuttle_service) {
      options.BY_FOOT.disabled = true;
      if (transportation_schedules && transportation_schedules.length > 0) {
        let tempSchedules = {};
        for (const schedule of transportation_schedules) {
          if (schedule?.airport_terminal?.id && schedule?.airport_terminal?.Name && schedule?.weekday && schedule.weekday.length > 0 && schedule?.time && schedule.time.length > 0) {
            if (!tempSchedules.hasOwnProperty(schedule.airport_terminal.id)) {
              tempSchedules[schedule.airport_terminal.id] = {
                airport_terminal_name: schedule.airport_terminal.Name,
                schedules: []
              };
            }
            tempSchedules[schedule.airport_terminal.id].schedules.push({
              days: schedule.weekday.map(function(day) { return weekdays[day].name }),
              time: schedule.time.map(function(time) { return moment(time, 'HH:mm').format('hh:mm a') })
            })
          }
        }
        options.SHUTTLE.tooltip = (
          <Box sx={{py:1}}>
            <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
              The hotel provides a shuttle. Below you can find the schedule for today:
            </Typography>
            {Object.keys(tempSchedules).map((scheduleIndex, i) => {
              let terminal = tempSchedules[scheduleIndex]
              return (
                <>
                  <br/>
                  <Typography variant='subtitle2' sx={{lineHeight: theme.lineHeight[1.2], ...theme.typography.fontMedium600}}>
                    Terminal - {terminal.airport_terminal_name}
                  </Typography>
                  {terminal.schedules.map((schedule, i) => (
                    <Box sx={{mt:1}}>
                      <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
                        <b>Day</b>: {schedule.days.join(', ')}
                      </Typography>
                      <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
                        <b>Time</b>: {schedule.time.join(', ')}
                      </Typography>
                    </Box>
                  ))}
                </>
              );
            })}
            <br/>
            <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2]}}>
              Croowy Companion will send the passengers a Google Maps link for navigation to the shuttle.
            </Typography>
          </Box>
        );
      }
    } else {
      options.BY_FOOT.disabled = true;
      options.SHUTTLE.disabled = true;
    }
    if(updateLayover){
      options.BY_FOOT.disabled = true;
      options.SHUTTLE.disabled = true;
      options.COST_EFFICIENT.disabled = true;
      options.INDIVIDUAL.disabled = true;
      options.SELF_ORGANIZED.disabled = true;
    }
    let radioOptions = [];
    for (const optKey in options) {
      const opt = options[optKey];
      let note = opt.note;
      radioOptions.push({
        value: opt.value,
        disabled: opt.disabled,
        label: (
          <Box component='span'>
            <Typography component='span'>{opt.label}</Typography>
            {(!opt.disabled && opt?.tooltip) ? (
              <TooltipClick
                title={opt.tooltip}
              />
            ) : (null)}
            <br/>
            <Typography component='span' sx={{
              color: theme.palette.table.header,
              ...theme.typography.textxSmall,
              lineHeight: theme.lineHeight[1.5]
            }}>
              {note}
            </Typography>
          </Box>
        )
      })
    }
    setOptionsToShow(radioOptions);
  }, [is_by_foot, is_shuttle_service, theme, transportationOptions, transportation_schedules])

  return (
    <>
      <Box sx={{display:'flex', alignItems:'center', pt:2, pb:1}}>
        <StyledMainLabel>Select Transportation Mode</StyledMainLabel>
      </Box>
      <Box>
        <Controller
          name={`formHotelSelection[hotels][${hotelId}][hotel_transportation]`}
          control={control}
          defaultValue={hotel_transportation ? hotel_transportation : getPreSelectedTransportation()}
          render={({ field }) => (
            <Radio
              options={optionsToShow}
              inputRef={field.ref}
              {...field}
              sx={{
                '& .MuiFormControlLabel-root': {
                  alignItems: 'start',
                  '&.Mui-disabled': {
                    filter: 'blur(.09rem)'
                  },
                  '&> .MuiRadio-root': {
                    paddingTop: 0,
                    paddingBottom: 0
                  },
                  '&> .MuiTypography-root': {
                    paddingTop: theme.spacing(.3)
                  }
                }
              }}
            />
          )}
        />
      </Box>
    </>
  );
};

export default memo(HotelSelectionHotelTransportation);
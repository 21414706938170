import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { HeadingInput } from '../components/headingInput';
import Input from '../../../components/input';
import { InputEndAdornment } from '../components/endAdornment';
import { name as getCurrencyName } from 'better-currency-codes';
import { Radio, AlertDialog } from '../../../components';
import { createHotelRoomFixedRates, getHotelRoomFixedRates, updateHotelRoomFixedRates } from '../../../services/hotel/hotelRoomFixedRates';
import { createHotelRatesConditions, getHotelRatesConditions, updateHotelRatesConditions } from '../../../services/hotel/hotelRatesConditions';
import { getHotelRoomTypes } from '../../../services/hotel/hotelRoomTypes';
import { maxToastTime, toastMessages } from '../../../helper/constant';
import { toast } from 'react-toastify';
import { useLoader } from '../../../providers/loader';
import RequiredFieldsInfoText from '../../../components/requiredFieldsInfoText';
import { HotelContext } from '../../../context/hotel';

const StyledRoomName = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[600],
  textTransform: 'uppercase'
}));

const RatesCharges = forwardRef((props, ref) => {
  const { hotel, permission, dirtyDialogOpen } = props;
  const { setIsFormDirty } = useContext(HotelContext);
  const formRef = useRef(null);
  const { startLoader, stopLoader } = useLoader();
  const [currency, setCurrency] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false)
  const [roomTypes, setRoomTypes] = useState([]);
  const [ratesConditions, setRatesConditions] = useState({});
  const [roomFixedRates, setRoomFixedRates] = useState([]);
  const [resetFormData, setResetFormData] = useState({});
  const [isPaidByPassanger, setIsPaidByPassanger] = useState(0);
  const [arePetsAllowed, setArePetsAllowed] = useState(0);
  const [isDayStayAllowed, setIsDayStayAllowed] = useState(0);

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setFocus,
    reset
  } = useForm();
  
  const watchedFields = useWatch({
    control,
    name: roomTypes.map(roomType => `formRoomFixedRates[${roomType?.id}][total_rate_without_meal]`),
  });

  const handleDialogOpen = () => {
    setDialogOpen(false)
    handleSubmit(onSubmit)()
  }

  // checking is form having any unsaved changes or not
  const hasUnsavedChanges = Object.keys(dirtyFields).length > 0;
  useEffect(() => {
    setIsFormDirty(hasUnsavedChanges);
  }, [hasUnsavedChanges, setIsFormDirty]);

  const hotelRoomFixedRatesRequest = useCallback(() => ({
    'populate[hotel_room_type][fields][0]': 'id',
    'populate[hotel_room_type][fields][1]': 'name',
    'populate[hotel_room_type][fields][2]': 'active'
  }), []);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
  
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const onIsDayStayAllowedChange = (value) => {
    setIsDayStayAllowed(parseInt(value));
  };

  const onIsPaidByPassangerChange = (value) => {
    setIsPaidByPassanger(parseInt(value));
  };

  const onArePetsAllowedChange = (value) => {
    setArePetsAllowed(parseInt(value));
  };
  
  /**
   * Get hotel room types from API
   * 
   * @param {*} hotelId   Hotel id 
   */
  const getHotelRoomTypesFromApi = useCallback(async (hotelId) => {
    const request = {
      'pagination[page]': '1',
      'pagination[pageSize]': '10000',
    };
    const response = await getHotelRoomTypes(hotelId, request);
    if (response?.data && response.data.length) {
      const sortedRoomTypes = response.data.sort(
        (firstObj, secondObj)=> firstObj.max_adults - secondObj.max_adults
      )
      setRoomTypes(sortedRoomTypes);
    } else {
      setRoomTypes([]);
    }
  }, []);

  /**
   * Get rates & charges from API
   * 
   * @param {*} hotelId   Hotel id 
   */
  const getRatesChargesFromApi = useCallback(async (hotelId) => {
    // Get hotel rates conditions via API
    const hotelRatesConditionsDetails = await getHotelRatesConditions(hotelId);
    if (hotelRatesConditionsDetails?.data && hotelRatesConditionsDetails.data.length) {
      setRatesConditions(hotelRatesConditionsDetails.data[0]);
    } else {
      setRatesConditions({});
    }

    // Get hotel room fixed rates via API
    const hotelRoomFixedRatesDetails = await getHotelRoomFixedRates(hotelId, {params: hotelRoomFixedRatesRequest()});
    if (hotelRoomFixedRatesDetails?.data && hotelRoomFixedRatesDetails.data.length) {
      setRoomFixedRates(hotelRoomFixedRatesDetails.data);
    } else {
      setRoomFixedRates([]);
    }
  }, [hotelRoomFixedRatesRequest]);

  /**
   * Update rates & charges from API
   * 
   * @param {*} hotelId   Hotel id
   * @param {*} request   Request
   */
  const updateRatesConditionsViaApi = useCallback(async (hotelId, request) => {
    let response = null;
    // If id exists, call update API
    try {
      if (request?.id) {
        response = await updateHotelRatesConditions(request?.id, request);
      } else {
        response = await createHotelRatesConditions(hotelId, request);
      }
      if (response?.data) {
        setRatesConditions({...ratesConditions, ...response.data});
      }
    } catch (e) {
      throw e
    }
  }, [ratesConditions]);

  /**
   * Update room fixed rates from API
   * 
   * @param {*} hotelId   Hotel id
   * @param {*} request   Request
   */
  const updateRoomFixedRatesViaApi = useCallback(async (hotelId, roomId, request) => {
    let response = null;
    // If id exists, call update API
    try {
      if (request?.id) {
        response = await updateHotelRoomFixedRates(request?.id, request, hotelRoomFixedRatesRequest());
      } else {
        response = await createHotelRoomFixedRates(hotelId, roomId, request, hotelRoomFixedRatesRequest());
      }
      if (response?.data) {
        setRoomFixedRates(roomFixedRates => {
          const tempRoomFixedRates = [];
          if (roomFixedRates.length) {
            for (const rfRate of roomFixedRates) {
              if (rfRate?.id && rfRate.id === response.data?.id) {
                tempRoomFixedRates.push({...rfRate, ...response.data});
              } else {
                tempRoomFixedRates.push(rfRate, response.data);
              }
            }
          } else {
            tempRoomFixedRates.push(response.data);
          }
          return tempRoomFixedRates;
        });
      }
    } catch (e) {
      throw e
    }
  }, [hotelRoomFixedRatesRequest]);

  // Call API's on form submit
  const onSubmit = useCallback(async (data) => {
    if (hotel && hotel.id && data) {
      setIsFormDirty(false); // if user clicks on Save changes then Confirmation box will not shown

      if (data.formRatesConditions.pets_charges === '' || data.formRatesConditions.pets_charges === null || data.formRatesConditions.pets_charges === undefined) {
        data.formRatesConditions.pets_charges = 0;
      }

      if (data.formRatesConditions.max_self_paid_passangers === '' || data.formRatesConditions.max_self_paid_passangers === null || data.formRatesConditions.max_self_paid_passangers === undefined) {
        data.formRatesConditions.max_self_paid_passangers = 0;
      }

      let promises = [];
      // Update rates & conditions
      if (data.formRatesConditions) {
        promises.push(updateRatesConditionsViaApi(hotel.id, data.formRatesConditions));
      }

      // Update room fixed rates
      if (data.formRoomFixedRates) {
        for (const roomId in data.formRoomFixedRates) {
          if (roomId) {
            let rfRateRequest = data.formRoomFixedRates[roomId];
            promises.push(updateRoomFixedRatesViaApi(hotel.id, roomId, rfRateRequest));
          }
        }
      }
      
      if (promises.length) {
        startLoader();
        await Promise.all(promises).then(() => {
          toast.success(toastMessages.hotel.updateHotelRatesConditions.success, {
            autoClose: maxToastTime,
            toastId: 'update-hotel-rates-conditions',
          })
        }).catch((value) => {
        }).finally(() => {
          stopLoader();
        });
      }
    }
  }, [updateRatesConditionsViaApi, updateRoomFixedRatesViaApi, startLoader, stopLoader, setIsFormDirty]);
  
  // Call function to submit the form on click of form submit button
  useImperativeHandle(ref, () => ({
    saveChanges: () => {
      if (dirtyDialogOpen && hasUnsavedChanges) {
        handleSubmit(onSubmit)();
      } else {
        const formHasChanged = watchedFields.some((field, index) => {
          const originalValue = resetFormData?.formRoomFixedRates?.[roomTypes[index]?.id]?.total_rate_without_meal || 0;
          return field !== originalValue;
        });
        if (formHasChanged) {
          setDialogOpen(true);
        } else {
          // handleDialogOpen();
          setDialogOpen(false);
          handleSubmit(onSubmit)();
        }
      }
    }
  }), [handleSubmit, onSubmit, watchedFields, resetFormData?.formRoomFixedRates, hasUnsavedChanges, dirtyDialogOpen]);

  useEffect(() => {
    if (hotel && hotel.id) {
      startLoader();
      Promise.allSettled([
        getHotelRoomTypesFromApi(hotel.id),
        getRatesChargesFromApi(hotel.id)
      ]).then(() => {
        stopLoader();
      });
    }
  }, [hotel, getHotelRoomTypesFromApi, getRatesChargesFromApi, startLoader, stopLoader]);

  useEffect(() => {
    if (roomTypes && roomFixedRates) {
      let roomWiseFixedRates = {};
      for (const fixedRate of roomFixedRates) {
        roomWiseFixedRates[fixedRate.hotel_room_type?.id] = fixedRate;
      }
      if (Object.keys(roomWiseFixedRates).length > 0) {
        for (let roomType of roomTypes) {
          roomType['fixedRates'] = null;
          if (roomWiseFixedRates[roomType?.id]) {
            roomType.fixedRates = roomWiseFixedRates[roomType?.id];
          }
        }
      }
    }
  }, [roomTypes, roomFixedRates]);

  // Prepare values to reset the form when API calls completed
  useEffect(() => {
    let tempResetFormData = {};
    if (ratesConditions && Object.keys(ratesConditions).length > 0) {
      tempResetFormData['formRatesConditions'] = ratesConditions;
      // Convert radio values into binary value
      tempResetFormData['formRatesConditions']['is_room_for_disabled'] = ratesConditions?.is_room_for_disabled ? 1 : 0;
      tempResetFormData['formRatesConditions']['is_pets'] = ratesConditions?.is_pets ? 1 : 0;
      tempResetFormData['formRatesConditions']['is_paid_by_passanger'] = ratesConditions?.is_paid_by_passanger ? 1 : 0;
      tempResetFormData['formRatesConditions']['is_shuttle_service_inclusive'] = ratesConditions?.is_shuttle_service_inclusive ? 1 : 0;
      tempResetFormData['formRatesConditions']['is_day_stay_allowed'] = ratesConditions?.is_day_stay_allowed ? 1 : 0;

      // prefilling radio btn values in states to manage ternary conditions in FORM
      setArePetsAllowed(ratesConditions?.is_pets ? 1 : 0);
      setIsPaidByPassanger(ratesConditions?.is_paid_by_passanger ? 1 : 0);
      setIsDayStayAllowed(ratesConditions?.is_day_stay_allowed ? 1 : 0);

    }
    if (roomFixedRates && roomFixedRates.length > 0) {
      let rfRateFormData = {};
      for (let fRate of roomFixedRates) {
        rfRateFormData[fRate.hotel_room_type?.id] = {};
        rfRateFormData[fRate.hotel_room_type?.id]['net_rate_without_meal'] = fRate.net_rate_without_meal;
        rfRateFormData[fRate.hotel_room_type?.id]['total_rate_without_meal'] = fRate.total_rate_without_meal;
        rfRateFormData[fRate.hotel_room_type?.id]['no_max_passangers'] = fRate.no_max_passangers;
      }
      tempResetFormData['formRoomFixedRates'] = rfRateFormData;
    }
    setResetFormData(tempResetFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratesConditions, roomFixedRates]);

  // Provide values to reset the form when API calls completed
  useEffect(() => {
    if (Object.keys(resetFormData).length > 0) {
      reset({});
      reset(resetFormData);
    } else {
      reset({});
      reset({});
    }
  }, [reset, resetFormData]);

  useEffect(() => {
    let hotelCurrency = '';
    if (hotel?.currency?.alphabetic_code) {
      const currencyName = getCurrencyName({ code: hotel.currency.alphabetic_code, numeric: false });
      hotelCurrency = currencyName?.unit?.plural ? currencyName.unit.plural : hotel.currency.alphabetic_code;
    }
    setCurrency(hotelCurrency);
  }, [hotel?.currency?.alphabetic_code]);

  return (
    <Box sx={{pt: 3}}>
      <Grid item xs={12} sx={{py: 1, display: 'flex', justifyContent: 'end'}}>
        <RequiredFieldsInfoText title='* fields are mandatory' />
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Controller
          name={'hotelId'}
          control={control}
          defaultValue={hotel.id}
          render={({ field }) => (
            <Input
              type='hidden'
              formControlProps={{sx: {m:0}}}
              sx={{border:0}}
              {...field}
            />
          )}
        />
        {/* Insert rates & conditions 'id' attribute as hidden field */}
        {(ratesConditions && ratesConditions?.id) ? (
          <Controller
            name={`formRatesConditions[id]`}
            control={control}
            defaultValue={ratesConditions.id}
            render={({ field }) => (
              <Input
                type='hidden'
                formControlProps={{sx: {m:0}}}
                sx={{border:0}}
                {...field}
              />
            )}
          />
        ) : ('')}
        {/* Insert room fixed rates 'id' attributes as hidden field */}
        {(roomTypes && roomTypes.length) ? (
          roomTypes?.map((roomType, index) => (
            roomType.fixedRates ? (
              <Controller
                key={`rfrhf-${index}`}
                name={`formRoomFixedRates[${roomType?.id}][id]`}
                control={control}
                defaultValue={roomType.fixedRates?.id}
                render={({ field }) => (
                  <Input
                    type='hidden'
                    formControlProps={{sx: {m:0}}}
                    sx={{border:0}}
                    {...field}
                  />
                )}
              />
            ) : ('')
          ))
        ) : ('')}
        <Grid container>
          <Grid item xs={12} sx={{mb: 3}}>
            <Grid container>
              <Grid item xs={12} sx={{py: 1}}>
                <Grid container>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    <HeadingInput title='Room rates without meals and without taxes * (If breakfast is always included as per brand standard, please quote the rate including breakfast.)' />
                  </Grid>
                </Grid>
              </Grid>
              {roomTypes ? (
                roomTypes?.map((roomType, index) => (
                  <Grid item xs={12} sx={{py: 1}} key={`lrwm-${index}`}>
                    <Grid container>
                      <Grid item xs={2} sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center'
                      }}>
                        <StyledRoomName>{roomType.name}</StyledRoomName>
                      </Grid>
                      <Grid item xs={10}>
                        <Controller
                          name={`formRoomFixedRates[${roomType?.id}][net_rate_without_meal]`}
                          control={control}
                          defaultValue={0}
                          render={({ field }) => (
                            <Input
                              formControlProps={{
                                sx: {
                                  marginBottom: 0
                                }
                              }}
                              isError={!!errors?.formRoomFixedRates?.[roomType?.id]?.net_rate_without_meal}
                              errorMessage={errors?.formRoomFixedRates?.[roomType?.id]?.net_rate_without_meal && 'Please enter valid rate'}
                              inputRef={field.ref}
                              {...field}
                              value={field.value.toString().replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                              onChange={(e) => {
                                const value = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                field.onChange(value);
                              }}
                              disabled={!permission?.edit}
                              endAdornment={
                                <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                  In {currency}
                                </InputEndAdornment>
                              }
                            />
                          )}
                          rules={{
                            required: true,
                            pattern: /^([1-9]\d*)(\.\d+)?$/
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              ) : ('')}
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb: 3}}>
            <Grid container>
              <Grid item xs={12} sx={{py: 1}}>
                <Grid container>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    <HeadingInput title='Room Rate with all taxes and VAT but without meals * (If breakfast is always included as per brand standard, please quote the rate including breakfast.)' />
                  </Grid>
                </Grid>
              </Grid>
              {roomTypes ? (
                roomTypes?.map((roomType, index) => (
                  <Grid item xs={12} sx={{py: 1}} key={`rrwatavwm-${index}`}>
                    <Grid container>
                      <Grid item xs={2} sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center'
                      }}>
                        <StyledRoomName>{roomType.name}</StyledRoomName>
                      </Grid>
                      <Grid item xs={10}>
                        <Controller
                          name={`formRoomFixedRates[${roomType?.id}][total_rate_without_meal]`}
                          control={control}
                          defaultValue={0}
                          render={({ field }) => (
                            <Input
                              formControlProps={{
                                sx: {
                                  marginBottom: 0
                                }
                              }}
                              isError={!!errors?.formRoomFixedRates?.[roomType?.id]?.total_rate_without_meal}
                              errorMessage={errors?.formRoomFixedRates?.[roomType?.id]?.total_rate_without_meal && 'Please enter valid rate'}
                              inputRef={field.ref}
                              {...field}
                              value={field.value.toString().replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                              onChange={(e) => {
                                const value = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                field.onChange(value);
                              }}
                              disabled={!permission?.edit}
                              endAdornment={
                                <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                  In {currency}
                                </InputEndAdornment>
                              }
                            />
                          )}
                          rules={{
                            required: true,
                            pattern: /^([1-9]\d*)(\.\d+)?$/
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              ) : ('')}
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb: 3}}>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='% of rooms not charged in case of no show *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formRatesConditions[no_show_no_rooms_not_charged_percent]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formRatesConditions?.no_show_no_rooms_not_charged_percent}
                            errorMessage={errors?.formRatesConditions?.no_show_no_rooms_not_charged_percent && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            endAdornment={
                              <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                In Percentage
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='% of room rate (as booked, including or excluding breakfast) charged for additional no shows *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formRatesConditions[no_show_penalty_percent]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formRatesConditions?.no_show_penalty_percent}
                            errorMessage={errors?.formRatesConditions?.no_show_penalty_percent && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            endAdornment={
                              <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                In Percentage
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb: 3}}>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='% of meal prices charged for any unused meals *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formRatesConditions[unused_meal_percent]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formRatesConditions?.unused_meal_percent}
                            errorMessage={errors?.formRatesConditions?.unused_meal_percent && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            endAdornment={
                              <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                In Percentage
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Does the rate include the shuttle service? *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formRatesConditions[is_shuttle_service_inclusive]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Radio
                            options={[
                              {
                                value: 1,
                                label: 'Yes',
                                disabled: !permission?.edit
                              },
                              {
                                value: 0,
                                label: 'No',
                                disabled: !permission?.edit
                              }
                            ]}
                            isError={!!errors?.formRatesConditions?.is_shuttle_service_inclusive}
                            errorMessage={errors?.formRatesConditions?.is_shuttle_service_inclusive && 'Please select a value'}
                            inputRef={field.ref}
                            {...field}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb: 3}}>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Are there rooms for disabled persons? *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formRatesConditions[is_room_for_disabled]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Radio
                            options={[
                              {
                                value: 1,
                                label: 'Yes',
                                disabled: !permission?.edit
                              },
                              {
                                value: 0,
                                label: 'No',
                                disabled: !permission?.edit
                              }
                            ]}
                            isError={!!errors?.formRatesConditions?.is_room_for_disabled}
                            errorMessage={errors?.formRatesConditions?.is_room_for_disabled && 'Please select a value'}
                            inputRef={field.ref}
                            {...field}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Are pets allowed? If so, what is the price per pet? *' />
                    </Grid>
                     <Grid item xs={12} sx={{ py: 1 }}>
                      <Controller
                        name={`formRatesConditions[is_pets]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Radio
                            options={[
                              {
                                value: 1,
                                label: 'Yes',
                                disabled: !permission?.edit
                              },
                              {
                                value: 0,
                                label: 'No',
                                disabled: !permission?.edit
                              }
                            ]}
                            isError={!!errors?.formRatesConditions?.is_pets}
                            errorMessage={errors?.formRatesConditions?.is_pets && 'Please select a value'}
                            inputRef={field.ref}
                            {...field}
                            onChange={(e) => {
                              onArePetsAllowedChange(e.target.value);
                              field.onChange(e);
                            }}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={10}>
                  <Grid item xs={6}>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formRatesConditions[pets_charges]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={arePetsAllowed === 1 && !!errors?.formRatesConditions?.pets_charges}
                            errorMessage={arePetsAllowed === 1 && errors?.formRatesConditions?.pets_charges && 'Please enter valid charge'}
                            inputRef={field.ref}
                            {...field}
                            disabled={arePetsAllowed === 0 || !permission?.edit} 
                            endAdornment={
                              <InputEndAdornment position='end' sx={{ textTransform: 'capitalize' }}>
                                In {currency}
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: arePetsAllowed === 0 || !permission?.edit ? false : true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb: 3}}>
            <Grid container>
              <Grid item xs={12} sx={{py: 1}}>
                <Grid container>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    <HeadingInput title='No. of guests allowed per room based on room type *' />
                  </Grid>
                </Grid>
              </Grid>
              {roomTypes ? (
                roomTypes?.map((roomType, index) => (
                  <Grid item xs={12} sx={{py: 1}} key={`nogaprbort-${index}`}>
                    <Grid container>
                      <Grid item xs={2} sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center'
                      }}>
                        <StyledRoomName>{roomType.name}</StyledRoomName>
                      </Grid>
                      <Grid item xs={10}>
                        <Controller
                          name={`formRoomFixedRates[${roomType?.id}][no_max_passangers]`}
                          control={control}
                          defaultValue={0}
                          render={({ field }) => (
                            <Input
                              formControlProps={{
                                sx: {
                                  marginBottom: 0
                                }
                              }}
                              isError={!!errors?.formRoomFixedRates?.[roomType?.id]?.no_max_passangers}
                              errorMessage={errors?.formRoomFixedRates?.[roomType?.id]?.no_max_passangers && 'Please enter valid number'}
                              inputRef={field.ref}
                              {...field}
                              disabled={!permission?.edit}
                            />
                          )}
                          rules={{
                            required: true,
                            pattern: /^([1-9]\d*)(\.\d+)?$/
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              ) : ('')}
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mb: 3 }}>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid item xs={12} sx={{ py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                      <HeadingInput title='Can passengers pay by themselves? *' />
                    </Grid>
                    <Grid item xs={12} sx={{ py: 1 }}>
                      <Controller
                        name={`formRatesConditions[is_paid_by_passanger]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Radio
                            options={[
                              {
                                value: 1,
                                label: 'Yes',
                                disabled: !permission?.edit
                              },
                              {
                                value: 0,
                                label: 'No',
                                disabled: !permission?.edit
                              }
                            ]}
                            isError={!!errors?.formRatesConditions?.is_paid_by_passanger}
                            errorMessage={errors?.formRatesConditions?.is_paid_by_passanger && 'Please select a value'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            onChange={(e) => {
                              onIsPaidByPassangerChange(e.target.value);
                              field.onChange(e);
                            }}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid item xs={12} sx={{ py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                      <HeadingInput title='Is there a maximum of self-paying passengers per layover event? *' />
                    </Grid>
                    <Grid item xs={12} sx={{ py: 1 }}>
                      <Controller
                        name={`formRatesConditions[max_self_paid_passangers]`}
                        control={control}
                        disabled={isPaidByPassanger === 0 || !permission?.edit}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={isPaidByPassanger === 1 && !!errors?.formRatesConditions?.max_self_paid_passangers}
                            errorMessage={isPaidByPassanger === 1 && errors?.formRatesConditions?.max_self_paid_passangers && 'Please enter valid number'}
                            inputRef={field.ref}
                            {...field}
                          />
                        )}
                        rules={{
                          required: isPaidByPassanger === 0 || !permission?.edit ? false : true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mb: 3 }}>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid item xs={12} sx={{ py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                      <HeadingInput title='Max. no. of nights the airline can book per guest for one layover *' />
                    </Grid>
                    <Grid item xs={12} sx={{ py: 1 }}>
                      <Controller
                        name={`formRatesConditions[max_nights_per_guests]`}
                        control={control}
                        defaultValue={1}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formRatesConditions?.max_nights_per_guests}
                            errorMessage={errors?.formRatesConditions?.max_nights_per_guests && 'Please enter a valid number greater than 0'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                          />
                        )}
                        rules={{
                          required: true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                          validate: value => parseFloat(value) > 0 || 'Value must be greater than 0'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mb: 3 }}>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid item xs={12} sx={{ py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                      <HeadingInput title='Are day stays allowed?' />
                    </Grid>
                    <Grid item xs={12} sx={{ py: 1 }}>
                      <Controller
                        name={`formRatesConditions[is_day_stay_allowed]`}
                        control={control}
                        defaultValue={isDayStayAllowed}
                        render={({ field }) => (
                          <Radio
                            options={[
                              { value: 1, label: 'Yes', disabled: !permission?.edit },
                              { value: 0, label: 'No', disabled: !permission?.edit }
                            ]}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            onChange={(e) => {
                              onIsDayStayAllowedChange(e.target.value);
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  
                  {Number(isDayStayAllowed) === 1 ? (<Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid item xs={12} sx={{ py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                      <HeadingInput title='What is the discount on the nightly rate for day stays? *' />
                    </Grid>
                    <Grid item xs={12} sx={{ py: 1 }}>
                      <Controller
                        name={`formRatesConditions[day_stay_discount_percent]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formRatesConditions?.day_stay_discount_percent}
                            errorMessage={errors?.formRatesConditions?.day_stay_discount_percent && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            endAdornment={
                              <InputEndAdornment position='end' sx={{ textTransform: 'capitalize' }}>
                                In Percentage
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /^([0-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>): null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>

      {/* alert dialog if user made changes in Room Rate with all taxes and VAT but without meals */}
      <AlertDialog
        title='Not Overwrite the Custom Rates'
        open={dialogOpen}
        onAgree={handleDialogOpen}
        onDisagree={() => setDialogOpen(false)}
        yesBtnText='Yes, Save changes'
        noBtnText='No, Go back'
        sx={{
          textAlign: 'center'
        }}
        fullWidth={true}
        maxWidth={'xs'}
        stretchBtn
      >
        <Typography sx={{lineHeight: '1.37rem', py: 2}} variant='subtitle2'>
          Attention! Changing the rates here will not overwrite any custom rates for specific dates you have entered on the Availability Management page.
        </Typography>
      </AlertDialog>
    </Box>
  );
});

export default RatesCharges;
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import BgImgPage from '../../assets/images/bg-login.png';
import Logo from '../../assets/images/logo.png';
import { Button, Input } from '../../components';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { maxToastTime, toastMessages } from '../../helper/constant';
import { toast } from 'react-toastify';
import { useLoader } from '../../providers/loader';
import { getUrlParams } from '../../utils/index';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../services/auth/logout';
import { isMobile } from 'react-device-detect';
import { resetPassword } from '../../services/user';

const PageContainer = styled(Box)(({ theme }) => ({
  '& .overlap': {
    borderRadius: '10px',
    position: 'relative',
    width: '100vw',
    height: '100vh',
  },
  '& .bg-image': {
    backgroundImage: `url(${BgImgPage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    userSelect: 'none',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
  },
  '& .backdrop': {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: theme.palette.primary.dark,
    opacity: '.85',
    backdropFilter: 'blur(5px) brightness(100%)',
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  width:isMobile? '90%' : '30%',
  '& .wrapper-fieldset': {
    borderRadius: '10px',
    borderColor: 'rgba(174, 207, 227, 0.3)',
    marginTop: '20px',
    '& legend': {
      padding: '0 20px',
      fontSize: theme.typography.dashboardHeadline.fontSize,
      fontWeight: theme.typography.fontSemiBold600.fontWeight,
      margin: '0 auto',
    },
  },
  '& .MuiTypography-root.login-text': {
    ...theme.typography.textMedium,
    marginBottom: theme.spacing(2),
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
}));

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { value } = getUrlParams('code'); 
  const { startLoader, stopLoader } = useLoader();
  const user = useSelector((state) => state.user);

  const { control, handleSubmit, formState: { errors }, watch } = useForm({
    defaultValue: { email: '' },
  });

  const watchPassword = watch('password', '');
  const watchConfirmPassword = watch('passwordConfirmation', '');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const logoutUser = () => {
    const redirectUrl = `/user/login`;
    logout(dispatch, navigate, redirectUrl);
  };

  const onSubmit = useCallback(async (data) => {
    // API call
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        const formData = { ...data, code: value };
        const response = await resetPassword(formData);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    }).then((response) => {
      toast.success(toastMessages.resetPassword.success, {
        autoClose: maxToastTime,
        toastId: 'resetPassword-success',
      });
      logoutUser();
    }).catch((e) => {
    }).finally(() => {
      stopLoader();
    });
  }, [startLoader, stopLoader, navigate, value, logoutUser]);

  return (
    <Box>
      {user !== null && (
        <PageContainer>
          <Box className='overlap'>
            <Box className='bg-image' style={{ backgroundImage: `url(${BgImgPage})` }}></Box>
            <Box className='backdrop'></Box>
            <Wrapper>
              <Box sx={{color:theme.palette.common.white}}>
                <img src={Logo} width={55} alt='Croowy Companion' />
                <Box component='fieldset' className='wrapper-fieldset'>
                  <legend>Reset Password</legend>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <StyledBox>
                      <Controller
                        name='password'
                        control={control}
                        render={({ field }) => (
                          <Input
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Password'
                            label='New Password*'
                            isError={!!errors.password}
                            errorMessage={
                              errors.password
                                ? (field.value === '' ? 'Please enter a password' : 'Password must be at least 8 characters long and contain at least one letter, one number, and one special character')
                                : ''
                            }
                            inputRef={field.ref}
                            {...field}
                            formControlProps={{
                              sx: {
                                '& .MuiFormLabel-root': {
                                  color: theme.mode === 'light' ? theme.palette.menu.main : 'inherit',
                                  '&.Mui-focused': {
                                      color: theme.mode === 'light' ? theme.palette.menu.main : 'inherit'
                                  }
                                }
                              }
                            }}
                            endAdornment={
                              <InputAdornment position='start'>
                                <IconButton onClick={handleClickShowPassword}>
                                  {showPassword ? (
                                    <VisibilityIcon sx={{ color: theme.palette.icon.light }} />
                                  ) : (
                                    <VisibilityOffIcon sx={{ color: theme.palette.icon.light }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: true,
                          minLength: 8,
                          pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                        }}
                      />
                      <Controller
                        name='passwordConfirmation'
                        control={control}
                        render={({ field }) => (
                          <Input
                            type={showConfirmPassword ? 'text' : 'password'}
                            placeholder='Confirm Password'
                            label='Confirm New Password*'
                            isError={!!errors.confirmPassword}
                            errorMessage={errors.confirmPassword && 'Passwords do not match'}
                            inputRef={field.ref}
                            {...field}
                            formControlProps={{
                              sx: {
                                '& .MuiFormLabel-root': {
                                  color: theme.mode === 'light' ? theme.palette.menu.main : 'inherit',
                                  '&.Mui-focused': {
                                      color: theme.mode === 'light' ? theme.palette.menu.main : 'inherit'
                                  }
                                }
                              }
                            }}
                            endAdornment={
                              <InputAdornment position='start'>
                                <IconButton onClick={handleClickShowConfirmPassword}>
                                  {showConfirmPassword ? (
                                    <VisibilityIcon sx={{ color: theme.palette.icon.light }} />
                                  ) : (
                                    <VisibilityOffIcon sx={{ color: theme.palette.icon.light }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: true,
                          validate: (value) => value === watchPassword,
                        }}
                      />
                      <Button
                        type='submit'
                        variant='contained'
                        width='100%'
                        disabled={!watchPassword || !watchConfirmPassword}
                        sx={{
                          mb:3,
                          background: theme.mode === 'light' ? theme.palette.roseGold[100] : 'default',
                          ':disabled': {
                            background: theme.mode === 'light' ? theme.palette.roseGold[100] : 'default',
                            color:theme.palette.common.white       
                          },
                          '&.MuiButton-contained': {
                            backgroundColor: theme.mode === 'light' ? theme.palette.roseGold[100]: 'default'
                          }
                        }}
                      >
                        Change Password
                      </Button>
                    </StyledBox>
                  </form>
                  <Typography variant='body1' align='center'>
                    <u>Contact Us </u>
                  </Typography>
                </Box>
              </Box>
            </Wrapper>
          </Box>
        </PageContainer>
      )}
    </Box>
  );
};

export default ResetPassword;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Typography,
  IconButton,
  InputAdornment,
  useTheme,
  Grid
} from '@mui/material';
import Input from '../../../../components/input';
import { LargeHeading } from '../../components/heading';
import formatTimestamp from '../../../../utils/formatTimestamp';

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontSemiBold500,
  textTransform: 'uppercase',
  color: theme.palette.table.header,
  borderBottom: 'unset',
  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
}));

const Para = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMedium,
  color: theme.palette.table.header,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1)
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.textMedium,
  color: theme.palette.text.primary,
  borderBottomColor: theme.palette.divider,
  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
}));

const HotelTable = ({ layovers, noLayoverFoundMessage }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const { control } = useForm();
  const rowsPerPage = 10;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, layovers?.length - page * rowsPerPage);

  // setting layovers data to state, taken from props
  useEffect(() => {
    setFilteredData(layovers ? layovers : []);
  }, [layovers])

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  // handle search
  const handleSearchChange = (e) => {
    const filtered = layovers?.filter((layover) => layover.airlineName.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilteredData(filtered);
  };

  return (
    <Box>
      <Grid container spacing={2} justifyContent='flex-start'>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name='searchInput'
            control={control}
            render={({ field }) => (
              <Input
                placeholder='Search'
                formControlProps={{
                  sx: { marginBottom: 3, marginTop: 3 },
                }}
                sx={{
                  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
                }}
                inputRef={field.ref}
                autoFocus
                {...field}
                onChange={handleSearchChange}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton type='submit'>
                      <SearchIcon sx={{ color: theme.palette.primary.light }} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
        </Grid>
      </Grid>
      
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell sx={{ width: '12%' }}>BOOKING DATE & TIME</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '18%', textAlign:'center' }}>
                LAYOVER DETAILS 
                {/* <Box sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                  LAYOVER DETAILS <InfoIcon sx={{color: theme.palette.icon.light, ml: 2}} />
                </Box>   */}
              </StyledTableHeaderCell>    
              <StyledTableHeaderCell sx={{ width: '10%', textAlign:'center' }}>REGISTERED PAX</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '20%', textAlign:'center' }}>NO. OF BOOKED ROOMS</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>ESTIMATED ARRIVAL AT HOTEL</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ textAlign:'center' }}>LATEST RETURN TO AIRPORT</StyledTableHeaderCell>
              {/* <StyledTableHeaderCell sx={{ textAlign:'center' }}>CHAT</StyledTableHeaderCell> */}
            </TableRow>
          </TableHead>

          {layovers && layovers?.length > 0 && (
            <TableBody>
              {filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor:'pointer' }} onClick={() => navigate(`${row?.layoverId}/details`)}>
                    <StyledTableCell sx={{ width: '12%' }}>
                      {formatTimestamp(row?.bookingDate)}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '18%', textAlign:'center' }}>
                      {row?.airlineName} 
                      <br /> <Para>{row?.toAirportCode_iata}</Para>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '10%', textAlign:'center' }}>{row?.registeredPax ? row?.registeredPax : '-'}</StyledTableCell>
                    <StyledTableCell sx={{ width: '20%', textAlign:'center' }}>
                      {row?.registeredPax ? row?.registeredPax : 0} {row?.registeredPax > 1 ? 'Guests' : 'Guest'} in {row?.bookedRooms ? row?.bookedRooms : 0} {row?.registeredPax > 1 ? 'Rooms' : 'Room'}
                      <br /> 
                      <Para>
                        {(typeof row.meals !== 'object' || (row.meals.isBreakfast === false && row.meals.isLunch === false && row.meals.isDinner === false && row.meals.isEarlyBreakfast === false && row.meals.isSnacks === false))
                          ? '-'
                          :  [
                            row.meals.isEarlyBreakfast && 'Early bird breakfast',
                            row.meals.isBreakfast && 'Breakfast',
                            row.meals.isLunch && 'Lunch',
                            row.meals.isDinner && 'Dinner',
                            row.meals.isSnacks && 'Snacks'
                          ].filter(Boolean).join(', ')
                        }
                      </Para>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '15%', textAlign:'center' }}>{row?.estimatedArrivalAtHotel === 'Invalid date' ? '-' : moment(row?.estimatedArrivalAtHotel).format('DD.MM.YY, hh:mm a')}</StyledTableCell>
                    <StyledTableCell sx={{ textAlign:'center' }}>
                      {formatTimestamp(
                        null, // Exclude row?.bookingDate
                        row?.isDifferentReturnTimes,
                        row?.passangerAirportReturnDate,
                        row?.passangerAirportReturnTime,
                        row?.latestReturnToAirport
                      )}
                    </StyledTableCell>
                    {/* <StyledTableCell sx={{ textAlign:'center' }}><IconButton><ChatIcon sx={{color:theme.palette.primary.light}} /></IconButton></StyledTableCell> */}
                  </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>

        {layovers && layovers?.length === 0 && (
          <Box sx={{ width: '100%', py: 28, display: 'flex', justifyContent: 'center' }}>
            <LargeHeading title={noLayoverFoundMessage} />
          </Box>
        )}
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <Pagination
          count={Math.ceil(layovers?.length / rowsPerPage)}
          page={page + 1}
          onChange={handleChangePage}
          variant='outlined'
          shape='rounded'
          hidePrevButton
          hideNextButton
          sx={{
            '& .MuiPaginationItem-root': {
              backgroundColor: theme.palette.grey[800],
              color: theme.palette.background.default,
              border: 'none',
              ':hover':{
                backgroundColor: theme.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[400],
                color: theme.palette.grey[800],
              }
            },
            '& .MuiPaginationItem-root.Mui-selected': {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.background.default,
              border: 'none',
              ':hover':{
                backgroundColor: theme.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[400],
                color: theme.palette.grey[800],
              }
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default HotelTable;

import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, IconButton, Typography, styled, useTheme } from '@mui/material';
import { symbol as getCurrencySymbol } from 'better-currency-codes';
import { SubHeading } from '../../components/heading';
import { Button as CustomButton, CheckBox, AlertDialog } from '../../../../components';
import { useLoader } from '../../../../providers/loader';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../../../../helper/constant';
import { useNavigate } from 'react-router-dom';
import { confirmLayoverBooking, updateLayoverBooking } from '../../../../services/layover/bookings';
import { getLayoverPreview } from '../../../../services/layover';
import { prepareImageUrlByPath } from '../../../../utils';
import { Document, Page, Image, StyleSheet, pdf, Text, Link as PdfLink } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import formatPrice from '../../../../utils/formatPrice';

const transportationMap = {
  'SHUTTLE': "Transportation via Hotel Shuttle.",
  'SELF_ORGANIZED': "You selected to organize the transport for this hotel by yourself.",
  'COST_EFFICIENT': "We will arrange transportation for you and contact you with the details and cost estimate shortly.",
  'INDIVIDUAL': "We will arrange transportation for you and contact you with the details and cost estimate shortly.",
  'BY_FOOT': "The hotel is at the airport and in walking distance. There is no transportation required."
};

// Styles required for QR pdf
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#ffffff',
  },
  image: {
    width: 300,
    height: 300,
  },
  airlineName: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 20
  },
  addPassengerLink: {
    fontSize: 20
  }
});

const StyledBigText = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxLarge,
  color: theme.palette.alert.dark
}));

const StyledItemLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[850]
}));

const StyledLayoverTotalPrice = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMedium,
  ...theme.typography.fontMedium600,
  color: theme.palette.primary.light20
}));

const StyledDocGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:last-child': {
    borderBottom: 'unset'
  }
}));

const StyledSimpleButton = styled(Button)(({ theme }) => ({
  ...theme.typography.textMedium,
  color: theme.palette.primary.light20,
  textDecoration: 'underline',
  textTransform: 'capitalize'
}));

const ReviewAndConfirm = (props) => {
  const { layoverId, updateLayover } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const { startLoader, stopLoader } = useLoader();
  const [layoverSummary, setLayoverSummary] = useState(null);
  const [layoverBookingDetails, setLayoverBookingDetails] = useState(null);
  const [isAgreementAccepted, setIsAgreementAccepted] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogNotAvailableCapacity, setDialogNotAvailableCapacity] = useState(false);
  
  const onDialogDisagree = useCallback(() => {
    navigate(`/layovers/${layoverId}/details`);
  }, [layoverId, navigate]);

  const onCopyLayoverLink = (link) => {
    navigator.clipboard.writeText(link);
  };

  // function go to checkout page and delete all checkout hotels
  const handleNotAvailableCapacityGoBackBtn = () => {
    navigate(`/layover/${layoverId}/hotels`, { state: { checkoutDeleteAll: true } });
    setDialogNotAvailableCapacity(false)
  }

  const printQrCode = async (filename, img, airlineName, addPassengerLink) => {
    const image = await img
    const blob = await pdf(<QrDocument image={image} airlineName={airlineName} addPassengerLink={addPassengerLink} />).toBlob()
    saveAs(blob, `${filename}.pdf`)
  };

  /**
   * Get layover currency symbol
   * 
   * @param {*} currency   Currency Object
   */
  const getLayoverCurrencySymbol = (currency) => {
    let tempCurrencySymbol = '';
    if (currency?.alphabetic_code) {
      const currencySymbol = getCurrencySymbol({ code: currency.alphabetic_code, numeric: false });
      tempCurrencySymbol = currencySymbol?.native ? currencySymbol.native : currency.alphabetic_code;
    }
    return tempCurrencySymbol;
  };

  /**
   * Get the layover preview via API
   * 
   * @param {*} layoverId  Layover Id
   */
  const getLayoverPreviewViaApi = useCallback(async (layoverId) => {
    try {
      const response = await getLayoverPreview(layoverId);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Confirm the layover booking via API
   * 
   * @param {*} layoverId  Layover Id
   */
  const confirmLayoverBookingViaApi = useCallback(async (layoverId) => {
    try {
      const response = await confirmLayoverBooking(layoverId);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * update the layover booking via API
   * 
   * @param {*} layoverId  Layover Id
   */
  const updateLayoverBookingViaApi = useCallback(async (layoverId) => {
    try {
      const response = await updateLayoverBooking(layoverId);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

    // Call API to update the layover booking
  const onUpdateLayoverBooking = useCallback(() => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        const response = await updateLayoverBookingViaApi(layoverId)
        resolve(response)
      } catch(e) {
        reject(e)
      }
    }).then(response => {
      toast.success(toastMessages.airline.updateLayoverBooking.success, {
        autoClose: maxToastTime,
        toastId: 'update-layover-booking-success',
      })
      navigate(`/layovers/${layoverId}/details`)
    }).catch((e) => {
      if (e?.response?.status && e.response.status === 424) {
        setDialogNotAvailableCapacity(true)
      }else{
        toast.error(toastMessages.airline.updateLayoverBooking.error, {
          autoClose: maxToastTime,
          toastId: 'update-layover-booking-error',
        })
      }
    }).finally(() => {
      stopLoader();
    });
  }, [startLoader, stopLoader, layoverId, updateLayoverBookingViaApi]);

  // Call API to confirm the layover booking
  const onConfirmLayoverBooking = useCallback(() => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        const response = await confirmLayoverBookingViaApi(layoverId)
        resolve(response)
      } catch(e) {
        reject(e)
      }
    }).then(response => {
      toast.success(toastMessages.airline.confirmLayoverBooking.success, {
        autoClose: maxToastTime,
        toastId: 'confirm-layover-booking-success',
      })
      setLayoverBookingDetails(response);
      setDialogOpen(true)
    }).catch((e) => {
      if (e?.response?.status && e.response.status === 424) {
        setDialogNotAvailableCapacity(true)
      }else{
        toast.error(toastMessages.airline.confirmLayoverBooking.error, {
          autoClose: maxToastTime,
          toastId: 'confirm-layover-booking-error',
        })
      }
    }).finally(() => {
      stopLoader();
    });
  }, [startLoader, stopLoader, layoverId, confirmLayoverBookingViaApi]);

  useEffect(() => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        const response = await getLayoverPreviewViaApi(layoverId)
        if (response) {
          setLayoverSummary(response)
        }
        resolve()
      } catch (e) {
        reject(e)
      }
    }).then(response => {
    }).catch((e) => {
      if (e?.response?.status && e.response.status === 424) {
        setDialogNotAvailableCapacity(true)
      }
    }).finally(() => {
      stopLoader();
    });
  }, [startLoader, stopLoader, layoverId, getLayoverPreviewViaApi]);

  return (
    <Box sx={{pt:4, backgroundColor:theme.mode === 'light' ? theme.palette.bg[400] : 'default', px:2}}>
      {layoverSummary ? (
        <Box>
          <Grid container columnSpacing={8}>
            <Grid item lg={6} md={12} >
              <Box sx={{mb:2}}>
                <StyledBigText>
                  {layoverSummary?.airport_id?.iata} {layoverSummary?.airport_id?.name}
                </StyledBigText>
              </Box>

              <Box sx={{mb:2}}>
                <Typography>
                  {layoverSummary?.airline_id?.name} {layoverSummary?.flight_code}{/* <Typography component='span' sx={{pl:1, color: theme.palette.table.header}}>{layoverSummary.flight.from_airport_code} to {layoverSummary.flight.to_airport_code}</Typography> */}
                </Typography>
              </Box>

              {/*<Grid container>
                 <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={7} sx={{py:1}}>
                      <StyledItemLabel>Departure Time</StyledItemLabel>
                    </Grid>
                    <Grid item xs={5} sx={{py:1}}>
                      { layoverSummary.passanger_airport_departure_date ?
                        (`${moment(layoverSummary.passanger_airport_departure_date).format('DD.MM.YY')}, ${moment(layoverSummary.passanger_airport_departure_time, 'HH:mm').format('hh:mm a')}` +
                        `${layoverSummary?.passanger_airport_return_date ? ` - ${moment(layoverSummary.passanger_airport_return_date).format('DD.MM.YY')}` : ''}`)
                        : ('-')
                      }
                    </Grid>
                  </Grid>
                </Grid> 
              </Grid>*/}

              {(layoverSummary?.hotels && layoverSummary.hotels.length > 0) ? (
                (layoverSummary.hotels).map((hotel, index) => (
                  <Grid container key={`ls-h-${index}`} sx={{mt:3}}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={7} sx={{py:1}}>
                          <StyledBigText>{hotel.hotel.name}</StyledBigText>
                        </Grid>
                        <Grid item xs={5} sx={{py:1, textAlign:'center'}}>
                          <Grid container>
                            <Grid item xs={4}>
                              <StyledItemLabel sx={{textTransform:'uppercase', lineHeight:theme.lineHeight[1]}}>Count Rooms</StyledItemLabel>
                            </Grid>
                            <Grid item xs={4}>
                              <StyledItemLabel sx={{textTransform:'uppercase'}}>Count Pax</StyledItemLabel>
                            </Grid>
                            <Grid item xs={4}>
                              <StyledItemLabel sx={{textTransform:'uppercase'}}>Cost</StyledItemLabel>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {(hotel?.rooms && hotel.rooms.length > 0) ? (
                      (hotel.rooms).map((room, roomIndex) => (
                        <Grid item key={`ls-h-${index}-r-${roomIndex}`} xs={12}>
                          <Grid container>
                            <Grid item xs={7} sx={{py:1}}>
                              <StyledItemLabel>{room?.roomTypeName} ({getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(room.price)})</StyledItemLabel>
                            </Grid>
                            <Grid item xs={5} sx={{py:1, textAlign:'center'}}>
                              <Grid container>
                                <Grid item xs={4}>
                                  {room.totalRooms}
                                  {updateLayover && room.additional_rooms ? 
                                    <Box sx={{color: theme.palette.success.main, mt: .5}}>
                                      (+{room.additional_rooms})
                                    </Box> 
                                  : null}
                                </Grid>
                                <Grid item xs={4}>
                                  {room.totalRooms * room.occupancy}
                                  {updateLayover && room.additional_rooms ? 
                                    <Box sx={{color: theme.palette.success.main, mt: .5}}>
                                      (+{room.additional_rooms * room.occupancy})
                                    </Box>
                                  : null}
                                </Grid>
                                <Grid item xs={4}>
                                  {getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(room.totalPrice)}
                                  {updateLayover && room.additional_rooms ? 
                                    <Box sx={{color: theme.palette.success.main, mt: .5}}>
                                      (+{getLayoverCurrencySymbol(layoverSummary.currency) + room.additional_rooms * room.price})
                                    </Box>
                                  : null}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    ) : (null)}
                    {(hotel?.is_breakfast && hotel?.breakfast) ? (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={7} sx={{py:1}}>
                            <StyledItemLabel>Breakfast ({getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.breakfast_price)} p.p)</StyledItemLabel>
                          </Grid>
                          <Grid item xs={5} sx={{py:1, textAlign:'center'}}>
                            <Grid container>
                              <Grid item xs={4}>-</Grid>
                              <Grid item xs={4}>
                                {hotel.breakfast}
                              </Grid>
                              <Grid item xs={4}>
                                {getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.total_breakfast_price)}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (null)}
                    {(hotel?.is_early_bird_breakfast && hotel?.early_bird_breakfast) ? (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={7} sx={{py:1}}>
                            <StyledItemLabel>Early Bird Breakfast ({getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.early_bird_breakfast_price)} p.p)</StyledItemLabel>
                          </Grid>
                          <Grid item xs={5} sx={{py:1, textAlign:'center'}}>
                            <Grid container>
                              <Grid item xs={4}>-</Grid>
                              <Grid item xs={4}>
                                {hotel.early_bird_breakfast}
                              </Grid>
                              <Grid item xs={4}>
                                {getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.total_early_bird_breakfast_price)}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (null)}
                    {(hotel?.is_lunch && hotel?.lunch) ? (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={7} sx={{py:1}}>
                            <StyledItemLabel>Lunch ({getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.lunch_price)} p.p)</StyledItemLabel>
                          </Grid>
                          <Grid item xs={5} sx={{py:1, textAlign:'center'}}>
                            <Grid container>
                              <Grid item xs={4}>-</Grid>
                              <Grid item xs={4}>
                                {hotel.lunch}
                              </Grid>
                              <Grid item xs={4}>
                                {getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.total_lunch_price)}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      (hotel?.is_croowy_lunch && hotel?.croowy_lunch) ? (
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={7} sx={{py:1}}>
                              <StyledItemLabel>Lunch (Organized by Croowy) ({getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.croowy_lunch_price)} p.p)</StyledItemLabel>
                            </Grid>
                            <Grid item xs={5} sx={{py:1, textAlign:'center'}}>
                              <Grid container>
                                <Grid item xs={4}>-</Grid>
                                <Grid item xs={4}>
                                  {hotel.croowy_lunch}
                                </Grid>
                                <Grid item xs={4}>
                                  {getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.total_croowy_lunch_price)}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (null)
                    )}
                    {(hotel?.is_dinner && hotel?.dinner) ? (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={7} sx={{py:1}}>
                            <StyledItemLabel>Dinner ({getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.dinner_price)} p.p)</StyledItemLabel>
                          </Grid>
                          <Grid item xs={5} sx={{py:1, textAlign:'center'}}>
                            <Grid container>
                              <Grid item xs={4}>-</Grid>
                              <Grid item xs={4}>
                                {hotel.dinner}
                              </Grid>
                              <Grid item xs={4}>
                                {getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.total_dinner_price)}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      (hotel?.is_croowy_dinner && hotel?.croowy_dinner) ? (
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={7} sx={{py:1}}>
                              <StyledItemLabel>Dinner (Organized by Croowy) ({getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.croowy_dinner_price)} p.p)</StyledItemLabel>
                            </Grid>
                            <Grid item xs={5} sx={{py:1, textAlign:'center'}}>
                              <Grid container>
                                <Grid item xs={4}>-</Grid>
                                <Grid item xs={4}>
                                  {hotel.croowy_dinner}
                                </Grid>
                                <Grid item xs={4}>
                                  {getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.total_croowy_dinner_price)}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (null)
                    )}
                    {(hotel?.is_snacks && hotel?.snacks) ? (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={7} sx={{py:1}}>
                            <StyledItemLabel>Snacks ({getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.snacks_price)} p.p)</StyledItemLabel>
                          </Grid>
                          <Grid item xs={5} sx={{py:1, textAlign:'center'}}>
                            <Grid container>
                              <Grid item xs={4}>-</Grid>
                              <Grid item xs={4}>
                                {hotel.snacks}
                              </Grid>
                              <Grid item xs={4}>
                                {getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.total_snacks_price)}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (null)}
                    <Grid item xs={12}>
                      <Divider variant='fullWidth' sx={{my:1}} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={7} sx={{py:1}}>
                          <StyledItemLabel sx={{textTransform:'uppercase', color:theme.palette.alert.dark}}>Total</StyledItemLabel>
                        </Grid>
                        <Grid item xs={5} sx={{py:1, textAlign:'center'}}>
                          <Grid container>
                            <Grid item xs={4}>{hotel.totalRooms}</Grid>
                            <Grid item xs={4}>
                              {hotel.rooms.reduce((sum, room) => {
                                return sum + (room.occupancy * room.totalRooms);
                              }, 0)}
                            </Grid>
                            <Grid item xs={4}>
                              {getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(hotel.totalPrice)}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              ) : (null)}

              <Grid container>
                <Grid item xs={12}>
                  <Divider variant='fullWidth' sx={{mt:5,mb:1}} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={7} sx={{py:1}}>
                      <StyledItemLabel sx={{textTransform:'uppercase', color:theme.palette.alert.dark}}>Total</StyledItemLabel>
                    </Grid>
                    <Grid item xs={5} sx={{py:1, textAlign:'center'}}>
                      <Grid container>
                        <Grid item xs={4}>{layoverSummary?.total_rooms}</Grid>
                        <Grid item xs={4}>
                          {layoverSummary?.hotels?.reduce((sum, hotel) => {
                            return sum + hotel?.rooms.reduce((roomSum, room) => {
                              return roomSum + (room.occupancy * room.totalRooms);
                            }, 0);
                          }, 0)}
                        </Grid>
                        <Grid item xs={4}>
                          <StyledLayoverTotalPrice component='p' sx={{mb:.75}}>
                            {getLayoverCurrencySymbol(layoverSummary.currency)}{formatPrice(layoverSummary.total_price)}
                          </StyledLayoverTotalPrice>
                          <StyledItemLabel>Incl. of tax</StyledItemLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={6} md={12}>
              <Grid container>
                <Grid item xs={12}>
                  <SubHeading title='Assumption of Cost Documents' />
                </Grid>

                <Grid item xs={12} sx={{mb:2}}>
                  {(layoverSummary?.hotels && layoverSummary.hotels.length > 0) ? (
                    (layoverSummary.hotels).map((hotel, index) => (
                      <StyledDocGrid container key={`ls-h-${index}`}>
                        <Grid item xs={12} sx={{py:1}}>
                          <StyledBigText>{hotel.hotel.name}</StyledBigText>
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{display:'flex'}}>
                            <Box sx={{py:1, flexGrow:1}}>
                              cost_assumption_document
                            </Box>
                            {/* <StyledSimpleButton onClick={() => window.open(`/layover/${layoverId}/hotel/${hotel?.hotel?.id}/cost`, '_blank', 'rel=noopener noreferrer')}>
                              Open
                            </StyledSimpleButton> */}
                            <StyledSimpleButton onClick={() => window.open(`/layover/${layoverId}/hotel/${hotel?.hotel?.id}/cost`, '_blank', 'rel=noopener noreferrer')}>
                              Download
                            </StyledSimpleButton>
                          </Box>
                        </Grid>
                      </StyledDocGrid>
                    ))
                  ) : (null)}
                  <StyledDocGrid container>
                    <Grid item xs={12} sx={{py:1}}>
                      <StyledBigText>Transportation</StyledBigText>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{display:'flex'}}>
                        <Box sx={{py:1, flexGrow:1}}>
                          {layoverSummary?.hotels?.map((hotel, index)=>(
                            <Typography variant='body1' sx={{lineHeight: theme.lineHeight[1.37]}} key={`transport-${index}`}>
                              {index + 1}. {hotel.hotel.name}: 
                              <StyledItemLabel sx={{...theme.typography.textMedium}} component={'span'}>
                                {' ' + transportationMap[hotel.hotel_transportation]}
                              </StyledItemLabel>
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    </Grid>
                  </StyledDocGrid>
                </Grid>

                <Grid item xs={12} sx={{mb:2}}>
                  <CheckBox
                    checked={isAgreementAccepted}
                    onChange={(e) => {
                      setIsAgreementAccepted(e.target.checked)
                    }}
                    label={
                      <Typography variant='caption' sx={{
                        ...theme.typography.textMedium,
                        color: theme.palette.table.header,
                        ml: 1,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        Accept Order Processing Agreement according to Art. 28 GDPR <IconButton sx={{p:0, ml:.5, color:theme.palette.icon.light}}><InfoIcon /></IconButton>
                      </Typography>
                    }
                    formControlLabelProps={{
                      sx: {
                        m: 0
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sx={{mb:4}}>
                  <Typography variant='caption' sx={{
                    ...theme.typography.textMedium,
                    color: theme.palette.table.header
                  }}>
                    By clicking on Confirm Layover Booking you enter a binding agreement with the reserved hotel(s) and transport provider(s) and agreement with croowy.de <Typography component='span' sx={{color:theme.palette.primary.light}}>terms and conditions</Typography>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <CustomButton
                    disabled={!isAgreementAccepted}
                    variant='contained'
                    sx={{
                      width: '100%',
                      '&.MuiButton-contained': {
                        ...theme.typography.fontMedium600
                      }
                    }}
                    onClick={updateLayover ? onUpdateLayoverBooking : onConfirmLayoverBooking}
                  >
                    {updateLayover ? 'Update Layover Booking' : 'Confirm Layover Booking'}
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          
          {/* Render Confirm Dialog */}
          <AlertDialog
            title='Layover booking confirmed'
            dialogTitleProps={{
              sx: {
                ...theme.typography.textxxLarge,
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(2)
              }
            }}
            open={layoverBookingDetails && dialogOpen}
            onDisagree={onDialogDisagree}
            noBtnText='Go to Layover Detail'
            sx={{
              textAlign: 'center'
            }}
            fullWidth={true}
            maxWidth={'xs'}
            stretchBtn
          >
            <Box>
              <Typography sx={{lineHeight: '1.37rem', pb:3}} variant='body1'>
                Layover booked successfully, a confirmation mail has been sent to your mail address.
              </Typography>
              <Box sx={{pb:3}}>
                <img
                  src={process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(layoverBookingDetails?.qr_code?.url) : layoverBookingDetails?.qr_code?.url}
                  loading='lazy'
                  alt='QR'
                  style={{
                    height: 125,
                    width: 125
                  }}
                />
              </Box>
              <Typography sx={{lineHeight: '1.37rem', pb:2, ...theme.typography.textxSmall}}>
                The QR code for passengers to scan.
              </Typography>
              <Box>
                <CustomButton
                  variant='contained'
                  sx={{
                    '&.MuiButton-contained': {
                      ...theme.typography.textMedium,
                      paddingTop: theme.spacing(1.5),
                      paddingBottom: theme.spacing(1.5),
                      paddingRight: theme.spacing(5),
                      paddingLeft: theme.spacing(5),
                      marginRight: theme.spacing(2),
                    }
                  }}
                  onClick={() => 
                    printQrCode(
                      layoverBookingDetails?.layover_code,
                      (process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(layoverBookingDetails?.qr_code?.url) : layoverBookingDetails?.qr_code?.url),
                      layoverSummary?.airline_id?.name,layoverBookingDetails?.booking_url
                    )
                  }
                >
                  Print QR Code
                </CustomButton>
                <CustomButton
                  variant='contained'
                  sx={{
                    '&.MuiButton-contained': {
                      ...theme.typography.textMedium,
                      backgroundColor: theme.palette.secondary.light,
                      paddingTop: theme.spacing(1.5),
                      paddingBottom: theme.spacing(1.5),
                      paddingRight: theme.spacing(3.75),
                      paddingLeft: theme.spacing(3.75)
                    }
                  }}
                  onClick={() => onCopyLayoverLink(layoverBookingDetails?.booking_url)}
                >
                  Copy Layover Link
                </CustomButton>
              </Box>
            </Box>
          </AlertDialog>

          {/* render if selected hotel not availability */}
          <AlertDialog
            title='Room Availability Update'
            dialogTitleProps={{
              sx: {
                ...theme.typography.textxxLarge,
                paddingTop: theme.spacing(5),
                color: theme.palette.roseGold[100],
                paddingBottom: theme.spacing(2)
              }
            }}
            open={dialogNotAvailableCapacity}
            onDisagree={handleNotAvailableCapacityGoBackBtn}
            noBtnText={'Go back to hotel selection'}
            sx={{
              textAlign: 'center'
            }}
            fullWidth={true}
            maxWidth={'xs'}
            stretchBtn
          >
            <Box>
              <Typography sx={{pb:1, lineHeight: theme.lineHeight[1.2]}} variant='body1'>
                The availability of Hotel has changed and the hotel does not have the requested room capacity. Please remove the hotel from the booking and re-add it or add another hotel.
              </Typography>
            </Box>
          </AlertDialog>
        </Box>
      ) : (null)}
    </Box>
  );
};

export default ReviewAndConfirm;

const QrDocument = ({image, airlineName, addPassengerLink}) => {
  return(
    <Document>
      <Page size='A4' style={styles.page}>
        <Text style={styles.airlineName}>{airlineName}</Text>
        <Image 
          style={styles.image}
          src={image}
          cache={false} allowDangerousPaths={true}
        />
        <PdfLink style={styles.addPassengerLink} src={addPassengerLink}>{addPassengerLink}</PdfLink>
      </Page>
    </Document>
  )
};

const Dashboard = () => {

  return (
    <>
      Welcome to Croowy...
    </>
  );
};

export default Dashboard;
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CommonLayout from '../common';
import { getMenus, storage } from '../../utils';
import HeaderLeftAppbar from './headerLeftAppbar';
import { AirlineContext } from '../../context/airline';
import { getAirlineDetails } from '../../services/airline';
import { getAirportDetails } from '../../services/airport';
import { setUser } from '../../redux/actions/user';
import { updateSelfUser } from '../../services/user';
import { TermsConditionContext } from '../../context/termsCondition';
import TermsConditionDialogue from '../common/termsConditionDialogue';
import { Box } from '@mui/material';
import { userRoles } from '../../helper/constant';

const AirlineLayout = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [sidebarMenus, setSidebarMenus] = useState(null);
  const [isCommonTopbar, setIsCommonTopbar] = useState(true);
  const [headerPageTitle, setHeaderPageTitle] = useState('');
  const [airlineId, setAirlineId] = useState(null);
  const [airline, setAirline] = useState(null);
  const [airlines, setAirlines] = useState([]);
  const [airportId, setAirportId] = useState(null);
  const [airport, setAirport] = useState(null);
  const [airports, setAirports] = useState([]);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [isSidebarOpened, setIsSidebarOpened] = useState(true);
  const [isAirlineDropdownDisabled, setIsAirlineDropdownDisabled] = useState(false);
  const [isAirportDropdownDisabled, setIsAirportDropdownDisabled] = useState(false);

  useEffect(() => {
    setIsSidebarOpened(storage.getData('sidebarOpened'));
  }, [])

  useEffect(() => {
    if (!isCommonTopbar) {
      setHeaderPageTitle('')
    }
  }, [setHeaderPageTitle, isCommonTopbar])

  useEffect(() => {
    if (!user.user?.id) {
      navigate('/user/login');
    }
    if(user.user?.role?.type) {
      let currentStoredSaProfile = null;
      if (user.user.role.type === userRoles.superAdmin && storage.getData('superAdminActiveProfile')) {
        currentStoredSaProfile = storage.getData('superAdminActiveProfile');
      }
      const menus = getMenus(currentStoredSaProfile ? currentStoredSaProfile : user.user.role.type);
      setSidebarMenus(menus);
    }
    setTermsDialogOpen(!user.user?.terms_accepted)
    /**
     * Set last accessed airline id from API
     * NOTE: If last accessed airline id does not exists in API, then set first airline in the list as last accessed airline id
     */
    if (user.user?.airlines && user.user.airlines.length > 0) {
      const sortedAirlinesArray = user.user.airlines.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setAirlines(sortedAirlinesArray);
      const accessibleAirlineIds = user.user.airlines.map(i => i.id);
      if (user.user.last_accessed_airline_id?.id && accessibleAirlineIds.includes(user.user.last_accessed_airline_id.id)) {
        setAirlineId(user.user.last_accessed_airline_id.id);
      } else {
        setAirlineId(user.user.airlines[0].id);
      }
    } else {
      setAirlines([]);
      setAirlineId(null);
    }

    if (user.user?.airports && user.user.airports.length > 0) {
      const sortedAirportsArray = user.user.airports.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setAirports(sortedAirportsArray);
      const accessibleAirportIds = user.user.airports.map(i => i.id);
      if (user.user.last_accessed_airport_id?.id && accessibleAirportIds.includes(user.user.last_accessed_airport_id.id)) {
        setAirportId(user.user.last_accessed_airport_id.id);
      } else {
        setAirportId(user.user.airports[0].id);
      }
    } else {
      setAirports([]);
      setAirportId(null);
    }
  }, [navigate, user.user])

  /**
   * Get airline details from API
   * 
   * @param {*} id  Airline Id
   */
  const fetchLastAccessedAirlineDetails = async (id) => {
    const airlineDetails = await getAirlineDetails(id);
    if (airlineDetails && airlineDetails?.data) {
      setAirline(airlineDetails.data);
    }
  };

  useEffect(() => {
    if (airlineId) {
      fetchLastAccessedAirlineDetails(airlineId);
    }
  }, [airlineId])

  /**
   * Update last accessed airline id via udpate self user API
   * 
   * @param {*} airlineId   Airline id
   */
  const updateLastAccessedAirlineId = async (airlineId) => {
    await updateSelfUser({last_accessed_airline_id: airlineId});
    dispatch(
      setUser({
        ...user,
        user: {
          ...user.user,
          last_accessed_airline_id: {
            ...user.user?.last_accessed_airline_id,
            id: airlineId
          }
        }
      })
    );
  };

  // Update last accessed airline id
  const onAirlineChange = (airlineId) => {
    updateLastAccessedAirlineId(airlineId);
  };
  
  /**
   * Get airport details from API
   * 
   * @param {*} id  airport Id
   */
  const fetchLastAccessedAirportDetails = async (id) => {
    const airportDetails = await getAirportDetails(id);
    if (airportDetails && airportDetails?.name) {
      setAirport(airportDetails);
    }
  };

  useEffect(() => {
    if (airportId) {
      fetchLastAccessedAirportDetails(airportId);
    }
  }, [airportId])

  /**
   * Update last accessed airport id via udpate self user API
   * 
   * @param {*} airportId   airport id
   */
  const updateLastAccessedAirportId = async (airportId) => {
    await updateSelfUser({last_accessed_airport_id: airportId});
    dispatch(
      setUser({
        ...user,
        user: {
          ...user.user,
          last_accessed_airport_id: {
            ...user.user?.last_accessed_airport_id,
            id: airportId
          }
        }
      })
    );
  };

  // Update last accessed airport id
  const onAirportChange = (airportId) => {
    updateLastAccessedAirportId(airportId);
  };

  /**
   * Update terms_accepted status for terms page via udpate self user API
   * 
   * @param {*} 
   */
  const confirmStatus = async () => {
    try {
      await updateSelfUser({terms_accepted: true});
      dispatch(
        setUser({
          ...user,
          user: {
            ...user.user,
            terms_accepted: true
          }
        })
      );
    } catch (e) {}
  };

  return (
    <AirlineContext.Provider value={{ setAirlineId, airlineId, airline, airlines, fetchLastAccessedAirlineDetails, setIsCommonTopbar, setHeaderPageTitle, isSidebarOpened,
        setIsSidebarOpened, isAirlineDropdownDisabled, setIsAirlineDropdownDisabled, setAirportId, airports, airportId, airport, isAirportDropdownDisabled, setIsAirportDropdownDisabled }}>
      <TermsConditionContext.Provider value={{ termsDialogOpen, setTermsDialogOpen}}>
        <CommonLayout
          menus={sidebarMenus}
          isSidebarOpened={isSidebarOpened}
          pageTitle={headerPageTitle}
          headerLeftAppbar={isCommonTopbar ? '' : (
            <HeaderLeftAppbar airlines={airlines} onAirlineChange={onAirlineChange} airports={airports} onAirportChange={onAirportChange} />
          )}
          termsConditionDialogue={<TermsConditionDialogue confirmStatus={confirmStatus}/>}
        >
          <Box sx={{display: airlineId ? '' : 'flex', justifyContent: airlineId ? '' : 'center', pt: airlineId ? 0 : 40}}>
            {airlineId ? children : 'You do not have access to any airline. Please contact the administrator if you believe this is incorrect.'}
          </Box>
        </CommonLayout>
      </TermsConditionContext.Provider>
    </AirlineContext.Provider>
  );
};

export default AirlineLayout;
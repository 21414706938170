import React from 'react';
import { Box, Typography, Chip, Pagination, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getHotelsInLayover } from '../../../../../services/layover/hotels';
import formatTimestamp from '../../../../../utils/formatTimestamp';
import Accordion from '../../../../../components/accordion';

const LayoverList = ({ layovers = [], selectedTab, recentPage, setRecentPage, allPage, setAllPage }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const rowsPerPage = 10;
    const currentPage = selectedTab === 0 ? recentPage : allPage;
    const paginatedLayovers = layovers.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        if (selectedTab === 0) {
            setRecentPage(newPage - 1);
        } else {
            setAllPage(newPage - 1);
        }
    };

    const handleViewDetailsClick = async (layover) => {
        const response = await getHotelsInLayover(layover?.layoverId);
        if (layover?.status === 'draft') {
            navigate(response?.length === 0 ? `/layover/${layover?.layoverId}/hotels` : `/layover/${layover?.layoverId}/booking`);
        } else {
            navigate(`/layovers/${layover?.layoverId}/details`);
        }
    };

    return (
        <Box sx={{ mt: 3, p: 1 }}>
            {paginatedLayovers.map((layover, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                    <Accordion
                        summary={
                            <Typography variant="subtitle2">
                                {`${layover?.flightCode} (${layover?.toAirportCode})`}
                            </Typography>
                        }
                        details={
                            <Box sx={{ p: 2, backgroundColor: theme.palette.mode === 'light' ? theme.palette.bg[400] : 'inherit' }}>
                                <Typography variant="body1" sx={{ mb: 2, color: theme.palette.grey[500], display: 'flex', alignItems: 'center' }}>
                                    Registered Pax : 
                                    <Typography sx={{ color: theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.grey[50], ml: 1.5 }}>
                                        {layover?.registeredPax ? layover?.registeredPax : '-'}
                                    </Typography>
                                </Typography>

                                <Typography variant="body1" sx={{ mb: 2, color: theme.palette.grey[500], display: 'flex', alignItems: 'center' }}>
                                    Booking Date & Time : 
                                    <Typography sx={{ color: theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.grey[50], ml: 1.5 }}>
                                        {formatTimestamp(layover?.bookingDate)}
                                    </Typography>
                                </Typography>

                                <Typography variant="body1" sx={{ mb: 2, color: theme.palette.grey[500], display: 'flex', alignItems: 'center' }}>
                                    Latest Return to Airport : 
                                    <Typography sx={{ color: theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.grey[50], ml: 1.5 }}>
                                        {layover?.isDifferentReturnTimes === null ? layover?.latestReturnToAirport : formatTimestamp(
                                            null,
                                            layover?.isDifferentReturnTimes,
                                            layover?.passanger_airport_return_date,
                                            layover?.passanger_airport_return_time,
                                            layover?.latestReturnToAirport
                                        )}
                                    </Typography>
                                </Typography>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="body1" sx={{ mr: 2, color: theme.palette.grey[500] }}>
                                        Status :
                                    </Typography>
                                    <Chip
                                        label={layover?.status === 'draft' ? 'Draft' : layover?.status === 'closed' ? 'Closed' : 'In Progress'}
                                        color={layover?.status === 'draft' ? 'error' : layover?.status === 'closed' ? 'warning' : 'success'}
                                    />
                                </Box>

                                <Typography
                                    variant="subtitle2"
                                    sx={{ mt: 2, textAlign: 'right', textDecoration: 'underline', color: theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.blue[200] }}
                                    onClick={() => handleViewDetailsClick(layover)}
                                >
                                    View Details
                                </Typography>
                            </Box>
                        }
                    />
                </Box>
            ))}

            {/* Pagination */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                    count={Math.ceil(layovers?.length / rowsPerPage)}
                    page={currentPage + 1}
                    onChange={handleChangePage}
                    variant="outlined"
                    shape="rounded"
                    hidePrevButton
                    hideNextButton
                    sx={{
                        '& .MuiPaginationItem-root': {
                            backgroundColor: 'grey.800',
                            color: 'background.default',
                            border: 'none',
                            ':hover': {
                                backgroundColor: 'grey.400',
                                color: 'grey.800',
                            },
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            backgroundColor: 'primary.light',
                            color: 'background.default',
                            border: 'none',
                            ':hover': {
                                backgroundColor: 'grey.400',
                                color: 'grey.800',
                            },
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default LayoverList;

import { memo } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { styled } from '@mui/material';

const MuiOtpInputStyled = styled(MuiOtpInput)(({ theme }) => ({
  justifyContent: 'center',
  '& .MuiOtpInput-TextField': {
    maxWidth: theme.spacing(4)
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    '& input': {
      ...theme.typography.subtitle1,
      paddingRight: theme.spacing(.5),
      paddingLeft: theme.spacing(.5),
      textTransform: 'uppercase'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[600]
    }
  }
}));

const OTPInput = (props) => {
  return (
    <MuiOtpInputStyled
      TextFieldsProps={{
        placeholder: '-'
      }}
      sx={{
        gap: 1
      }}
      length={6}
      {...props}
    />
  );
};

export default memo(OTPInput);
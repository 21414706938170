import { memo, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Grid, Typography, styled, useTheme } from '@mui/material';
import { CheckBox, Input } from '../../../../../components';
import { symbol as getCurrencySymbol } from 'better-currency-codes';
import moment from 'moment';
import { formatPrice } from '../../../../../utils';

const StyledMainLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[800],
  textTransform: 'uppercase'
}))

const StyledMealBox = styled(Grid)(({ theme }) => ({}))

const StyledCroowyMealBox = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(1)
}))

const StyledMealTotalPrice = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMedium,
  ...theme.typography.fontMedium500,
  color: theme.palette.roseGold[200],
  paddingTop: theme.spacing(.4),
  paddingBottom: theme.spacing(.4),
  flexGrow: 1,
  textAlign: 'right',
  whiteSpace: 'nowrap'
}))

const StyledMealBoxDescription = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(.75),
  '&>.MuiTypography-root': {
    ...theme.typography.textxSmall,
    lineHeight: theme.lineHeight[1.37]
  }
}))

const HotelSelectionHotelMeal = (props) => {
  const { hotelId, updateLayover, occupants, currency,
    hotel_is_breakfast, hotel_breakfast_price, is_breakfast, breakfast, weekday_breakfast_from, weekday_breakfast_to, weekend_breakfast_from, weekend_breakfast_to,
    hotel_is_early_bird_breakfast, hotel_early_bird_breakfast_price, is_early_bird_breakfast, early_bird_breakfast, weekday_early_bird_breakfast_from, weekday_early_bird_breakfast_to, weekend_early_bird_breakfast_from, weekend_early_bird_breakfast_to,
    hotel_is_lunch, hotel_lunch_price, is_lunch, lunch, lunch_list, weekday_lunch_from, weekday_lunch_to, weekday_lunch_max_people, weekend_lunch_from, weekend_lunch_to, weekend_lunch_max_people,
    croowy_lunch_price, is_croowy_lunch, croowy_lunch,
    hotel_is_dinner, hotel_dinner_price, is_dinner, dinner, dinner_list, weekday_dinner_from, weekday_dinner_to, weekday_dinner_max_people, weekend_dinner_from, weekend_dinner_to, weekend_dinner_max_people,
    croowy_dinner_price, is_croowy_dinner, croowy_dinner,
    hotel_is_snacks, hotel_snacks_price, is_snacks, snacks, snacks_list } = props;
  const { control, formState: { errors }, setValue, getValues, trigger } = useFormContext();
  const theme = useTheme();
  const [breakfastTotalPrice, setBreakfastTotalPrice] = useState(0);
  const [earlyBirdBreakfastTotalPrice, setEarlyBirdBreakfastTotalPrice] = useState(0);
  const [lunchTotalPrice, setLunchTotalPrice] = useState(0);
  const [croowyLunchTotalPrice, setCroowyLunchTotalPrice] = useState(0);
  const [dinnerTotalPrice, setDinnerTotalPrice] = useState(0);
  const [croowyDinnerTotalPrice, setCroowyDinnerTotalPrice] = useState(0);
  const [snacksTotalPrice, setSnacksTotalPrice] = useState(0);

  /**
   * Get hotel currency symbol
   * 
   * @param {*} currency   Currency Object
   */
  const getHotelCurrencySymbol = (currency) => {
    let tempCurrencySymbol = '';
    if (currency?.alphabetic_code) {
      const currencySymbol = getCurrencySymbol({ code: currency.alphabetic_code, numeric: false });
      tempCurrencySymbol = currencySymbol?.native ? currencySymbol.native : currency.alphabetic_code;
    }
    return tempCurrencySymbol;
  };

  // Is any meal selected previously? (NOTE: If yes, it means meal selection done previously, so we don't need to go with the criteria)
  const isAnyMealSelected = useCallback(() => {
    if (is_breakfast === null && is_early_bird_breakfast === null && is_lunch === null && (is_croowy_lunch === null || is_croowy_lunch === false) && is_dinner === null && (is_croowy_dinner === null || is_croowy_dinner === false) && is_snacks === null) {
      return false;
    }
    return true;
  }, [is_breakfast, is_early_bird_breakfast, is_lunch, is_croowy_lunch, is_dinner, is_croowy_dinner, is_snacks]);

  /**
   * Is meal pre-selected?
   * 
   * NOTE: As mentioned in the requirement - https://croowy.atlassian.net/jira/software/projects/LAYOV/boards/4/backlog?selectedIssue=LAYOV-38
   * Meal Pre-selections by system:
   *   bookings between 14:00 and 17:00 (local time) --> Dinner & Breakfast are preselected.
   *   bookings between 17:00 and 23:00 --> Dinner & Breakfast are preselected.
   *   bookings between 23:00 and 04:00 --> Breakfast is preselected
   *   bookings between 04:00 and 09:00 --> Breakfast and Lunch are preselected
   *   bookings between 09:00 and 14:00 --> Breakfast and Lunch is preselected
   * 
   * NOTE 2: AS PER LAYOV-163, WE NEED TO SELECT BREAKFAST AAL THE TIME
   */
  const isMealPreSelected = useCallback((field) => {
    // First, check if meal is provided by the hotel
    switch(field) {
      case 'is_breakfast': {
        if (!hotel_is_breakfast) { return false; }
        break;
      }
      case 'is_dinner': {
        if (!hotel_is_dinner) { return false; }
        break;
      }
      case 'is_croowy_dinner': {
        if (croowy_dinner_price < 0) { return false; }
        break;
      }
      case 'is_lunch': {
        if (!hotel_is_lunch) { return false; }
        break;
      }
      case 'is_croowy_lunch': {
        if (croowy_lunch_price < 0) { return false; }
        break;
      }
      default: break;
    }
    const time = moment();
    switch(true) {
      case time.isBetween(moment('14:00:00', 'hh:mm:ss'), moment('17:00:00', 'hh:mm:ss')):
      case time.isBetween(moment('17:00:00', 'hh:mm:ss'), moment('23:00:00', 'hh:mm:ss')): {
        switch(field) {
          case 'is_breakfast': return true;
          case 'is_dinner': return true;
          case 'is_croowy_dinner': return true;
          case 'is_lunch': return false;
          case 'is_croowy_lunch': return false;
          default: return false;
        }
      }
      case time.isBetween(moment('23:00:00', 'hh:mm:ss'), moment('04:00:00', 'hh:mm:ss')): {
        switch(field) {
          case 'is_breakfast': return true;
          case 'is_dinner': return false;
          case 'is_croowy_dinner': return false;
          case 'is_lunch': return false;
          case 'is_croowy_lunch': return false;
          default: return false;
        }
      }
      case time.isBetween(moment('04:00:00', 'hh:mm:ss'), moment('09:00:00', 'hh:mm:ss')): {
        switch(field) {
          case 'is_breakfast': return true;
          case 'is_dinner': return false;
          case 'is_croowy_dinner': return false;
          case 'is_lunch': return true;
          case 'is_croowy_lunch': return true;
          default: return false;
        }
      }
      case time.isBetween(moment('09:00:00', 'hh:mm:ss'), moment('14:00:00', 'hh:mm:ss')): {
        switch(field) {
          case 'is_breakfast': return true;
          case 'is_dinner': return false;
          case 'is_croowy_dinner': return false;
          case 'is_lunch': return true;
          case 'is_croowy_lunch': return true;
          default: return false;
        }
      }
      default: return false;
    }
  }, [hotel_is_breakfast, hotel_is_dinner, croowy_dinner_price, hotel_is_lunch, croowy_lunch_price]);

  // Calculate meal price according to the quantity
  const calculateMealPrice = useCallback((field) => {
    switch(field) {
      case 'breakfast': {
        if (hotel_is_breakfast) {
          const isBreakfast = getValues(`formHotelSelection[hotels][${hotelId}][is_breakfast]`);
          const qty = getValues(`formHotelSelection[hotels][${hotelId}][breakfast]`);
          if (isBreakfast && qty && qty >= 0 && hotel_breakfast_price && hotel_breakfast_price >= 0) {
            setBreakfastTotalPrice(qty * hotel_breakfast_price);
          } else {
            setBreakfastTotalPrice(0);
          }
        }
        break;
      }
      case 'early_bird_breakfast': {
        if (hotel_is_early_bird_breakfast) {
          const isEarlyBirdBreakfast = getValues(`formHotelSelection[hotels][${hotelId}][is_early_bird_breakfast]`);
          const qty = getValues(`formHotelSelection[hotels][${hotelId}][early_bird_breakfast]`);
          if (isEarlyBirdBreakfast && qty && qty >= 0 && hotel_early_bird_breakfast_price && hotel_early_bird_breakfast_price >= 0) {
            setEarlyBirdBreakfastTotalPrice(qty * hotel_early_bird_breakfast_price);
          } else {
            setEarlyBirdBreakfastTotalPrice(0);
          }
        }
        break;
      }
      case 'lunch': {
        if (hotel_is_lunch) {
          const isLunch = getValues(`formHotelSelection[hotels][${hotelId}][is_lunch]`);
          const qty = getValues(`formHotelSelection[hotels][${hotelId}][lunch]`);
          if (isLunch && qty && qty >= 0 && hotel_lunch_price && hotel_lunch_price >= 0) {
            setLunchTotalPrice(qty * hotel_lunch_price);
          } else {
            setLunchTotalPrice(0);
          }
        }
        break;
      }
      case 'croowy_lunch': {
        const isCroowyLunch = getValues(`formHotelSelection[hotels][${hotelId}][is_croowy_lunch]`);
        const qty = getValues(`formHotelSelection[hotels][${hotelId}][croowy_lunch]`);
        if (isCroowyLunch && qty && qty >= 0 && croowy_lunch_price && croowy_lunch_price >= 0) {
          setCroowyLunchTotalPrice(qty * croowy_lunch_price);
        } else {
          setCroowyLunchTotalPrice(0);
        }
        break;
      }
      case 'dinner': {
        if (hotel_is_dinner) {
          const isDinner = getValues(`formHotelSelection[hotels][${hotelId}][is_dinner]`);
          const qty = getValues(`formHotelSelection[hotels][${hotelId}][dinner]`);
          if (isDinner && qty && qty >= 0 && hotel_dinner_price && hotel_dinner_price >= 0) {
            setDinnerTotalPrice(qty * hotel_dinner_price);
          } else {
            setDinnerTotalPrice(0);
          }
        }
        break;
      }
      case 'croowy_dinner': {
        const isCroowyDinner = getValues(`formHotelSelection[hotels][${hotelId}][is_croowy_dinner]`);
        const qty = getValues(`formHotelSelection[hotels][${hotelId}][croowy_dinner]`);
        if (isCroowyDinner && qty && qty >= 0 && croowy_dinner_price && croowy_dinner_price >= 0) {
          setCroowyDinnerTotalPrice(qty * croowy_dinner_price);
        } else {
          setCroowyDinnerTotalPrice(0);
        }
        break;
      }
      case 'snacks': {
        if (hotel_is_snacks) {
          const isSnacks = getValues(`formHotelSelection[hotels][${hotelId}][is_snacks]`);
          const qty = getValues(`formHotelSelection[hotels][${hotelId}][snacks]`);
          if (isSnacks && qty && qty >= 0 && hotel_snacks_price && hotel_snacks_price >= 0) {
            setSnacksTotalPrice(qty * hotel_snacks_price);
          } else {
            setSnacksTotalPrice(0);
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }, [getValues, hotelId, hotel_is_breakfast, hotel_is_early_bird_breakfast, hotel_is_lunch, hotel_is_dinner, hotel_is_snacks, hotel_breakfast_price, hotel_early_bird_breakfast_price, hotel_lunch_price, croowy_lunch_price, hotel_dinner_price, croowy_dinner_price, hotel_snacks_price]);

  useEffect(() => {
    if (occupants && occupants > 0) {
      // Set the number of meals accroding to number of passangers
      if (hotel_is_breakfast && !isAnyMealSelected() && isMealPreSelected('is_breakfast')) {
        setValue(`formHotelSelection[hotels][${hotelId}][breakfast]`, occupants)
      }

      if (hotel_is_dinner && !isAnyMealSelected() && isMealPreSelected('is_dinner')) {
        setValue(`formHotelSelection[hotels][${hotelId}][dinner]`, occupants)
      } else if (!hotel_is_dinner && isMealPreSelected('is_croowy_dinner')) {
        setValue(`formHotelSelection[hotels][${hotelId}][is_croowy_dinner]`, 1)
        setValue(`formHotelSelection[hotels][${hotelId}][croowy_dinner]`, occupants)
      }

      if (hotel_is_lunch && !isAnyMealSelected() && isMealPreSelected('is_lunch')) {
        setValue(`formHotelSelection[hotels][${hotelId}][lunch]`, occupants)
      } else if (!hotel_is_lunch && isMealPreSelected('is_croowy_lunch')) {
        setValue(`formHotelSelection[hotels][${hotelId}][is_croowy_lunch]`, 1)
        setValue(`formHotelSelection[hotels][${hotelId}][croowy_lunch]`, occupants)
      }
    }
    Promise.all([
      calculateMealPrice('breakfast'),
      calculateMealPrice('early_bird_breakfast'),
      calculateMealPrice('lunch'),
      calculateMealPrice('croowy_lunch'),
      calculateMealPrice('dinner'),
      calculateMealPrice('croowy_dinner'),
      calculateMealPrice('snacks'),
    ]);
  }, [hotelId, isAnyMealSelected, isMealPreSelected, occupants, setValue, hotel_is_breakfast, hotel_is_dinner, hotel_is_lunch, calculateMealPrice])

  return (
    <>
      <Box sx={{display:'flex', alignItems:'center', py:1}}>
        <StyledMainLabel>Select Meals</StyledMainLabel>
      </Box>
      <Grid container columnSpacing={2}>
        <StyledMealBox item lg={2.4}>
          <Box sx={{display:'flex'}}>
            <Controller
              name={`formHotelSelection[hotels][${hotelId}][is_breakfast]`}
              control={control}
              disabled={!hotel_is_breakfast || updateLayover}
              defaultValue={isAnyMealSelected() ? (is_breakfast ? true : false) : isMealPreSelected('is_breakfast')}
              render={({ field }) => (
                <CheckBox
                  inputRef={field.ref}
                  {...field}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    // if early breakfast is already selected and the user trying to select breakfast, then early breakfast will get unticked and quantity will get zero
                    if (getValues(`formHotelSelection[hotels][${hotelId}][is_early_bird_breakfast]`) === true) {
                      setValue(`formHotelSelection[hotels][${hotelId}][early_bird_breakfast]`, 0);
                      setValue(`formHotelSelection[hotels][${hotelId}][is_early_bird_breakfast]`, false);
                      setEarlyBirdBreakfastTotalPrice(0);
                    }
                    if (e.target.checked === false) {
                      setValue(`formHotelSelection[hotels][${hotelId}][breakfast]`, 0);
                    }
                    if (e.target.checked === true) {
                      setValue(`formHotelSelection[hotels][${hotelId}][breakfast]`, occupants);
                    }
                    trigger(`formHotelSelection[hotels][${hotelId}][breakfast]`)
                    calculateMealPrice('breakfast')
                  }}
                  formControlLabelProps={{
                    sx: {
                      m: 0,
                      '&.Mui-disabled': {
                        filter: 'blur(.05rem)'
                      }
                    }
                  }}
                />
              )}
            />
            <Box sx={{flexGrow:1, display:'flex', pl:.25}}>
              <Typography sx={{py:.4}}>Breakfast</Typography>
              {breakfastTotalPrice ? (
                <StyledMealTotalPrice>+{`${getHotelCurrencySymbol(currency)}${formatPrice(breakfastTotalPrice)}`}</StyledMealTotalPrice>
              ) : (null)}
            </Box>
          </Box>
          {/* <Box>
            <Controller
              name={`formHotelSelection[hotels][${hotelId}][breakfast]`}
              control={control}
              disabled={!hotel_is_breakfast}
              defaultValue={isAnyMealSelected() ? ((is_breakfast && breakfast) ? breakfast : 0) : 0}
              render={({ field }) => (
                <Input
                  readOnly={true}
                  formControlProps={{
                    sx: {
                      marginBottom: 0,
                      paddingTop: theme.spacing(.25),
                      '& .Mui-disabled': {
                        filter: 'blur(.05rem)'
                      }
                    }
                  }}
                  sx={{ 
                    borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                    backgroundColor: theme.mode === 'light' ? theme.palette.background.default : 'default'
                  }}
                  isError={getValues(`formHotelSelection[hotels][${hotelId}][is_breakfast]`) && !!errors?.formHotelSelection?.hotels?.[hotelId]?.breakfast}
                  inputRef={field.ref}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                    calculateMealPrice('breakfast')
                  }}
                />
              )}
              rules={{
                validate: (val) => {
                  let tempIsBreakfast = getValues(`formHotelSelection[hotels][${hotelId}][is_breakfast]`);
                  if (tempIsBreakfast) {
                    return /^([1-9]\d*)$/.test(val)
                  } else {
                    return /^(0|[1-9]\d*)$/.test(val)
                  }
                }
              }}
            />
          </Box> */}
          <StyledMealBoxDescription>
            {!hotel_is_breakfast ? (
              <Typography sx={{color: theme.palette.error.main}}>Not available</Typography>
            ) : (
              <>
                {(weekday_breakfast_from && weekday_breakfast_to) ? (
                  <Typography>Weekdays: {moment(weekday_breakfast_from, 'HH:mm').format('hh:mm a')} - {moment(weekday_breakfast_to, 'HH:mm').format('hh:mm a')}</Typography>
                ) : (null)}
                {(weekend_breakfast_from && weekend_breakfast_to) ? (
                  <Typography>Weekends: {moment(weekend_breakfast_from, 'HH:mm').format('hh:mm a')} - {moment(weekend_breakfast_to, 'HH:mm').format('hh:mm a')}</Typography>
                ) : (null)}
              </>
            )}
          </StyledMealBoxDescription>
        </StyledMealBox>
        <StyledMealBox item lg={2.4}>
          <Box sx={{display:'flex'}}>
            <Controller
              name={`formHotelSelection[hotels][${hotelId}][is_early_bird_breakfast]`}
              control={control}
              disabled={!hotel_is_early_bird_breakfast || updateLayover}
              defaultValue={isAnyMealSelected() ? (is_early_bird_breakfast ? true : false) : false}
              render={({ field }) => (
                <CheckBox
                  inputRef={field.ref}
                  {...field}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    // if breakfast is already selected and the user trying to select early breakfast, then breakfast will get unticked and quantity will get zero
                    if (getValues(`formHotelSelection[hotels][${hotelId}][is_breakfast]`) === true) {
                      setValue(`formHotelSelection[hotels][${hotelId}][breakfast]`, 0);
                      setValue(`formHotelSelection[hotels][${hotelId}][is_breakfast]`, false);
                      setBreakfastTotalPrice(0);
                    }
                    if (e.target.checked === false) {
                      setValue(`formHotelSelection[hotels][${hotelId}][early_bird_breakfast]`, 0);
                    }
                    if (e.target.checked === true) {
                      setValue(`formHotelSelection[hotels][${hotelId}][early_bird_breakfast]`, occupants);
                    }
                    trigger(`formHotelSelection[hotels][${hotelId}][early_bird_breakfast]`)
                    calculateMealPrice('early_bird_breakfast')
                  }}
                  formControlLabelProps={{
                    sx: {
                      m: 0,
                      '&.Mui-disabled': {
                        filter: 'blur(.05rem)'
                      }
                    }
                  }}
                />
              )}
            />
            <Box sx={{flexGrow:1, display:'flex', pl:.25}}>
              <Typography sx={{py:.4}}>Early Bird Breakfast</Typography>
              {earlyBirdBreakfastTotalPrice ? (
                <StyledMealTotalPrice>+{`${getHotelCurrencySymbol(currency)}${formatPrice(earlyBirdBreakfastTotalPrice)}`}</StyledMealTotalPrice>
              ) : (null)}
            </Box>
          </Box>
          {/* <Box>
            <Controller
              name={`formHotelSelection[hotels][${hotelId}][early_bird_breakfast]`}
              control={control}
              disabled={!hotel_is_early_bird_breakfast}
              defaultValue={isAnyMealSelected() ? ((is_early_bird_breakfast && early_bird_breakfast) ? early_bird_breakfast : 0) : 0}
              render={({ field }) => (
                <Input
                  readOnly={true}
                  formControlProps={{
                    sx: {
                      marginBottom: 0,
                      paddingTop: theme.spacing(.25),
                      '& .Mui-disabled': {
                        filter: 'blur(.05rem)'
                      }
                    }
                  }}
                  sx={{ 
                    borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                    backgroundColor: theme.mode === 'light' ? theme.palette.background.default : 'default'
                  }}
                  isError={getValues(`formHotelSelection[hotels][${hotelId}][is_early_bird_breakfast]`) && !!errors?.formHotelSelection?.hotels?.[hotelId]?.early_bird_breakfast}
                  inputRef={field.ref}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                    calculateMealPrice('early_bird_breakfast')
                  }}
                />
              )}
              rules={{
                validate: (val) => {
                  let tempIsEarlyBirdBreakfast = getValues(`formHotelSelection[hotels][${hotelId}][is_early_bird_breakfast]`);
                  if (tempIsEarlyBirdBreakfast) {
                    return /^([1-9]\d*)$/.test(val)
                  } else {
                    return /^(0|[1-9]\d*)$/.test(val)
                  }
                }
              }}
            />
          </Box> */}
          <StyledMealBoxDescription>
            {!hotel_is_early_bird_breakfast ? (
              <Typography sx={{color: theme.palette.error.main}}>Not available</Typography>
            ) : (
              <>
                {(weekday_early_bird_breakfast_from && weekday_early_bird_breakfast_to) ? (
                  <Typography>Weekdays: {moment(weekday_early_bird_breakfast_from, 'HH:mm').format('hh:mm a')} - {moment(weekday_early_bird_breakfast_to, 'HH:mm').format('hh:mm a')}</Typography>
                ) : (null)}
                {(weekend_early_bird_breakfast_from && weekend_early_bird_breakfast_to) ? (
                  <Typography>Weekends: {moment(weekend_early_bird_breakfast_from, 'HH:mm').format('hh:mm a')} - {moment(weekend_early_bird_breakfast_to, 'HH:mm').format('hh:mm a')}</Typography>
                ) : (null)}
              </>
            )}
          </StyledMealBoxDescription>
        </StyledMealBox>
        <StyledMealBox item lg={2.4}>
          <Box sx={{display:'flex'}}>
            <Controller
              name={`formHotelSelection[hotels][${hotelId}][is_dinner]`}
              control={control}
              disabled={!hotel_is_dinner || updateLayover}
              defaultValue={isAnyMealSelected() ? (is_dinner ? true : false) : isMealPreSelected('is_dinner')}
              render={({ field }) => (
                <CheckBox
                  inputRef={field.ref}
                  {...field}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    if (e.target.checked === false) {
                      setValue(`formHotelSelection[hotels][${hotelId}][dinner]`, 0)
                    }
                    if (e.target.checked === true) {
                      setValue(`formHotelSelection[hotels][${hotelId}][dinner]`, occupants);
                    }
                    trigger(`formHotelSelection[hotels][${hotelId}][dinner]`)
                    calculateMealPrice('dinner')
                  }}
                  formControlLabelProps={{
                    sx: {
                      m: 0,
                      '&.Mui-disabled': {
                        filter: 'blur(.05rem)'
                      }
                    }
                  }}
                />
              )}
            />
            <Box sx={{flexGrow:1, display:'flex', pl:.25}}>
              <Typography sx={{py:.4}}>Dinner</Typography>
              {dinnerTotalPrice ? (
                <StyledMealTotalPrice>+{`${getHotelCurrencySymbol(currency)}${formatPrice(dinnerTotalPrice)}`}</StyledMealTotalPrice>
              ) : (null)}
            </Box>
          </Box>
          {/* <Box>
            <Controller
              name={`formHotelSelection[hotels][${hotelId}][dinner]`}
              control={control}
              disabled={!hotel_is_dinner}
              defaultValue={isAnyMealSelected() ? ((is_dinner && dinner) ? dinner : 0) : 0}
              render={({ field }) => (
                <Input
                  readOnly={true}
                  formControlProps={{
                    sx: {
                      marginBottom: 0,
                      paddingTop: theme.spacing(.25),
                      '& .Mui-disabled': {
                        filter: 'blur(.05rem)'
                      }
                    }
                  }}
                  sx={{ 
                    borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                    backgroundColor: theme.mode === 'light' ? theme.palette.background.default : 'default'
                  }}
                  isError={getValues(`formHotelSelection[hotels][${hotelId}][is_dinner]`) && !!errors?.formHotelSelection?.hotels?.[hotelId]?.dinner}
                  inputRef={field.ref}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                    calculateMealPrice('dinner')
                  }}
                />
              )}
              rules={{
                validate: (val) => {
                  let tempIsDinner = getValues(`formHotelSelection[hotels][${hotelId}][is_dinner]`);
                  if (tempIsDinner) {
                    return /^([1-9]\d*)$/.test(val)
                  } else {
                    return /^(0|[1-9]\d*)$/.test(val)
                  }
                }
              }}
            />
          </Box> */}
          <StyledMealBoxDescription>
            {!hotel_is_dinner ? (
              <Typography sx={{color: theme.palette.error.main}}>Not available</Typography>
            ) : (
              <>
                {(weekday_dinner_from && weekday_dinner_to) ? (
                  <Typography>Weekdays: {moment(weekday_dinner_from, 'HH:mm').format('hh:mm a')} - {moment(weekday_dinner_to, 'HH:mm').format('hh:mm a')}{`${(weekday_dinner_max_people && Number(weekday_dinner_max_people) > 0) ? ` (max. ${weekday_dinner_max_people} people)` : ``}`}</Typography>
                ) : (null)}
                {(weekend_dinner_from && weekend_dinner_to) ? (
                  <Typography>Weekends: {moment(weekend_dinner_from, 'HH:mm').format('hh:mm a')} - {moment(weekend_dinner_to, 'HH:mm').format('hh:mm a')}{`${(weekend_dinner_max_people && Number(weekend_dinner_max_people) > 0) ? ` (max. ${weekend_dinner_max_people} people)` : ``}`}</Typography>
                ) : (null)}
                {dinner_list ? (
                  <Typography>Our dinner includes {dinner_list}</Typography>
                ) : (null)}
              </>
            )}
          </StyledMealBoxDescription>
          {(!hotel_is_dinner && croowy_dinner_price && croowy_dinner_price > 0) ? (
            <StyledCroowyMealBox>
              <Box sx={{mb:.5}}>
                <Controller
                  name={`formHotelSelection[hotels][${hotelId}][is_croowy_dinner]`}
                  control={control}
                  defaultValue={isAnyMealSelected() ? (is_croowy_dinner ? true : false) : isMealPreSelected('is_croowy_dinner')}
                  disabled={updateLayover}
                  render={({ field }) => (
                    <CheckBox
                      inputRef={field.ref}
                      {...field}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        if (e.target.checked === false) {
                          setValue(`formHotelSelection[hotels][${hotelId}][croowy_dinner]`, 0)
                        }
                        if (e.target.checked === true) {
                          setValue(`formHotelSelection[hotels][${hotelId}][croowy_dinner]`, occupants);
                        }
                        trigger(`formHotelSelection[hotels][${hotelId}][croowy_dinner]`)
                        calculateMealPrice('croowy_dinner')
                      }}
                      formControlLabelProps={{
                        sx: {
                          alignItems: 'start',
                          m: 0,
                          '&.Mui-disabled': {
                            filter: 'blur(.05rem)'
                          }
                        }
                      }}
                      label={
                        <Typography sx={{
                          ...theme.typography.textxSmall,
                          lineHeight: theme.lineHeight[1.2],
                          pl:.5
                        }}>
                          Request Croowy to organize the Dinner.
                        </Typography>
                      }
                    />
                  )}
                />
              </Box>
              <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                <Box sx={{flexGrow:1, mr:.5}}>
                  <Typography sx={{...theme.typography.textxSmall}}>Number of meals</Typography>
                </Box>
                <Box>
                  <Controller
                    name={`formHotelSelection[hotels][${hotelId}][croowy_dinner]`}
                    control={control}
                    defaultValue={(is_croowy_dinner && croowy_dinner) ? croowy_dinner : 0}
                    render={({ field }) => (
                      <Input
                        readOnly={true}
                        formControlProps={{
                          sx: {
                            maxWidth: theme.spacing(6),
                            marginBottom: 0,
                            paddingTop: theme.spacing(.25),
                            '& .Mui-disabled': {
                              filter: 'blur(.05rem)'
                            }
                          }
                        }}
                        isError={!!errors?.formHotelSelection?.hotels?.[hotelId]?.croowy_dinner}
                        inputRef={field.ref}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e)
                          calculateMealPrice('croowy_dinner')
                        }}
                        sx={{
                          borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                          backgroundColor: theme.mode === 'light' ? theme.palette.background.default : 'default',
                          '& .MuiInputBase-input': {
                            paddingTop: 0,
                            paddingRight: .5,
                            paddingBottom: 0,
                            paddingLeft: .5
                          }
                        }}
                      />
                    )}
                    rules={{
                      validate: (val) => {
                        let tempIsCroowyDinner = getValues(`formHotelSelection[hotels][${hotelId}][is_croowy_dinner]`);
                        if (tempIsCroowyDinner) {
                          return /^([1-9]\d*)$/.test(val)
                        } else {
                          return /^(0|[1-9]\d*)$/.test(val)
                        }
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                <Box sx={{flexGrow:1, mr:.5}}>
                  <Typography sx={{...theme.typography.textxSmall, lineHeight: theme.lineHeight[1.5]}}>Budget per P. ({`${getHotelCurrencySymbol(currency)}${formatPrice(croowy_dinner_price)}`})</Typography>
                </Box>
                <Box>
                  {croowyDinnerTotalPrice ? (
                    <StyledMealTotalPrice>+{`${getHotelCurrencySymbol(currency)}${formatPrice(croowyDinnerTotalPrice)}`}</StyledMealTotalPrice>
                  ) : (null)}
                </Box>
              </Box>
            </StyledCroowyMealBox>
          ) : (null)}
        </StyledMealBox>
        <StyledMealBox item lg={2.4}>
          <Box sx={{display:'flex'}}>
            <Controller
              name={`formHotelSelection[hotels][${hotelId}][is_lunch]`}
              control={control}
              disabled={!hotel_is_lunch || updateLayover}
              defaultValue={isAnyMealSelected() ? (is_lunch ? true : false) : isMealPreSelected('is_lunch')}
              render={({ field }) => (
                <CheckBox
                  inputRef={field.ref}
                  {...field}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    if (e.target.checked === false) {
                      setValue(`formHotelSelection[hotels][${hotelId}][lunch]`, 0)
                    }
                    if (e.target.checked === true) {
                      setValue(`formHotelSelection[hotels][${hotelId}][lunch]`, occupants);
                    }
                    trigger(`formHotelSelection[hotels][${hotelId}][lunch]`)
                    calculateMealPrice('lunch')
                  }}
                  formControlLabelProps={{
                    sx: {
                      m: 0,
                      '&.Mui-disabled': {
                        filter: 'blur(.05rem)'
                      }
                    }
                  }}
                />
              )}
            />
            <Box sx={{flexGrow:1, display:'flex', pl:.25}}>
              <Typography sx={{py:.4}}>Lunch</Typography>
              {lunchTotalPrice ? (
                <StyledMealTotalPrice>+{`${getHotelCurrencySymbol(currency)}${formatPrice(lunchTotalPrice)}`}</StyledMealTotalPrice>
              ) : (null)}
            </Box>
          </Box>
          {/* <Box>
            <Controller
              name={`formHotelSelection[hotels][${hotelId}][lunch]`}
              control={control}
              disabled={!hotel_is_lunch}
              defaultValue={isAnyMealSelected() ? ((is_lunch && lunch) ? lunch : 0) : 0}
              render={({ field }) => (
                <Input
                  readOnly={true}
                  formControlProps={{
                    sx: {
                      marginBottom: 0,
                      paddingTop: theme.spacing(.25),
                      '& .Mui-disabled': {
                        filter: 'blur(.05rem)'
                      }
                    }
                  }}
                  sx={{
                    borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                    backgroundColor: theme.mode === 'light' ? theme.palette.background.default : 'default',
                  }}
                  isError={getValues(`formHotelSelection[hotels][${hotelId}][is_lunch]`) && !!errors?.formHotelSelection?.hotels?.[hotelId]?.lunch}
                  inputRef={field.ref}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                    calculateMealPrice('lunch')
                  }}
                />
              )}
              rules={{
                validate: (val) => {
                  let tempIsLunch = getValues(`formHotelSelection[hotels][${hotelId}][is_lunch]`);
                  if (tempIsLunch) {
                    return /^([1-9]\d*)$/.test(val)
                  } else {
                    return /^(0|[1-9]\d*)$/.test(val)
                  }
                }
              }}
            />
          </Box> */}
          <StyledMealBoxDescription>
            {!hotel_is_lunch ? (
              <Typography sx={{color: theme.palette.error.main}}>Not available</Typography>
            ) : (
              <>
                {(weekday_lunch_from && weekday_lunch_to) ? (
                  <Typography>Weekdays: {moment(weekday_lunch_from, 'HH:mm').format('hh:mm a')} - {moment(weekday_lunch_to, 'HH:mm').format('hh:mm a')}{`${(weekday_lunch_max_people && Number(weekday_lunch_max_people) > 0) ? ` (max. ${weekday_lunch_max_people} people)` : ``}`}</Typography>
                ) : (null)}
                {(weekend_lunch_from && weekend_lunch_to) ? (
                  <Typography>Weekends: {moment(weekend_lunch_from, 'HH:mm').format('hh:mm a')} - {moment(weekend_lunch_to, 'HH:mm').format('hh:mm a')}{`${(weekend_lunch_max_people && Number(weekend_lunch_max_people) > 0) ? ` (max. ${weekend_lunch_max_people} people)` : ``}`}</Typography>
                ) : (null)}
                {lunch_list ? (
                  <Typography>Our lunch includes {lunch_list}</Typography>
                ) : (null)}
              </>
            )}
          </StyledMealBoxDescription>
          {(!hotel_is_lunch && croowy_lunch_price && croowy_lunch_price > 0) ? (
            <StyledCroowyMealBox>
              <Box sx={{mb:.5}}>
                <Controller
                  name={`formHotelSelection[hotels][${hotelId}][is_croowy_lunch]`}
                  control={control}
                  defaultValue={isAnyMealSelected() ? (is_croowy_lunch ? true : false) : isMealPreSelected('is_croowy_lunch')}
                  disabled={updateLayover}
                  render={({ field }) => (
                    <CheckBox
                      inputRef={field.ref}
                      {...field}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        if (e.target.checked === false) {
                          setValue(`formHotelSelection[hotels][${hotelId}][croowy_lunch]`, 0)
                        }
                        if (e.target.checked === true) {
                          setValue(`formHotelSelection[hotels][${hotelId}][croowy_lunch]`, occupants);
                        }
                        trigger(`formHotelSelection[hotels][${hotelId}][croowy_lunch]`)
                        calculateMealPrice('croowy_lunch')
                      }}
                      formControlLabelProps={{
                        sx: {
                          alignItems: 'start',
                          m: 0,
                          '&.Mui-disabled': {
                            filter: 'blur(.05rem)'
                          }
                        }
                      }}
                      label={
                        <Typography sx={{
                          ...theme.typography.textxSmall,
                          lineHeight: theme.lineHeight[1.2],
                          pl:.5
                        }}>
                          Request Croowy to organize the Lunch.
                        </Typography>
                      }
                    />
                  )}
                />
              </Box>
              <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                <Box sx={{flexGrow:1, mr:.5}}>
                  <Typography sx={{...theme.typography.textxSmall}}>Number of meals</Typography>
                </Box>
                <Box>
                  <Controller
                    name={`formHotelSelection[hotels][${hotelId}][croowy_lunch]`}
                    control={control}
                    defaultValue={(is_croowy_lunch && croowy_lunch) ? croowy_lunch : 0}
                    render={({ field }) => (
                      <Input
                        readOnly={true}
                        formControlProps={{
                          sx: {
                            maxWidth: theme.spacing(6),
                            marginBottom: 0,
                            paddingTop: theme.spacing(.25),
                            '& .Mui-disabled': {
                              filter: 'blur(.05rem)'
                            }
                          }
                        }}
                        isError={!!errors?.formHotelSelection?.hotels?.[hotelId]?.croowy_lunch}
                        inputRef={field.ref}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e)
                          calculateMealPrice('croowy_lunch')
                        }}
                        sx={{
                          borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                          backgroundColor: theme.mode === 'light' ? theme.palette.background.default : 'default',
                          '& .MuiInputBase-input': {
                            paddingTop: 0,
                            paddingRight: .5,
                            paddingBottom: 0,
                            paddingLeft: .5
                          }
                        }}
                      />
                    )}
                    rules={{
                      validate: (val) => {
                        let tempIsCroowyLunch = getValues(`formHotelSelection[hotels][${hotelId}][is_croowy_lunch]`);
                        if (tempIsCroowyLunch) {
                          return /^([1-9]\d*)$/.test(val)
                        } else {
                          return /^(0|[1-9]\d*)$/.test(val)
                        }
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                <Box sx={{flexGrow:1, mr:.5}}>
                  <Typography sx={{...theme.typography.textxSmall, lineHeight: theme.lineHeight[1.5]}}>Budget per P. ({`${getHotelCurrencySymbol(currency)}${formatPrice(croowy_lunch_price)}`})</Typography>
                </Box>
                <Box>
                  {croowyLunchTotalPrice ? (
                    <StyledMealTotalPrice>+{`${getHotelCurrencySymbol(currency)}${formatPrice(croowyLunchTotalPrice)}`}</StyledMealTotalPrice>
                  ) : (null)}
                </Box>
              </Box>
            </StyledCroowyMealBox>
          ) : (null)}
        </StyledMealBox>
        <StyledMealBox item lg={2.4}>
          <Box sx={{display:'flex'}}>
            <Controller
              name={`formHotelSelection[hotels][${hotelId}][is_snacks]`}
              control={control}
              disabled={!hotel_is_snacks || updateLayover}
              defaultValue={isAnyMealSelected() ? (is_snacks ? true : false) : false}
              render={({ field }) => (
                <CheckBox
                  inputRef={field.ref}
                  {...field}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    if (e.target.checked === false) {
                      setValue(`formHotelSelection[hotels][${hotelId}][snacks]`, 0)
                    }
                    if (e.target.checked === true) {
                      setValue(`formHotelSelection[hotels][${hotelId}][snacks]`, occupants);
                    }
                    trigger(`formHotelSelection[hotels][${hotelId}][snacks]`)
                    calculateMealPrice('snacks')
                  }}
                  formControlLabelProps={{
                    sx: {
                      m: 0,
                      '&.Mui-disabled': {
                        filter: 'blur(.05rem)'
                      }
                    }
                  }}
                />
              )}
            />
            <Box sx={{flexGrow:1, display:'flex', pl:.25}}>
              <Typography sx={{py:.4}}>Snacks</Typography>
              {snacksTotalPrice ? (
                <StyledMealTotalPrice>+{`${getHotelCurrencySymbol(currency)}${formatPrice(snacksTotalPrice)}`}</StyledMealTotalPrice>
              ) : (null)}
            </Box>
          </Box>
          {/* <Box>
            <Controller
              name={`formHotelSelection[hotels][${hotelId}][snacks]`}
              control={control}
              disabled={!hotel_is_snacks}
              defaultValue={isAnyMealSelected() ? ((is_snacks && snacks) ? snacks : 0) : 0}
              render={({ field }) => (
                <Input
                  readOnly={true}
                  formControlProps={{
                    sx: {
                      marginBottom: 0,
                      paddingTop: theme.spacing(.25),
                      '& .Mui-disabled': {
                        filter: 'blur(.05rem)'
                      }
                    }
                  }}
                  sx={{
                    borderColor: theme.mode === 'light' ? theme.palette.primary.main : 'default',
                    backgroundColor: theme.mode === 'light' ? theme.palette.background.default : 'default',
                  }}
                  isError={getValues(`formHotelSelection[hotels][${hotelId}][is_snacks]`) && !!errors?.formHotelSelection?.hotels?.[hotelId]?.snacks}
                  inputRef={field.ref}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                    calculateMealPrice('snacks')
                  }}
                />
              )}
              rules={{
                validate: (val) => {
                  let tempIsSnacks = getValues(`formHotelSelection[hotels][${hotelId}][is_snacks]`);
                  if (tempIsSnacks) {
                    return /^([1-9]\d*)$/.test(val)
                  } else {
                    return /^(0|[1-9]\d*)$/.test(val)
                  }
                }
              }}
            />
          </Box> */}
          <StyledMealBoxDescription>
            {!hotel_is_snacks ? (
              <Typography sx={{color: theme.palette.error.main}}>Not available</Typography>
            ) : (
              <>
                {snacks_list ? (
                  <Typography>Our snacks includes {snacks_list}</Typography>
                ) : (null)}
              </>
            )}
          </StyledMealBoxDescription>
        </StyledMealBox>
      </Grid>
    </>
  );
};

export default memo(HotelSelectionHotelMeal);
import React, { useContext, useEffect, useCallback, useState, useRef, Fragment } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { AirlineContext } from '../../../context/airline';
import {
  Box,
  Divider,
  useTheme,
  Typography,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  Avatar,
  Chip
} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import SearchIcon from '@mui/icons-material/Search';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Dropdown, Input } from '../../../components';
import { InputEndAdornment } from '../components/endAdornment';
import { LargeHeading } from '../../hotel/components/heading';
import { useLoader } from '../../../providers/loader';
import { getLayoverDetails } from '../../../services/layover/details';
import MailToAllPassenger from './components/mailToAllPassenger.js';
import saveAs from 'file-saver';
import { Document, Page, Image, StyleSheet, pdf, Text, Link as PdfLink } from '@react-pdf/renderer';
import prepareImageUrlByPath from '../../../utils/prepareImageUrlByPath.js';
import { languages, maxToastTime, pageTitles, toastMessages } from '../../../helper/constant.js';
import formatTimestamp from '../../../utils/formatTimestamp.js';
import { HeadingInput } from '../components/headingInput.js';
import { toast } from 'react-toastify';
import downloadJsonAsXlsx from '../../../utils/downloadJsonAsXlsx.js';
import downloadCSVFile from '../../../utils/downloadCSV.js';
import moment from 'moment';

// Styles required for QR pdf
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#ffffff',
  },
  image: {
    width: 300,
    height: 300,
  },
  airlineName: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 20
  },
  addPassengerLink: {
    fontSize: 20
  }
});

const Para = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  alignItems: 'flex-start',
  textAlign: 'left',
}))

// const StyledButton = styled(Button, {
//   shouldForwardProp: (prop) => ['active'].indexOf(prop) === -1
// })(({ theme, active }) => ({
//   borderRadius: theme.spacing(0.5),
//   width: theme.spacing(5),
//   borderColor: theme.palette.primary.light,
//   backgroundColor: active ? theme.palette.primary.light : 'transparent',
//   color: active ? theme.palette.primary.dark : theme.palette.primary.light,
//   minHeight: theme.spacing(5),
//   minWidth: theme.spacing(3),
//   '&:hover': {
//     backgroundColor: active ? theme.palette.primary.light : 'transparent',
//     opacity: '.85'
//   }
// }));

const HeadingBar = styled(Typography)(({ theme }) => ({
   ...theme.typography.textMedium,
   ...theme.typography.fontSemiBold600,
  alignItems: 'flex-start',
  textAlign: 'left',
  marginBottom: theme.spacing(1.5),
  color: theme.palette.dashboardWidgetHeadline,
}))

const DarkPara = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  textTransform: 'uppercase',
  color: theme.palette.grey[800],
  marginBottom: theme.spacing(1),
  alignItems: 'flex-start',
  textAlign: 'left',
}))

// Custom styled TableCell for table headings
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.fontSemiBold600,
  color: theme.palette.table.header,
  borderBottom: 'none',
  textTransform: 'uppercase',
  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& > *': {
    position: 'relative',
  },
  '& svg': {
    marginBottom: `-${theme.spacing(0.5)}`,
    paddingRight: theme.spacing(0.7),
    color: theme.palette.grey[800],
  },
  '& .passenger-info': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(0.7),
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1),
    },
  },
  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
}));

const CustomTableCell = styled(TableCell)(({ theme }) =>({
  fontWeight: theme.typography.fontMedium600.fontWeight,
  backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
}));

const CustomBox = styled(Box)(({ theme }) =>({
  fontWeight: theme.typography.fontMedium500.fontWeight,
  color: theme.palette.alert.dark
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginRight: theme.spacing(1.2),
}));

const RoseGoldButton = styled(Button)(({ theme }) => ({
  ...theme.typography.textLarge,
  textTransform: 'none',
  color: theme.palette.roseGold[100],
  paddingRight: 0,
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(0.5),
  }
}));

const Layovers = () => {
  const { airlineId, setIsCommonTopbar, setIsAirlineDropdownDisabled, setIsAirportDropdownDisabled, setHeaderPageTitle, setAirlineId, setAirportId } = useContext(AirlineContext);
  const theme = useTheme();
  const newWindowRef = useRef(null);
  const [layoverDetails, setLayoverDetails] = useState(null);
  const { startLoader, stopLoader } = useLoader();
  const { layoverId } = useParams();
  const navigate = useNavigate();
  const [filteredPassengers, setFilteredPassengers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [mailToDialogOpen, setMailToDialogOpen] = useState(false);
  const [passengerWindow, setPassengerWindow] = useState(null);
  const roomsByType = layoverDetails?.passengers_info?.roomsTotalByType?.filter((roomsByType) => roomsByType.name !== undefined) || [];
  const hotels = layoverDetails?.passengers_info?.hotels || [];
  const availableRooms = Number(layoverDetails?.passengers_info?.totalRooms) - Number(layoverDetails?.passengers_info?.bookedRooms);

  // passenger info Box related styling
  const passengerInfoMainColumnWidth = theme.spacing(30);
  const passengerInfoOtherColumnsWidth = theme.spacing(12);
  const passengerInfoOtherHotelRowHeight = theme.spacing(5);
  const passengerInfoMainBoxHeight = theme.spacing(12);

  const {
    control
  } = useForm();
  
  const getLanguageByCode = (langCode) => {
    const langCodeLowerCase = langCode.toLowerCase();
    return (languages?.[langCodeLowerCase]?.name) ? languages[langCodeLowerCase].name : langCode;
  };

  useEffect(() => {
    setIsCommonTopbar(false);
    setIsAirlineDropdownDisabled(true);
    setIsAirportDropdownDisabled(true);
    setHeaderPageTitle(pageTitles.airline.layoverDetails);
  }, [setIsCommonTopbar, setIsAirlineDropdownDisabled, setIsAirportDropdownDisabled, setHeaderPageTitle]);

  // const handleSendEmail = (mailId) => {
  //   window.location.href = `mailto:${mailId}`;
  // };

  const convertPassengerDataCSV = () => {
    // headers for csv file
    let headers = ['First Name,Last Name,Email Id,Hotel Name,Booking Id,Preferred Language']
    
    // Convert all passenger data into camma separated string
    let passengersCsv = filteredPassengers.reduce((acc, passenger) => {
      // Add co-passenger data
      const coPassengerRows = passenger.coPassengers.map(coPassenger => [
        coPassenger?.first_name,
        coPassenger?.last_name,
        coPassenger?.isLead ? passenger?.leadPassengerEmail: '-',
        coPassenger?.hotelName,
        passenger?.confirmationCode,
        getLanguageByCode(passenger?.preferredLanguageCode)
      ]);
      acc.push(...coPassengerRows);

      return acc;
    }, []);
  
    downloadCSVFile({
      data: [...headers, ...passengersCsv].join('\n'),
      fileName: 'passengerList.csv',
      fileType: 'text/csv',
    })
  }

  const convertPassengerDataXlsx = () => {
    // Headers for XLSX file
    let headers = ['First Name', 'Last Name', 'Email Id', 'Hotel Name', 'Booking Id', 'Preferred Language'];

    const passengersXlsx = filteredPassengers.reduce((acc, passenger) => {

      // Add co-passenger data
      const coPassengerRows = passenger.coPassengers.map(coPassenger => [
        coPassenger?.first_name,
        coPassenger?.last_name,
        coPassenger?.isLead ? passenger?.leadPassengerEmail: '-',
        coPassenger?.hotelName,
        passenger?.confirmationCode,
        getLanguageByCode(passenger?.preferredLanguageCode)
      ]);
      acc.push(...coPassengerRows);

      return acc;
    }, []);

    // Download XLSX file
    downloadJsonAsXlsx({
      data: [headers, ...passengersXlsx],
      fileName: 'passengerList.xlsx',
    });
  };
  
  const getLayoverFromApi = useCallback(async (layoverId) => {
    try {
      const response = await getLayoverDetails(layoverId);
      return response;
    } catch (e) {
      throw e;
    }
  }, []);

  useEffect(() => {
    if (layoverId && passengerWindow === null) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await getLayoverFromApi(layoverId);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      })
      .then((response) => {
        setLayoverDetails(response);
        setAirlineId(response.bookingInfo.airlineId);
        setAirportId(response.bookingInfo.airportId);
      }).catch((e) => {
        // Handle error if necessary
      }).finally(() => {
        stopLoader();
      });
    }
  }, [layoverId, getLayoverFromApi, passengerWindow, startLoader, stopLoader]);

  const filterPassengers = (data, searchTerm) => {
    if (data) {
      if (!searchTerm) {
        return data.passenger_list;
      } else {
        return data.passenger_list.filter((passenger) =>
          passenger.leadPassengerFirstName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
    }
  }

  useEffect(() => {
    setFilteredPassengers(filterPassengers(layoverDetails, searchTerm));
  }, [layoverDetails, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  }

  const printQrCode = async (filename, img, airlineName, addPassengerLink) => {
    const image = await img
    const blob = await pdf(<QrDocument image={image} airlineName={airlineName} addPassengerLink={addPassengerLink} />).toBlob()
    saveAs(blob, `${filename}.pdf`)
  };

  const handleAddPassenger = () => {
    if (availableRooms === 0) {
      toast.error(toastMessages.addPassengerWarning, {
        autoClose: maxToastTime,
        toastId: 'add-passenger-warning',
      });
    } else {
      const newUrl = `${layoverDetails?.bookingUrl}?clear_cache=true`;
      const desiredWidth = 500;
      const desiredHeight = 800;
      const newWindowFeatures = `width=${desiredWidth},height=${desiredHeight},toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`;
      newWindowRef.current = window.open(newUrl, '_blank', newWindowFeatures);
      if (newWindowRef && newWindowRef.current) {
        newWindowRef.current.focus();
      }

      const checkpassengerWindowClosedOrMinimized = setInterval(() => {
        if (newWindowRef && newWindowRef.current) {
          if (newWindowRef?.current?.closed) {
            clearInterval(checkpassengerWindowClosedOrMinimized);
            setPassengerWindow(null);
          } else if (newWindowRef?.current?.outerWidth <= 160 && newWindowRef?.current?.outerHeight <= 37) {
            setPassengerWindow(null);
          } else {
            setPassengerWindow(newWindowRef?.current);
          }
        }
      }, 1000);
      setPassengerWindow(newWindowRef?.current);
    }
  };
  
  return (
    <Box sx={{p: 2, backgroundColor: theme.mode === 'light' && theme.palette.common.white, borderRadius: theme.borderRadius[10]}}>
      {layoverDetails 
        ? (<Box sx={{ px: theme.spacing(2), py: theme.spacing(2) }}>
            <Box sx={{ padding: theme.spacing(1.5) }}>
              <Stack 
                direction='row' 
                justifyContent='flex-end' 
                alignItems='center'
                sx={{ mb: theme.spacing(1.5), gap: 2 }}
              >
                <Para sx={{ padding: theme.spacing(0.5) }}>
                  {/* <Heading title='Layover Details' /> */}
                </Para>
                <RoseGoldButton
                  onClick={() => printQrCode(layoverDetails?.qrCodeLink?.name.replace(/\.[^/]*$/, ''),
                    (process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(layoverDetails?.qrCodeLink?.url) : layoverDetails?.qrCodeLink?.url),
                    layoverDetails?.bookingInfo?.airlineName,layoverDetails?.bookingUrl
                  )}
                  variant='text'
                  startIcon={<GetAppIcon />}
                >
                  Download QR Code
                </RoseGoldButton>
                <Button
                  disabled={layoverDetails?.status !== 'in_progress'}
                  onClick={() => navigate(`/layover/${layoverDetails?.layoverId}/booking`, { state: { updateLayover: true } })}
                  variant='contained'
                  sx={{
                    p: 0, px: 1, textTransform: 'capitalize', 
                    backgroundColor: theme.mode === 'dark' ? theme.palette.sidebarActiveBg : theme.palette.grey[500],
                    color: theme.mode === 'dark' ? theme.palette.text.primary : theme.palette.common.white,
                    '&.MuiButton-contained': {
                      ...theme.typography.muiTypography600
                    }
                  }}
                >+ Add Rooms</Button>
              </Stack>

              <Box>
                <Grid container spacing={2} margin={0} xs={12}>
                  {/* Grid item for the booking info card */}
                  <Grid container item xs={12} md={5.5}>
                    <Grid container item  
                      component={Paper} 
                      sx={{p: theme.spacing(1), pb: theme.spacing(2), backgroundColor: theme.mode === 'light' && theme.palette.bg[400], borderRadius: theme.borderRadius[10]}}
                      direction='column'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                      spacing={2}
                    >
                      <Grid item>
                        <HeadingBar>Booking info</HeadingBar>
                      </Grid>
                      <Grid container item spacing={8}>
                        <Grid container item xs={6} spacing={1} alignItems='center'>
                          <Grid item>
                            <Avatar sx={{ width: 33, height: 33 }}>{layoverDetails?.bookingInfo?.airlineName[0]}</Avatar>
                          </Grid>
                          <Grid item>
                            <Typography variant='body1'>
                              {layoverDetails?.bookingInfo?.airlineName}{' '}
                              {layoverDetails?.bookingInfo?.flight_code}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant='body2' 
                              sx={{ 
                                marginTop: theme.spacing(0.4), 
                                textTransform: 'uppercase',
                                color: theme.palette.grey[800],
                              }}
                            >{layoverDetails?.bookingInfo?.toAirportCode_iata}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2' 
                            sx={{ 
                              marginTop: theme.spacing(0.4),
                              color: theme.palette.primary.light,
                            }}
                          >{layoverDetails?.passengers_info?.totalRooms} rooms booked</Typography>
                        </Grid>
                      </Grid>
                      <Grid item sx={{width: '95%'}}>
                        <Divider/>
                      </Grid>
                      <Grid container item spacing={8} sx={{overflow: 'hidden'}}>
                        <Grid item xs={4}>
                          <DarkPara variant='body1'>BOOKING DATE & TIME</DarkPara>
                          <Typography variant='body2'>
                            {formatTimestamp(layoverDetails?.bookingInfo?.bookingDate)}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <DarkPara variant='body1'>DEPARTURE FROM AIRPORT</DarkPara>
                          <Typography variant='body2'>
                            {formatTimestamp(layoverDetails?.bookingInfo?.estimated_arrival_at_hotel)}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <DarkPara variant='body1'>LUGGAGE DETAILS</DarkPara>
                          <Typography variant='body2'>
                            {layoverDetails?.bookingInfo?.luggageType === 'HAND' 
                              ? 'Hand-luggage only'
                              : layoverDetails?.bookingInfo?.luggageType === 'HAND_AND_1_CHECKED' 
                              ? '1 checked bag + hand-luggage'
                              : layoverDetails?.bookingInfo?.luggageType === 'HAND_AND_2_CHECKED'
                              ? '2 checked bags + hand-luggage'
                              : '-'
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item spacing={8}>
                        <Grid item xs={4}>
                          <DarkPara variant='body1'>BOOKED ROOMS</DarkPara>
                          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: theme.spacing(0.5) }}>
                            {roomsByType?.map(({ name, booked, singleOccupancy = 0 }, index) => {
                              const remainingRooms = booked - singleOccupancy;
                              return (
                                <Fragment key={index}>
                                  {name && (
                                    <Typography variant='body2' sx={{ lineHeight: theme.lineHeight[1] }}>
                                      {`${remainingRooms} ${name}${remainingRooms > 1 ? 's' : ''}`}
                                    </Typography>
                                  )}
                                  {name && singleOccupancy > 0 && (
                                    <Typography variant='body2' sx={{ lineHeight: theme.lineHeight[1] }}>
                                      {`${singleOccupancy} ${name}${singleOccupancy > 1 ? 's for single use' : ' for single use'}`}
                                    </Typography>
                                  )}
                                </Fragment>
                              );
                            })}
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <DarkPara variant='body1'>LATEST RETURN TO AIRPORT</DarkPara>
                          <Typography variant='body2'>
                            {layoverDetails?.bookingInfo?.isDifferentReturnTimes === 'YES'
                              ? 'Different return times'
                              : layoverDetails?.bookingInfo?.isDifferentReturnTimes === 'NO'
                              ? moment(layoverDetails?.bookingInfo?.passanger_airport_return_date).format('DD.MM.YY, ') + moment(layoverDetails?.bookingInfo?.passanger_airport_return_time, 'HH:mm:ss.SSS').format('hh:mm A')
                              : 'To be announced later'}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={0.5}></Grid>
                  {/* Grid item for the passenger info table */}
                  <Grid container item xs={12} md={6} component={Paper} 
                    sx={{ width: '100%', overflow: 'hidden', px: theme.spacing(1), py: theme.spacing(2), backgroundColor: theme.mode === 'light' && theme.palette.bg[400], borderRadius: theme.borderRadius[10] }}
                  >
                    <Box sx={{ overflow: 'auto', width: '100%' }}>
                      <Box sx={{ maxHeight: passengerInfoMainBoxHeight, width: 'fit-content', minWidth: '100%', display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: theme.spacing(2), pt: 2 }}>
                          <Box sx={{ width: passengerInfoMainColumnWidth }}>
                            <HeadingBar>Passenger info</HeadingBar>
                          </Box>
                          <Box sx={{ width: passengerInfoOtherColumnsWidth }}>
                            <DarkPara>PAX</DarkPara>
                          </Box>
                          <>
                            {layoverDetails?.passengers_info.roomsTotalByType?.map((room, index) => (
                              <>
                                {room?.name ?
                                  (<Box key={index} sx={{ width: passengerInfoOtherColumnsWidth }}>
                                    <DarkPara sx={{ textTransform: 'capitalize' }}>{room?.name}</DarkPara>
                                  </Box>)
                                : (null)}
                              </> 
                            ))}
                          </>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: theme.spacing(2) }}>
                          <Box sx={{ width: passengerInfoMainColumnWidth }}>
                            <Typography>Total Registered</Typography>
                          </Box>
                          <Box sx={{ width: passengerInfoOtherColumnsWidth }}>
                            <Typography
                              sx={{
                                fontSize: theme.typography.h3.fontSize,
                                display: 'inline-block',
                                color: theme.palette.text.secondary,
                              }}
                            >
                              {layoverDetails?.passengers_info?.registeredPassengers || 0}
                            </Typography>
                            <Typography
                              sx={{
                                display: 'inline-block',
                                color: theme.palette.text.secondary,
                                fontSize: theme.typography.h6.fontSize,
                              }}
                            >
                              /{layoverDetails?.passengers_info?.totalPassengers || 0}
                            </Typography>
                          </Box>
                          <>
                            {roomsByType?.map((room, index) => (
                              room.name ? 
                                (room?.total !== 0) ?
                                  (<Box key={index} sx={{ width: passengerInfoOtherColumnsWidth }}>
                                    <Typography
                                      sx={{
                                        fontSize: theme.typography.h3.fontSize,
                                        display: 'inline-block',
                                        color: theme.palette.text.secondary,
                                      }}
                                    >
                                      {room?.booked}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        display: 'inline-block',
                                        color: theme.palette.text.secondary,
                                        fontSize: theme.typography.h6.fontSize,
                                      }}
                                    >
                                      /{room?.total}
                                    </Typography>
                                  </Box>)
                                : (<Box key={index} sx={{ width: passengerInfoOtherColumnsWidth }}>
                                    <Typography
                                      sx={{
                                        fontSize: theme.typography.h3.fontSize,
                                        display: 'inline-block',
                                        color: theme.palette.text.secondary,
                                      }}
                                    >-</Typography>
                                  </Box>
                                )
                              : (null)
                            ))}
                          </>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          <Divider sx={{width: '100%'}} />
                        </Box>
                        {hotels?.map((hotel, index) => (
                          <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', gap: theme.spacing(2), height: passengerInfoOtherHotelRowHeight }}>
                            <Box sx={{ width: passengerInfoMainColumnWidth }}>
                              <Typography variant={'body2'}>{hotel?.name || 'Hotel Name'}</Typography>
                            </Box>
                            <Box sx={{ width: passengerInfoOtherColumnsWidth, pl: 1 }}>
                              <Typography variant={'body2'}>{hotel && hotel.passengers ? hotel.passengers : 0}</Typography>
                            </Box>
                            {roomsByType.map((roomType, typeIndex) => {
                              const matchedRoom = hotel?.roomsInfo?.find(room => roomType?.name === room?.name);
                              return (
                                <>
                                  {roomType?.name ? 
                                    (<Box key={typeIndex} sx={{ width: passengerInfoOtherColumnsWidth, pl: 1 }}>
                                      <Typography variant='body2' 
                                        sx={{ color: matchedRoom && matchedRoom?.booked !== 0 && matchedRoom?.total !== 0 && (matchedRoom?.booked === matchedRoom?.total) ? theme.palette.orange[300] : theme.palette.menu.dark }}
                                      >
                                        {(matchedRoom && matchedRoom?.total !== 0) ? `${matchedRoom?.booked}/${matchedRoom?.total}` : '-'}
                                      </Typography>
                                    </Box>)
                                  : (null)}
                                </>
                              );
                            })}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* HOTEL INFO */}
              <Box>
                <HeadingBar sx={{marginTop: theme.spacing(4)}}>Hotel info</HeadingBar>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell sx={{ width: '15%' }}>
                          CHAT
                        </StyledTableCell> */}
                        <StyledTableCell sx={{ width: '25%' }}>
                          HOTEL NAME
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: '25%', textAlign:'center' }}>
                          MEALS
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: '15%', textAlign:'center' }}>
                          TRANSPORT
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign:'center' }}>
                          Cost assumption documents
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{backgroundColor: theme.mode === 'light' && theme.palette.bg[400]}}>
                      {layoverDetails?.hotel_info_list?.map((hotel) => (
                        <TableRow key={hotel.id}>
                          {/* <TableCell sx={{ width: '15%' }}>
                            <StyledButton 
                              variant='outlined'
                              onClick={()=>{handleSendEmail(hotel.contact_email)}}>
                              <SmsRoundedIcon/>
                            </StyledButton>
                          </TableCell> */}
                          <TableCell sx={{ width: '25%' }}>
                            <Typography variant='body1'>{hotel.name}</Typography>
                            {/* <Typography variant='body2' 
                              sx={{ 
                                marginTop: theme.spacing(0.4), 
                                ...theme.typography.textxxSmall,
                                textTransform: 'uppercase',
                                color: theme.palette.grey[800],
                              }}
                            >{hotel.contact_phone}</Typography> */}
                          </TableCell>
                          <TableCell sx={{ width: '25%', textAlign:'center' }}>
                            {(typeof hotel.meals !== 'object' || (hotel.meals.isBreakfast === false && hotel.meals.isLunch === false && hotel.meals.isDinner === false && hotel.meals.isEarlyBirdBreakfast === false && hotel.meals.isSnacks === false && hotel.meals.isCroowyDinner === false && hotel.meals.isCroowyLunch === false))
                              ? '-'
                              :  [
                                hotel.meals.isEarlyBirdBreakfast && 'Early bird breakfast',
                                hotel.meals.isBreakfast && 'Breakfast',
                                hotel.meals.isLunch && 'Lunch',
                                hotel.meals.isDinner && 'Dinner',
                                hotel.meals.isSnacks && 'Snacks',
                                hotel.meals.isCroowyDinner && 'Dinner (Organized by Croowy)',
                                hotel.meals.isCroowyLunch && 'Lunch (Organized by Croowy)',
                              ].filter(Boolean).join(', ')
                            }
                          </TableCell>
                          <TableCell sx={{ width: '15%', textAlign:'center' }}>
                            {hotel.transportation}
                          </TableCell>
                          <TableCell sx={{textAlign:'center'}}>
                            <Link 
                              to={`/layover-detail/${layoverDetails?.layoverId}/hotel/${hotel?.id}/cost`} 
                              style={{
                                color: theme.palette.primary.light,
                                textDecoration: 'underline',
                                width: '15%',
                              }}
                            >
                              {`${hotel.name}_cost_assumption.pdf`}
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* ADDITIONAL BOOKED TRANSAPORTATION */}
              <Box>
                <HeadingBar sx={{marginTop: theme.spacing(4)}}>Additional Booked Transportation</HeadingBar>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650, backgroundColor: theme.mode === 'light' && theme.palette.bg[400] }}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ width: '20%' }}>Contact</StyledTableCell>
                        <StyledTableCell sx={{ width: '25%', textAlign:'center' }}>Company Name</StyledTableCell>
                        <StyledTableCell sx={{ width: '25%', textAlign:'center' }}>Transportation Type</StyledTableCell>
                        <StyledTableCell sx={{ width: '15%', textAlign:'center' }}>Number of Passengers</StyledTableCell>
                        <StyledTableCell sx={{ textAlign:'center' }}>Cost assumption documents</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Box sx={{ width: '100%', minHeight: '10vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <LargeHeading title={layoverDetails?.additionally_booked_transportation} sx={{...theme.typography.fontMedium500}} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* ADDITIONAL BOOKED FOOD AND BEVERAGES */}
              <Box>
                <HeadingBar sx={{marginTop: theme.spacing(4)}}>Additional Booked Food and Beverages</HeadingBar>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650, backgroundColor: theme.mode === 'light' && theme.palette.bg[400] }}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ width: '25%' }}>Company Name</StyledTableCell>
                        <StyledTableCell sx={{ width: '25%', textAlign:'center' }}>Number of Passengers</StyledTableCell>
                        <StyledTableCell sx={{ width: '25%', textAlign:'center' }}>Allowance per passengers</StyledTableCell>
                        <StyledTableCell sx={{ textAlign:'center' }}>Total costs</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Box sx={{ width: '100%', minHeight: '10vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <LargeHeading title={layoverDetails?.['additionally_booked_food_beverage']} sx={{...theme.typography.fontMedium500}} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Passenger List */}
              <Box>
                <HeadingBar sx={{marginTop: theme.spacing(4)}}>Passenger List</HeadingBar>
                <Box>
                  {/* Search input */}
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid item xs={12} sm={12} md={5} sx={{mb:2}}>
                      <Controller
                        name='searchInput'
                        control={control}
                        render={({ field }) => (
                          <Input
                            placeholder='Search'
                            formControlProps={{
                              sx: { marginBottom: 0 },
                            }}
                            sx={{
                              backgroundColor: theme.mode === 'light' && theme.palette.bg[400]
                            }}
                            inputRef={field.ref}
                            autoFocus
                            {...field}
                            onChange={handleSearchChange}
                            endAdornment={
                              <InputEndAdornment
                                position='end'
                                color={theme.palette.primary.light}
                              >
                                <SearchIcon />
                              </InputEndAdornment>
                            }
                          />
                        )}
                      />
                    </Grid>

                    <Stack direction='row' spacing={4} alignItems='center'>
                      <Box sx={{display: 'flex'}}>
                        <Dropdown
                          options={[
                            {
                              key: 1,
                              name: 'Download as Excel',
                              onClickOption: convertPassengerDataXlsx
                            },
                            {
                              key: 2,
                              name: 'Download as CSV',
                              onClickOption: convertPassengerDataCSV
                            },
                          ]}
                          isReadOnly={filteredPassengers?.length <= 0}
                          text={
                            <Box sx={{
                              ...theme.typography.textLarge,
                              display: 'flex',
                              alignItems: 'center',
                              opacity: filteredPassengers?.length > 0 ? 1 : .65,
                              color: filteredPassengers?.length > 0 ? theme.palette.roseGold[100] : theme.palette.text.disabled,
                            }}>
                              <GetAppIcon fontSize='small' sx={{mr: .5}}/>
                              Download passenger list
                            </Box>
                          }
                        />
                        <RoseGoldButton
                          disabled={filteredPassengers?.length > 0 ? false : true}
                          variant='text'
                          onClick={()=>{setMailToDialogOpen(true)}}
                          sx={{mr:2}}
                          startIcon={<EmailIcon />}>
                          Send mail to all
                        </RoseGoldButton>

                        <RoseGoldButton variant='text' sx={{mr:1}} onClick={handleAddPassenger}>
                          + Add Passenger
                        </RoseGoldButton>
                      </Box>
                    </Stack>
                  </Stack>

                  {/* Passenger list table */}
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell sx={{width: '20%'}}>PASSENGER INFO</StyledTableCell>
                          <StyledTableCell sx={{width: '20%', textAlign:'center'}}>HOTEL NAME</StyledTableCell>
                          <StyledTableCell sx={{width: '10%', textAlign:'center'}}>LAYOVER CODE</StyledTableCell>
                          <StyledTableCell sx={{width: '15%', textAlign:'center'}}>CHECK-IN</StyledTableCell>
                          <StyledTableCell sx={{width: '15%', textAlign:'center'}}>CHECK-OUT</StyledTableCell>
                          <StyledTableCell sx={{width: '10%', textAlign:'center'}}>PREFERRED LANGUAGE</StyledTableCell>
                          <StyledTableCell sx={{textAlign:'center'}}>STATUS</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredPassengers?.map((passenger, index) => (
                          <StyledTableRow key={index}>
                            <CustomTableCell 
                              sx={{
                                width: '20%', 
                                textDecoration: passenger?.status === 'CONFIRMED' ? '' : 'line-through', 
                                color: passenger?.status === 'CONFIRMED' ? theme.palette.menu.dark : theme.palette.red[100],
                                textTransform: 'capitalize'
                              }}
                            >
                              {passenger?.coPassengers?.sort((a, b) => (a.isLead === b.isLead ? 0 : a.isLead ? -1 : 1))?.map((coPassenger, index) => (
                                <Box key={index}>
                                  {coPassenger?.isLead ? (
                                    <Box>
                                      {/* <CallIcon /> */}{`${coPassenger?.first_name} ${coPassenger?.last_name}`}
                                      <br />
                                    </Box>
                                  ) : (
                                    <Box className='passenger-info'>
                                      <CustomDivider orientation='vertical' flexItem />
                                      <CustomBox sx={{color: passenger?.status === 'CONFIRMED' ? theme.palette.menu.dark : theme.palette.red[100], textTransform: 'capitalize'}}>
                                        {`${coPassenger?.first_name} ${coPassenger?.last_name}`}
                                        <br />
                                      </CustomBox>
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </CustomTableCell>
                            <TableCell sx={{width: '20%', textAlign:'center'}}>
                              {passenger?.coPassengers?.map((coPassenger, index) => (
                                <Box key={index} sx={{textAlign: 'start', mb: theme.spacing(0.8)}}>{coPassenger.hotelName}</Box>
                              ))}
                            </TableCell>
                            <TableCell sx={{width: '10%', textAlign:'center'}}>{layoverDetails?.layoverCode}</TableCell>
                            <TableCell sx={{width: '15%', textAlign:'center'}}>{passenger?.checkInTime}</TableCell>
                            <TableCell sx={{width: '15%', textAlign:'center'}}>{passenger?.checkOutTime}</TableCell>
                            <TableCell sx={{width: '10%', textAlign:'center'}}>{getLanguageByCode(passenger?.preferredLanguageCode)}</TableCell>
                            <TableCell sx={{textAlign:'center'}}>
                              <Chip
                                label={`${passenger?.status === 'CONFIRMED' ? 'CONFIRMED' : 'CANCELLED'}`}
                                color={passenger?.status === 'CONFIRMED' ? 'success' : 'error'}
                                sx={{height:'auto', py:.5 , color: theme.mode === 'light' ? theme.palette.common.white : 'default'}}
                              />
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>

            <MailToAllPassenger
              open={mailToDialogOpen}
              onAgree={() => setMailToDialogOpen(false)}
              onDisagree={() => setMailToDialogOpen(false)}
              layoverId={layoverId}
              airlineId={airlineId}
            />
          </Box>)
          : (<Box sx={{display:'flex', justifyContent:'center', pt: 40}}><HeadingInput title='No Layover Details Found' /></Box>)
      }
    </Box>
  )
};

export default Layovers;

const QrDocument = ({image, airlineName, addPassengerLink}) => {
  return(
    <Document>
      <Page size='A4' style={styles.page}>
        <Text style={styles.airlineName}>{airlineName}</Text>
        <Image 
          style={styles.image}
          src={image}
          cache={false} allowDangerousPaths={true}
        />
        <PdfLink style={styles.addPassengerLink} src={addPassengerLink}>{addPassengerLink}</PdfLink>
      </Page>
    </Document>
  )
};

import { useEffect, useRef, useState, Fragment } from 'react';
import { Box, Button, Grid, Typography, styled, useTheme } from '@mui/material';
import moment from 'moment';
import formatPrice from '../../../../utils/formatPrice';

const StyledBtn = styled(Button, {
  shouldForwardProp: (prop) => ['isActive'].indexOf(prop) === -1
})(({ theme, isActive }) => ({
  color: isActive ? theme.palette.primary.light : theme.palette.grey[800],
  minWidth: 'unset',
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  '& .tab-label': {
    borderBottom: isActive ? '1px solid' : 0,
  }
}));

const StyledBtnName = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600
}));

const StyledTabItemLabel = styled(Typography, {
  shouldForwardProp: (prop) => ['error'].indexOf(prop) === -1
})(({ theme, error }) => ({
  ...theme.typography.textxSmall,
  color: error ? theme.palette.roseGold[100] : theme.palette.text.primary
}));

const StyledTabItemValue = styled(Typography, {
  shouldForwardProp: (prop) => ['error'].indexOf(prop) === -1
})(({ theme, error }) => ({
  ...theme.typography.textMedium,
  ...theme.typography.fontMedium600,
  color: error ? theme.palette.roseGold[100] : theme.palette.text.primary
}));

const StyledTabItemValueText = styled(Typography, {
  shouldForwardProp: (prop) => ['error'].indexOf(prop) === -1
})(({ theme, error }) => ({
  ...theme.typography.textxxSmall,
  color: error ? theme.palette.roseGold[100] : theme.palette.text.primary
}));

const HotelCardCollapse = (props) => {
  const { hotel, getCurrencySymbol, isOpen, weekdayMapping, today, todaySchedulesForHTOA, todaySchedulesForATOH } = props;
  const observerRefs = useRef([]);
  const theme = useTheme()
  const observerParentRef = useRef(null);
  const [visibleKey, setVisibleKey] = useState('');
  const refKeys = {
    ROOMS: 'rooms',
    MEALS: 'meals',
    BOOKINGS: 'bookings',
    SHUTTLES: 'shuttles'
  };

  useEffect(() => {
    // observerRefs.current = observerRefs.current.slice(0, 2);
    handleBtnClickInCollapse(refKeys.ROOMS)
  }, [refKeys.ROOMS, isOpen]);
  useEffect(() => {
    const element = observerParentRef.current;
    const scrollPositions = []
    const scrollPositionsKeys = {}
    for (const key in observerRefs.current) {
      scrollPositions.push(observerRefs.current[key].offsetTop)
      scrollPositionsKeys[observerRefs.current[key].offsetTop] = key;
    }
    const getClosestValueInArray = (arr, val) => {
      let i = 0;
      while(arr[++i] < val);
      return arr[--i];
    };
    const handleScroll = (e) => {
      let closestTop = getClosestValueInArray(scrollPositions, (e.target.scrollTop + 41));
      if (scrollPositionsKeys?.[closestTop] && scrollPositionsKeys[closestTop] !== visibleKey) {
        setVisibleKey(scrollPositionsKeys[closestTop])
      }
    };
    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);
  }, [observerParentRef, visibleKey]);

  const handleBtnClickInCollapse = (key) => {
    setVisibleKey(key);
    observerParentRef.current.scrollTo({
      top: (observerRefs.current[key].offsetTop - 40),
      behavior: 'smooth',
    });
  };

  return (
    <Box sx={{py:1, px:1, height:'100%'}}>
      <Box>
        <StyledBtn onClick={() => handleBtnClickInCollapse(refKeys.ROOMS)} isActive={visibleKey === refKeys.ROOMS}>
          <StyledBtnName className='tab-label'>Rooms</StyledBtnName>
        </StyledBtn>
        <StyledBtn onClick={() => handleBtnClickInCollapse(refKeys.MEALS)} isActive={visibleKey === refKeys.MEALS}>
          <StyledBtnName className='tab-label'>Meals</StyledBtnName>
        </StyledBtn>
        <StyledBtn onClick={() => handleBtnClickInCollapse(refKeys.BOOKINGS)} isActive={visibleKey === refKeys.BOOKINGS}>
          <StyledBtnName className='tab-label'>Bookings</StyledBtnName>
        </StyledBtn>
        {todaySchedulesForATOH?.length > 0 ? (
          <StyledBtn onClick={() => handleBtnClickInCollapse(refKeys.SHUTTLES)} isActive={visibleKey === refKeys.SHUTTLES}>
            <StyledBtnName className='tab-label'>Shuttle</StyledBtnName>
          </StyledBtn>
        ) : (null)}
      </Box>
      <Box ref={observerParentRef} sx={{height:'calc(100% - 40px)', overflow: 'auto'}}>
        <Box ref={(el) => (observerRefs.current[refKeys.ROOMS] = el)} sx={{minHeight:'100%'}}>
          <Grid container sx={{px:1, pb:3}}>
            {(hotel?.roomTypes && hotel.roomTypes.length) ? (
              (hotel.roomTypes).map((room, index) => (
                <Grid item xs={12} key={`ht-c-r-${index}`}>
                  <Grid container sx={{alignItems: 'center'}}>
                    <Grid item xs={6} sx={{py:1}}>
                      <StyledTabItemLabel error={!room?.price}>
                        {room.roomTypeName} ({room.available_rooms ? room.available_rooms : 0})
                      </StyledTabItemLabel>
                    </Grid>
                    <Grid item xs={6} sx={{py:1}}>
                      <StyledTabItemValue error={!room?.price}>
                        {room?.price ? `${getCurrencySymbol(hotel)}${formatPrice(room.price)}/room` : 'Not Available'}
                      </StyledTabItemValue>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            ) : (null)}
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel>
                    No Show rooms not charged
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6}>
                  <StyledTabItemValue>
                    {hotel?.no_show?.no_show_no_rooms_not_charged_percent ? hotel.no_show.no_show_no_rooms_not_charged_percent : 0}%
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel>
                    No Show fee (rooms)
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue>
                    {hotel?.no_show?.no_show_penalty_percent ? hotel.no_show.no_show_penalty_percent : 0}%
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel>
                    No Show fee (meals)
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue>
                    {hotel?.no_show?.unused_meal_percent ? hotel.no_show.unused_meal_percent : 0}%
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!hotel?.no_show?.is_room_for_disabled}>
                    Handicapped Rooms
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!hotel?.no_show?.is_room_for_disabled}>
                    {hotel?.no_show?.is_room_for_disabled ? 'Available' : 'Not Available'}
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box ref={(el) => (observerRefs.current[refKeys.MEALS] = el)} sx={{minHeight:'100%'}}>
          <Grid container sx={{px:1, pb:3}}>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!(hotel?.meals?.is_early_bird_breakfast && hotel?.meals?.early_bird_breakfast_price)}>
                    Early Bird Breakfast
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!(hotel?.meals?.is_early_bird_breakfast && hotel?.meals?.early_bird_breakfast_price)}>
                    {hotel?.meals?.early_bird_breakfast_price && hotel?.meals?.is_early_bird_breakfast ? `${getCurrencySymbol(hotel)}${formatPrice(hotel.meals.early_bird_breakfast_price)}` : 'Not Available'}
                  </StyledTabItemValue>
                  <StyledTabItemValueText>
                    {hotel?.meals?.early_bird_breakfast_from && hotel?.meals?.early_bird_breakfast_to && hotel?.meals?.is_early_bird_breakfast && hotel?.meals?.early_bird_breakfast_price ? `${moment(hotel?.meals?.early_bird_breakfast_from, 'HH:mm').format('HH:mm')} - ${moment(hotel?.meals?.early_bird_breakfast_to, 'HH:mm').format('HH:mm')}` : null}
                  </StyledTabItemValueText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!hotel?.meals?.is_breakfast}>
                    Breakfast
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!hotel?.meals?.is_breakfast}>
                    {hotel?.meals?.is_breakfast && hotel?.meals?.breakfast_price === 0 
                      ? 'Available' 
                      : hotel?.meals?.is_breakfast && hotel?.meals?.breakfast_price > 0 ? `${getCurrencySymbol(hotel)}${formatPrice(hotel.meals.breakfast_price)}` : 'Not Available'}
                  </StyledTabItemValue>
                  <StyledTabItemValueText>
                    {hotel?.meals?.breakfast_from && hotel?.meals?.breakfast_to && hotel?.meals?.is_breakfast && hotel?.meals?.breakfast_price ? `${moment(hotel?.meals?.breakfast_from, 'HH:mm').format('HH:mm')} - ${moment(hotel?.meals?.breakfast_to, 'HH:mm').format('HH:mm')}` : null}
                  </StyledTabItemValueText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!(hotel?.meals?.is_lunch && hotel?.meals?.lunch_price)}>
                    Lunch
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!(hotel?.meals?.is_lunch && hotel?.meals?.lunch_price)}>
                    {hotel?.meals?.lunch_price && hotel?.meals?.is_lunch ? `${getCurrencySymbol(hotel)}${formatPrice(hotel.meals.lunch_price)}` : 'Not Available'}
                  </StyledTabItemValue>
                  <StyledTabItemValueText>
                    {hotel?.meals?.lunch_from && hotel?.meals?.lunch_to && hotel?.meals?.is_lunch && hotel?.meals?.lunch_price  ? `${moment(hotel?.meals?.lunch_from, 'HH:mm').format('HH:mm')} - ${moment(hotel?.meals?.lunch_to, 'HH:mm').format('HH:mm')}` : null}
                  </StyledTabItemValueText>
                  <StyledTabItemValueText>
                    {hotel?.meals?.lunch_max_people && hotel?.meals?.is_lunch && hotel?.meals?.lunch_price ? `(max. ${hotel?.meals?.lunch_max_people} ppl.)`  : null}
                  </StyledTabItemValueText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!(hotel?.meals?.is_dinner && hotel?.meals?.dinner_price)}>
                    Dinner
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!(hotel?.meals?.is_dinner && hotel?.meals?.dinner_price)}>
                    {hotel?.meals?.dinner_price && hotel?.meals?.is_dinner ? `${getCurrencySymbol(hotel)}${formatPrice(hotel.meals.dinner_price)}` : 'Not Available'}
                  </StyledTabItemValue>
                  <StyledTabItemValueText>
                    {hotel?.meals?.dinner_from && hotel?.meals?.dinner_to && hotel?.meals?.is_dinner && hotel?.meals?.dinner_price ? `${moment(hotel.meals.dinner_from, 'HH:mm').format('HH:mm')} - ${moment(hotel.meals.dinner_to, 'HH:mm').format('HH:mm')}`  : null}
                  </StyledTabItemValueText>
                  <StyledTabItemValueText>
                    {hotel?.meals?.is_dinner && hotel?.meals?.dinner_price && hotel?.meals?.dinner_max_people ? `(max. ${hotel?.meals?.dinner_max_people} ppl.)`  : null}
                  </StyledTabItemValueText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!(hotel?.meals?.is_late_dinner && hotel?.meals?.late_dinner_price)}>
                    Late Dinner
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!(hotel?.meals?.is_late_dinner && hotel?.meals?.late_dinner_price)}>
                    {hotel?.meals?.late_dinner_price && hotel?.meals?.is_late_dinner ? `${getCurrencySymbol(hotel)}${formatPrice(hotel.meals.late_dinner_price)}` : 'Not Available'}
                  </StyledTabItemValue>
                  <StyledTabItemValueText>
                    {hotel?.meals?.late_dinner_from && hotel?.meals?.late_dinner_to && hotel?.meals?.is_late_dinner && hotel?.meals?.late_dinner_price ? `${moment(hotel?.meals?.late_dinner_from, 'HH:mm').format('HH:mm')} - ${moment(hotel?.meals?.late_dinner_to, 'HH:mm').format('HH:mm')}` : null}
                  </StyledTabItemValueText>
                  <StyledTabItemValueText>
                    {hotel?.meals?.is_late_dinner && hotel?.meals?.late_dinner_price && hotel?.meals?.late_dinner_min_people && hotel?.meals?.late_dinner_max_people ? `(min. ${hotel?.meals?.late_dinner_min_people} & max ${hotel?.meals?.late_dinner_max_people} ppl.)`  : null}
                  </StyledTabItemValueText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!(hotel?.meals?.is_snacks && hotel?.meals?.snacks_price)}>
                    Snacks
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!(hotel?.meals?.is_snacks && hotel?.meals?.snacks_price)}>
                    {hotel?.meals?.snacks_price && hotel?.meals?.is_snacks ? `${getCurrencySymbol(hotel)}${formatPrice(hotel.meals.snacks_price)}` : 'Not Available'}
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!(hotel?.meals?.available_foods?.length > 0)}>
                    Special meals
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!(hotel?.meals?.available_foods?.length > 0)}>
                    {hotel?.meals?.available_foods?.length > 0 ? hotel?.meals?.available_foods?.join(', ') : 'Not Available'}
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box ref={(el) => (observerRefs.current[refKeys.BOOKINGS] = el)} sx={{minHeight:'100%'}}>
          <Grid container sx={{px:1, pb:3}}>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel>
                    Check-in
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue>
                    {hotel?.timings?.checkin_time ? `From ${moment(hotel.timings.checkin_time, 'HH:mm').format('HH:mm')}` : '--:--'}
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel>
                    Check-out
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue>
                    {hotel?.timings?.checkout_time ? `Until ${moment(hotel.timings.checkout_time, 'HH:mm').format('HH:mm')}` : '--:--'}
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!(hotel?.timings?.early_checkin_from && hotel?.timings?.early_checkin_to)}>
                    Early Check-in
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!(hotel?.timings?.early_checkin_from && hotel?.timings?.early_checkin_to)}>
                    {(hotel?.timings?.early_checkin_from && hotel?.timings?.early_checkin_to) ? `${moment(hotel.timings.early_checkin_from, 'HH:mm').format('HH:mm')} to ${moment(hotel.timings.early_checkin_to, 'HH:mm').format('HH:mm')}${hotel?.timings?.early_checkin_extra_cost ? ` at ${getCurrencySymbol(hotel)}${hotel.timings.early_checkin_extra_cost}` : ''}` : 'Not Available'}
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!(hotel?.timings?.late_checkout_from && hotel?.timings?.late_checkout_to)}>
                    Late Check-out
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!(hotel?.timings?.late_checkout_from && hotel?.timings?.late_checkout_to)}>
                    {(hotel?.timings?.late_checkout_from && hotel?.timings?.late_checkout_to) ? `${moment(hotel.timings.late_checkout_from, 'HH:mm').format('HH:mm')} to ${moment(hotel.timings.late_checkout_to, 'HH:mm').format('HH:mm')}${hotel?.timings?.late_checkout_extra_cost ? ` at ${getCurrencySymbol(hotel)}${hotel.timings.late_checkout_extra_cost}` : ''}` : 'Not Available'}
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!hotel?.no_show?.is_day_stay_allowed}>
                    Day Stay
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!hotel?.no_show?.is_day_stay_allowed}>
                    {hotel?.no_show?.is_day_stay_allowed ? `${(hotel?.timings?.day_stay_from && hotel?.timings?.day_stay_to) ? `${moment(hotel.timings.day_stay_from, 'HH:mm').format('HH:mm')} to ${moment(hotel.timings.day_stay_to, 'HH:mm').format('HH:mm')}${hotel?.no_show?.day_stay_discount_percent ? ` at ${hotel?.no_show?.day_stay_discount_percent}% less` : ''}` : 'Allowed'}` : 'Not Allowed'}
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!hotel?.no_show?.max_self_paid_passangers}>
                    Self-Paying Passengers
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!hotel?.no_show?.max_self_paid_passangers}>
                    {hotel?.no_show?.max_self_paid_passangers ? `${hotel.no_show.max_self_paid_passangers} people` : 'Not Allowed'}
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{alignItems: 'center'}}>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemLabel error={!hotel?.is_shuttle_service}>
                    Transportation Service
                  </StyledTabItemLabel>
                </Grid>
                <Grid item xs={6} sx={{py:1}}>
                  <StyledTabItemValue error={!hotel?.is_shuttle_service}>
                    {hotel?.is_shuttle_service ? 'Available' : 'Not Available'}
                  </StyledTabItemValue>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {todaySchedulesForHTOA?.length > 0 || todaySchedulesForATOH?.length > 0 ? (
          <Box ref={(el) => (observerRefs.current[refKeys.SHUTTLES] = el)} sx={{minHeight:'100%'}}>
            <Grid container sx={{px:1, pb:3}} spacing={1.5}>
              {/* Shuttles content here */}
              {todaySchedulesForATOH?.length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <Typography align='center' sx={{...theme.typography.subtitle2, ...theme.typography.fontSemiBold500}}>Terminal - Hotel</Typography>
                  </Grid>
                  {todaySchedulesForATOH?.map((terminal, i) =>(
                    <Fragment key={`atoh-${i}`}>
                      <Grid item xs={12}>
                        <Typography sx={{color: theme.palette.grey[800]}}>{terminal.airport_terminal_name}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component='span' sx={{...theme.typography.body2, ...theme.typography.fontSemiBold500, mr: 1}}>Day:</Typography> 
                        {weekdayMapping[today]}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component='span' sx={{...theme.typography.body2, ...theme.typography.fontSemiBold500, mr: 1}}>Time:</Typography> 
                        {terminal.time.map(time => moment(time, 'HH:mm').format('hh:mm a')).join(', ')}
                      </Grid>
                    </Fragment>
                  ))}
                </>
              ):(
                <Grid item>
                  <Typography
                    align='center'
                    sx={{ ...theme.typography.subtitle2, ...theme.typography.fontSemiBold500, mb: 1  }}
                  >
                    There is no shuttle service for Terminal to Hotel today.
                  </Typography>
                </Grid>
              )}
              {todaySchedulesForHTOA?.length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <Typography align='center' sx={{...theme.typography.subtitle2, ...theme.typography.fontSemiBold500}}>Hotel - Terminal</Typography>
                  </Grid>
                  {todaySchedulesForHTOA?.map((terminal, i) =>(
                    <Fragment key={`htoa-${i}`}>
                      <Grid item xs={12}>
                        <Typography sx={{color: theme.palette.grey[800]}}>{terminal.airport_terminal_name}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component='span' sx={{...theme.typography.body2, ...theme.typography.fontSemiBold500, mr: 1}}>Day:</Typography> 
                        {weekdayMapping[today]}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component='span' sx={{...theme.typography.body2, ...theme.typography.fontSemiBold500, mr: 1}}>Time:</Typography> 
                        {terminal.time.map(time => moment(time, 'HH:mm').format('hh:mm a')).join(', ')}
                      </Grid>
                    </Fragment>
                  ))}
                </>
              ):(
                <Grid item>
                  <Typography
                    align='center'
                    sx={{ ...theme.typography.subtitle2, ...theme.typography.fontSemiBold500, mb: 1  }}
                  >
                    There is no shuttle service for Hotel to Terminal today.
                  </Typography>
                </Grid>
              )}
            </Grid>
            {hotel?.shuttle_departure_point ? 
              <Grid item>
                <Typography sx={{ ...theme.typography.subtitle2, ...theme.typography.fontSemiBold500, mb: 1, ml: 1 }}>
                  Shuttle Departure Point:
                </Typography>
                <Typography sx={{ ...theme.typography.body2, mb: 1, ml: 1, whiteSpace: 'pre-wrap' }}>
                  {hotel?.shuttle_departure_point}
                </Typography>
              </Grid>
            : ''}
          </Box>
        ):(null)}
      </Box>
    </Box>
  );
};

export default HotelCardCollapse;

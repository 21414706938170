import { memo, useState } from 'react';
import { Box, Typography, styled, Grid, useTheme, IconButton } from '@mui/material';
import { Input } from '..';
import { HeadingInput } from '../../pages/hotel/components/headingInput';
import { prepareImageUrlByPath } from '../../utils';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { maxToastTime, toastMessages } from '../../helper/constant';
import { isMobile, isTablet } from 'react-device-detect';

const StyledFileInputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderWidth: '2px',
  borderRadius: theme.borderRadius[2],
  borderColor: theme.mode === 'light'? theme.palette.sidebarBg :theme.palette.grey[100],
  borderStyle: 'dashed',
  backgroundColor: theme.mode === 'light'? theme.palette.grey[200] : theme.palette.grey[800],
  color: theme.palette.primary.light,
  outline: 'none', 
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  position: 'relative',
  transition: 'border .24s ease-in-out',
}));

const StyledFileInput = styled(Input)(({ theme }) => ({
  cursor: 'pointer',
  opacity: 0,
  '& input': {
    cursor: 'pointer',
    opacity: 0,
    padding: '0 !important',
    width: '100%',
    height: '100%',
    zIndex: 1
  }
}));

const StyledThumbsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}));

const StyledThumb = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  position: 'relative', 
  borderRadius: theme.borderRadius[2],
  border: `1px solid ${theme.mode === 'light' ? theme.palette.primary.light : theme.palette.grey['A200']}`,
  width: isMobile || isTablet ? '48%' : '',
  height: '120px',
  padding: theme.spacing(1),
  boxSizing: 'border-box',
}));

const StyledThumbInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
}));

const StyledParentBoxForImgs = styled(Box)(({ theme }) => ({
  borderRadius: theme.borderRadius[2],
  border: `1px solid ${theme.mode === 'light' ? theme.palette.primary.light : theme.palette.grey['A200']}`,
  marginTop: theme.spacing(2),
  padding: theme.spacing(1),
  marginRight: theme.spacing(2),
}));

const StyledDeleteIcon = styled(IconButton)(({ theme }) => ({
  padding: 0,
  position: 'absolute',
  top: -10, 
  right: -10,
  cursor: 'pointer',
  color: theme.mode === 'light' && theme.palette.primary.light
}));

const ImageUpload = (props) => {
  const theme = useTheme();
  const { label, isPreview, alreadyuploadedimages, onRemoveUploadedImage, uploadedImages, onDeleteNewlyUploadedImage, setValue, ...otherProps } = props;
  const [files, setFiles] = useState([]);

  const onFileChange = async (e) => {
    if (isPreview && e?.target?.files && e.target.files.length > 0) {
      const acceptedFiles = Array.from(e.target.files);
      let tempFilesArr = [];
      for (const file of acceptedFiles) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        await new Promise((resolve) => {
          img.onload = () => {
            if (img.width >= 750 && img.height >= 600) {
              file.preview = URL.createObjectURL(file)
              tempFilesArr.push(file);  // always, dont handle file object as a normal object 
            } else {
              toast.error(toastMessages.imageDimensionError, {
                autoClose: maxToastTime,
                toastId: 'edit-hotel-image-wrong-dimensions-error',
              });
            }
            resolve();
          };
        });
      }
      if (tempFilesArr.length > 0) {
        setFiles(prevFiles => [...prevFiles, ...tempFilesArr]);
        props.onChange({target: {files: [...files, ...tempFilesArr]}}); // it will keep the sync between State value and Formstate
      }
    }
  };

  const handleDelete = async (index, imageId) => {
    onRemoveUploadedImage(index, imageId);
  };

  const handleDeleteForNewlyUploadedImage = async (indexProp) => {
    const filteredArray = files?.filter((file, index) => index !== indexProp);
    setFiles(filteredArray); 
    props.onChange({target: {files: [...filteredArray]}}); // it will keep the sync between State value and Formstate
  };

  return (
    <Box>
      <StyledFileInputWrapper>
        <StyledFileInput
          formControlProps={{
            sx: {
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              marginBottom: 0,
              '& .MuiInputBase-root': {
                height: '100%'
              }
            }
          }}
          {...props}
          inputProps={{
            type: 'file',
            accept: 'image/png, image/jpeg, image/jpg',
            ...props.inputProps
          }}
          onChange={(e) => {
            onFileChange(e);
          }}
        />
        <Typography variant='h6'>
          {label ? label : `Drag 'n' drop some images here, or click to select images`}
        </Typography>
      </StyledFileInputWrapper>

      <Grid xs={12} container sx={{ mt:2 }}>
        {alreadyuploadedimages && alreadyuploadedimages.length > 0 ? 
          <Box style={{ maxWidth: '100%' }}>
            <HeadingInput title='Already uploaded images' />
          
            <StyledParentBoxForImgs>
              <StyledThumbsContainer gap={1}>
                {alreadyuploadedimages.map((image, index) => (
                  <StyledThumb key={`fiu-${index}`}>
                    <StyledThumbInner>
                      {image ? (
                        <>
                          <img loading='lazy' src={process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(image?.url) : image?.url} alt='hotel' />
                          <StyledDeleteIcon aria-label='Remove' onClick={() => handleDelete(index, image?.id)}><CloseIcon /></StyledDeleteIcon>
                        </>
                      ) : (null)}
                    </StyledThumbInner>
                  </StyledThumb>
                ))}
              </StyledThumbsContainer>
            </StyledParentBoxForImgs>
          </Box>
        : null}
        
        {isPreview && files && files.length > 0 ? (
          <Grid xs={6} sx={{ marginTop: theme.spacing(5) }}>
              <StyledThumbsContainer gap={1}>
                {files?.map((file, index) => (
                    <StyledThumb key={`fiu-${index}`}>
                      <StyledThumbInner>
                        <img
                          src={file?.preview}
                          style={{
                            width: 'auto',
                            height: '100%'
                          }}
                        />
                        <StyledDeleteIcon aria-label='Remove' onClick={() => handleDeleteForNewlyUploadedImage(index, file)}><CloseIcon /></StyledDeleteIcon>
                      </StyledThumbInner>
                    </StyledThumb>
                  ))}
              </StyledThumbsContainer>
          </Grid>
        ) : (null)}
      </Grid>
    </Box>
  );
}

export default memo(ImageUpload);

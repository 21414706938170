import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, styled, Typography, useTheme } from '@mui/material';
import { HotelContext } from '../../../context/hotel';
import { SubHeading } from '../components/heading';
import { symbol as getCurrencySymbol } from 'better-currency-codes';
import BookingsTable from './bookingsTable';
import WidgetCard from './widgetCard';
import { useLoader } from '../../../providers/loader';
import { getHotelDashboard } from '../../../services/hotel';
import { AlertDialog } from '../../../components';
import { maxToastTime, toastMessages } from '../../../helper/constant';
import { toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { isMobile, isTablet } from 'react-device-detect';
import formatPrice from '../../../utils/formatPrice';

const StyledButton = styled(Button)(({ theme }) => ({
  ...theme.typography.textMedium,
  textTransform: 'capitalize',
  textDecoration: 'underline', 
  color: theme.palette.primary.light,
  borderRadius: theme.spacing(.75),
  borderColor: theme.palette.primary.light,
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  marginLeft: theme.spacing(1),
}));

const StyledMandatoryInfoTypography = styled(Typography, {
  shouldForwardProp: (prop) => ['isIncomplete'].indexOf(prop) === -1
})(({ theme, isIncomplete = true }) => ({
  ...theme.typography.textLarge,
  ...theme.typography.fontMedium500,
  color: isIncomplete ? theme.palette.text.primary : theme.palette.success.main,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    color: isIncomplete ? theme.palette.grey[700] : theme.palette.success.main,
  },
  '& a': {
    paddingRight: theme.spacing(.5),
    paddingLeft: theme.spacing(.5),
  }
}));

const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { hotel, setIsCommonTopbar, onChangeLayoverBookingSwitch, setIsBookingsOpen, fetchLastAccessedHotelDetails } = useContext(HotelContext);
  const { startLoader, stopLoader } = useLoader();
  const [dashboardData, setDashboardData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mandatoryInfoErrorKeys, setMandatoryInfoErrorKeys] = useState([]);

  useEffect(() => {
    setIsCommonTopbar(false);
  }, [setIsCommonTopbar])

  /**
   * Get currency symbol
   * 
   * @param {*} currency   Currency Object
   */
  const getHotelCurrencySymbol = (currency) => {
    let tempCurrencySymbol = '';
    if (currency?.alphabetic_code) {
      const currencySymbol = getCurrencySymbol({ code: currency.alphabetic_code, numeric: false });
      tempCurrencySymbol = currencySymbol?.native ? currencySymbol.native : currency.alphabetic_code;
    }
    return tempCurrencySymbol;
  };

  /**
   * Call API to get the hotel dashboard data
   * 
   * @param {*} hotelId   Hotel Id
   */
  const getHotelDashboardFromApi = useCallback(async (hotelId) => {
    try {
      const response = await getHotelDashboard(hotelId);
      return response;
    } catch (e) {
      throw e
    }
  }, [])

  useEffect(() => {
    if (hotel?.id) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await getHotelDashboardFromApi(hotel.id);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      }).then((response) => {
        setDashboardData(response)
        setMandatoryInfoErrorKeys([])
      }).catch((e) => {
        if (e?.response?.status === 412) {
          const errorResponse = e?.response?.data?.error?.details?.error;
          let warningMsgs = ''
          let i=1;
          let tempErrorKeys = [];
          for (const errorKey in errorResponse) {
            tempErrorKeys.push(errorKey);
            warningMsgs += `<p>${i}. ${errorResponse[errorKey]}</p>`;
            i++;
          }
          setMandatoryInfoErrorKeys(tempErrorKeys)
          // Show error & warning messages
          if (e?.response?.data?.error?.details.hasOwnProperty('isOpenedForLayover') && e?.response?.data?.error?.details.hasOwnProperty('isOpenedForLayoverChanged') && e.response.data.error.details.isOpenedForLayoverChanged) {
            fetchLastAccessedHotelDetails(hotel.id);
            toast.error(toastMessages.hotel.dashboardSetHotelClosedForLayover.error, {
              autoClose: maxToastTime,
              toastId: 'closed-for-layover-error',
            });
            toast.warning(<div dangerouslySetInnerHTML={{ __html: warningMsgs }} />, {
              autoClose: 30000,
              toastId: 'closed-for-layover-warning',
            });
          }
        } else if (e?.response?.status !== 404) {
          setMandatoryInfoErrorKeys([])
          toast.error(e?.response?.data?.error?.message, {
            autoClose: maxToastTime,
            toastId: 'layover-bookings-flag-error',
          });
        }
      }).finally(() => {
        stopLoader();
      });
    }
  }, [hotel, startLoader, stopLoader, getHotelDashboardFromApi]);

  // show instructions and warnings if new hotel there or hotel imp data is missing
  // const checkIsFreshHotel = useCallback(async () => {
  //   if (hotel) {
  //     if (dashboardData) {
  //       const isHotelDataMissing = hotel?.contact_email === null || hotel?.contact_phone === null;
  //       const isHotelRoomInfoMissing = dashboardData?.roomTypes.every(roomType => roomType?.roomTypeName === '');

  //       if (hotel.is_open_for_layover === true && (isHotelDataMissing || isHotelRoomInfoMissing)) {
  //         setIsFreshNewHotel(true);
  //         setIsBookingsOpen(false);
  //         await updateHotelDetails(hotel.id, { is_open_for_layover: false, not_send_closed_for_layover_email: true });
  //         let userHotels = null;
  //         if (user.user?.hotels) {
  //           userHotels = user.user.hotels.map((iHotel, i) => (
  //             iHotel.id === hotel.id ? {
  //               ...iHotel,
  //               is_open_for_layover: false
  //             } : iHotel
  //           ));
  //         }
  //         dispatch(
  //           setUser({
  //             ...user,
  //             user: {
  //               ...user.user,
  //               hotels: userHotels
  //             }
  //           })
  //         );
  //         fetchLastAccessedHotelDetails(hotel.id);
  //       } else if (hotel.is_open_for_layover === false && (isHotelDataMissing || isHotelRoomInfoMissing)) {
  //         setIsFreshNewHotel(true);
  //         setIsBookingsOpen(false);
  //       } else {
  //         setIsFreshNewHotel(false);
  //         setIsBookingsOpen(hotel.is_open_for_layover);
  //       }
  //     } else {
  //       if (dashboardData === null) {
  //         setIsFreshNewHotel(true);
  //         setIsBookingsOpen(false);
  //       }
  //     }
  //   }
  // }, [hotel, dashboardData, setIsFreshNewHotel, setIsBookingsOpen])

  // useEffect(() => {
  //   checkIsFreshHotel();
  // }, [hotel, dashboardData, isFreshNewHotel, setIsFreshNewHotel, checkIsFreshHotel]);

  // const onDialogAgree = useCallback(async (hotelId) => {
  //   setDialogOpen(false);
  //   startLoader();
  //   const response = await updateHotelDetails(hotelId, { is_open_for_layover: hotel.is_open_for_layover === isBookingsOpen ? !isBookingsOpen : isBookingsOpen });
  //   const errorResponse = response?.response?.data?.error?.details?.errorDetails;

  //   if (response === undefined || (errorResponse && Object.keys(errorResponse).length > 0)) {
  //     let msg = Object.keys(errorResponse).map((errorMessage, errorIndex) => {
  //       return `<p style="margin-bottom: 5px;"><b>${errorIndex + 1}.</b> ${errorResponse[errorMessage]}</p>`;
  //     }).join('');

  //     toast.warning(<div dangerouslySetInnerHTML={{ __html: msg }} />, {
  //       autoClose: maxToastTime,
  //       toastId: 'layover-bookings-flag',
  //     });
  //     setIsBookingsOpen(false);
  //     setIsFreshNewHotel(true);
  //     if (hotel.is_open_for_layover === true) {
  //       await updateHotelDetails(hotel.id, { is_open_for_layover: false });
  //       let userHotels = null;
  //       if (user.user?.hotels) {
  //         userHotels = user.user.hotels.map((iHotel, i) => (
  //           iHotel.id === hotel.id ? {
  //             ...iHotel,
  //             is_open_for_layover: false
  //           } : iHotel
  //         ));
  //       }
  //       dispatch(
  //         setUser({
  //           ...user,
  //           user: {
  //             ...user.user,
  //             hotels: userHotels
  //           }
  //         })
  //       );
  //       fetchLastAccessedHotelDetails(hotel.id);
  //     }
  //     stopLoader();
  //   } else {
  //     stopLoader();
  //     try {
  //       let userHotels = null;
  //       if (user.user?.hotels) {
  //         userHotels = user.user.hotels.map((hotel, i) => (
  //           hotel.id === hotelId ? {
  //             ...hotel,
  //             is_open_for_layover: hotel.is_open_for_layover === isBookingsOpen ? !isBookingsOpen : isBookingsOpen
  //           } : hotel
  //         ));
  //       }
  //       dispatch(
  //         setUser({
  //           ...user,
  //           user: {
  //             ...user.user,
  //             hotels: userHotels
  //           }
  //         })
  //       );
  //       const msg = isBookingsOpen ? toastMessages.layoverBookings.open.success : toastMessages.layoverBookings.close.success;
  //       toast.success(msg, {
  //         autoClose: maxToastTime,
  //         toastId: 'layover-bookings-flag',
  //       });
  //       setIsFreshNewHotel(false);
  //       setIsBookingsOpen(isBookingsOpen);
  //       fetchLastAccessedHotelDetails(hotel.id);
  //     } catch (e) {
  //       const msg = isBookingsOpen ? toastMessages.layoverBookings.open.error : toastMessages.layoverBookings.close.error;
  //       toast.error(msg, {
  //         autoClose: maxToastTime,
  //         toastId: 'layover-bookings-flag',
  //       })
  //     }
  //   }
  //   stopLoader();
  // }, [user, startLoader, stopLoader, dispatch, isBookingsOpen, isFreshNewHotel, setIsFreshNewHotel, setIsBookingsOpen]);

  const onDialogAgree = useCallback(async (isOpen, hotelId) => {
    setDialogOpen(false);
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        await onChangeLayoverBookingSwitch(isOpen, hotelId)
        resolve()
      } catch (e) {
        reject(e)
      }
    }).then((response) => {
      setIsBookingsOpen(isOpen)
      setMandatoryInfoErrorKeys([])
      const msg = isOpen ? toastMessages.layoverBookings.open.success : toastMessages.layoverBookings.close.success;
      toast.success(msg, {
        autoClose: maxToastTime,
        toastId: 'header-layover-bookings-flag-success',
      })
    }).catch((e) => {
      setIsBookingsOpen(hotel?.is_open_for_layover ? hotel.is_open_for_layover : false);
      if (e?.response?.status === 412) {
        const errorResponse = e?.response?.data?.error?.details?.error;
        let warningMsgs = ''
        let i=1;
        let tempErrorKeys = [];
        for (const errorKey in errorResponse) {
          tempErrorKeys.push(errorKey);
          warningMsgs += `<p>${i}. ${errorResponse[errorKey]}</p>`;
          i++;
        }
        setMandatoryInfoErrorKeys(tempErrorKeys)
        // Show error & warning messages
        toast.error(toastMessages.layoverBookings.open.mandatoryInfoError, {
          autoClose: maxToastTime,
          toastId: 'closed-for-layover-error',
        });
        toast.warning(<div dangerouslySetInnerHTML={{ __html: warningMsgs }} />, {
          autoClose: 30000,
          toastId: 'closed-for-layover-warning',
        });
      } else if (e?.response?.status !== 404) {
        setMandatoryInfoErrorKeys([])
        // Show error message
        const msg = isOpen ? toastMessages.layoverBookings.open.error : toastMessages.layoverBookings.close.error;
        toast.error(msg, {
          autoClose: maxToastTime,
          toastId: 'layover-bookings-flag-error',
        })
      }
    }).finally(() => {
      stopLoader();
      fetchLastAccessedHotelDetails(hotelId);
    });
  }, [startLoader, stopLoader, hotel, onChangeLayoverBookingSwitch, setIsBookingsOpen]);

  const onDialogDisagree = () => {
    setDialogOpen(false);
    setIsBookingsOpen(hotel?.is_open_for_layover ? hotel.is_open_for_layover : false);
  };

  return (
    <Box sx={{
      px: 2,
      py: 1,
      backgroundColor: theme.mode === 'light' && theme.palette.common.white,
      borderRadius: theme.borderRadius[10],
    }}>
      {/* <Heading title={`Welcome,  ${user?.user?.first_name} ${user?.user?.last_name}`} /> */}
      {hotel ? (
        <>
          {((mandatoryInfoErrorKeys && mandatoryInfoErrorKeys.length > 0) || (!hotel?.is_mandatory_info && !hotel?.is_open_for_layover)) ? (
            <Grid item sx={{mt: 5}} xs={12}>
              <Typography inline variant='body1' sx={{...theme.typography.textxLarge, color: theme.palette.error.main}}>
                Your hotel is currently closed for bookings!
              </Typography>

              <Typography inline variant='body1' sx={{...theme.typography.textxxLarge, mt: 3}}>
                Please follow the simple 4 steps below to get started:
              </Typography>

              <Box sx={{pl: 2, pt: 1}}>
                <StyledMandatoryInfoTypography inline variant='body1' sx={{flexDirection: 'row', justifyContent: 'flex-start'}} isIncomplete={(mandatoryInfoErrorKeys.includes('ratesAndCharges') || mandatoryInfoErrorKeys.includes('hotelTimings') || mandatoryInfoErrorKeys.includes('foodAndBeverages') || mandatoryInfoErrorKeys.includes('transportation'))}>
                  <CheckCircleIcon />
                    <Typography sx={{...theme.typography.textMedium, lineHeight: theme.lineHeight[1.25]}}>  
                     First open the
                    <Link
                      to='/rates-conditions'
                      target='_self'
                      rel='noopener noreferrer'
                      style={{ textDecoration: 'underline', color: theme.palette.primary.light }}
                    >
                    <b>Rates and Conditions</b>
                    </Link>
                    pages and fill out the information on all 5 Tabs. You just need to do this once.
                  </Typography>
                </StyledMandatoryInfoTypography>

                <StyledMandatoryInfoTypography inline variant='body1' sx={{flexDirection: 'row', justifyContent: 'flex-start'}} isIncomplete={mandatoryInfoErrorKeys.includes('availability')}>
                  <CheckCircleIcon /> 
                  <Typography sx={{...theme.typography.textMedium, lineHeight: theme.lineHeight[1.25]}}>
                    Then navigate to the
                    <Link
                      to='/availability'
                      target='_self'
                      rel='noopener noreferrer'
                      style={{ textDecoration: 'underline', color: theme.palette.primary.light }}
                    >
                      <b>Availability Management</b>
                    </Link>
                    page and insert your hotel's availability for the next days.
                  </Typography>
                </StyledMandatoryInfoTypography>

                <StyledMandatoryInfoTypography inline variant='body1' isIncomplete={(mandatoryInfoErrorKeys.includes('hotelLocation') || mandatoryInfoErrorKeys.includes('hotelEmail') || mandatoryInfoErrorKeys.includes('hotelContact'))}>
                  <Box>
                    <CheckCircleIcon /> Next open the
                    <Link
                      to='/account/settings'
                      target='_self'
                      rel='noopener noreferrer'
                      style={{ textDecoration: 'underline', color: theme.palette.primary.light }}
                    >
                      <Typography display='inline' sx={{...theme.typography.textMedium}}><b>Account Settings</b></Typography>
                    </Link>
                    to
                  </Box>
                  <Box sx={{ml: 2, display: 'flex', alignItems: 'start !important', flexDirection: 'column'}}>
                    <Typography variant='body1' sx={{mt: 2}}>a. Edit your contact and hotel details like photos, location and facilities.</Typography>
                    <Typography variant='body1' sx={{mt: 2}}>b. Add another hotel admin or front desk users.</Typography>
                  </Box>
                </StyledMandatoryInfoTypography>

                <StyledMandatoryInfoTypography inline variant='body1'>
                  <Box sx={{lineHeight: theme.lineHeight[1.25]}}>
                    <CheckCircleIcon /> Lastly you can enable your hotel to be visible to airlines for bookings by clicking
                    <StyledButton
                      variant='outlined'
                      disabled={mandatoryInfoErrorKeys.length > 0 ? true : false}
                      onClick={() => {setDialogOpen(true);}}
                    >
                      <Typography display='inline'><b>Open Hotel For Layovers</b></Typography>
                    </StyledButton>
                  </Box>
                </StyledMandatoryInfoTypography>
              </Box>
            </Grid>
          ) : (
            dashboardData ? (
              <Grid xs={12}>
                <Grid container spacing={4} sx={{py: 2, flexDirection: isMobile || isTablet ? 'column' : 'row'}}>
                  <Grid item xs={isMobile || isTablet ? 12 : 6}>
                    <Grid item xs={12} sx={{py: 2}}>
                      <SubHeading title="Today's Layover Rooms" />
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={isMobile || isTablet ? 12 : 6}>
                        <WidgetCard isOpenForLayover={hotel?.is_open_for_layover} noDataFound={(!dashboardData || dashboardData?.availableRooms === 0) ? true : false} 
                          heading={(dashboardData && dashboardData?.availableRooms !== 0) ? dashboardData?.availableRooms : '_ _'} label='Still Available' onClick={() => navigate('/availability')} />
                      </Grid>
                      <Grid item xs={isMobile || isTablet ? 12 : 6}>
                        <WidgetCard isOpenForLayover={hotel?.is_open_for_layover} noDataFound={(!dashboardData || dashboardData?.pickedUpRooms === 0) ? true : false} 
                          heading={(dashboardData && dashboardData?.pickedUpRooms !== 0) ? dashboardData?.pickedUpRooms : '0'} label='Picked Up Rooms' onClick={null} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={isMobile || isTablet ? 12 : 6}>
                    <Grid item xs={12} sx={{py: 2}}>
                      <SubHeading title="Today's Room Rate" />
                    </Grid>
                    <Grid container spacing={2}>
                      {(dashboardData?.roomTypes && dashboardData.roomTypes.length > 0) ? (
                        dashboardData.roomTypes.map((room, i) => (
                          <Grid key={`h-r-p-${i}`} item xs={isMobile || isTablet ? 12 : 6}>
                            <WidgetCard isOpenForLayover={hotel?.is_open_for_layover} noDataFound={room?.price === 0 ? true : false}
                              heading={room?.price !== 0 ? `${getHotelCurrencySymbol(hotel?.currency)}${formatPrice(room?.price)}` : '_ _'} label={room?.roomTypeName} onClick={() => navigate('/availability')} />
                          </Grid>
                        ))
                      ) : (
                        <>
                          <Grid item xs={isMobile || isTablet ? 12 : 6}>
                            <WidgetCard isOpenForLayover={hotel?.is_open_for_layover} noDataFound={(!dashboardData || dashboardData?.singleRooms === 0) ? true : false} 
                              heading={(dashboardData && dashboardData?.singleRooms !== 0) ? dashboardData?.singleRooms : '_ _'} label='Single Room' onClick={() => navigate('/availability')} />
                          </Grid>
                          <Grid item xs={isMobile || isTablet ? 12 : 6}>
                            <WidgetCard isOpenForLayover={hotel?.is_open_for_layover} noDataFound={(!dashboardData || dashboardData?.doubleRooms === 0) ? true : false} 
                              heading={(dashboardData && dashboardData?.doubleRooms !== 0) ? dashboardData?.doubleRooms : '_ _'} label='Double Room' onClick={() => navigate('/availability')} />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
  
                <Box sx={{py: 2}}>
                  <SubHeading title='Recent Bookings' />
                  <Box sx={{py: 2}}>
                    <BookingsTable layovers={dashboardData?.recentBookingDetails ? dashboardData?.recentBookingDetails : []} />
                  </Box>
                </Box>
              </Grid>
            ) : (null)
          )}

          {/* Render Confirm Dialog */}
          <AlertDialog
            title='Are you sure?'
            open={dialogOpen}
            onAgree={() => onDialogAgree(!hotel?.is_open_for_layover, hotel.id)}
            onDisagree={onDialogDisagree}
            yesBtnText={`Yes, ${hotel?.is_open_for_layover === true ? 'Close' : 'Open'}`}
            noBtnText='No, Go back'
            sx={{
              textAlign: 'center'
            }}
            fullWidth={true}
            maxWidth={'xs'}
            stretchBtn
          >
            <Typography sx={{lineHeight: '1.37rem', py: 2}} variant='subtitle2'>
              Do you really want to {hotel?.is_open_for_layover === true ? 'close' : 'open'} the hotel <Typography sx={{...theme.typography.textMedium, ...theme.typography.fontMedium600}} variant='caption'>{hotel?.name}</Typography> for layovers?
            </Typography>
          </AlertDialog>
        </>
      ) : (null)}
    </Box>
  );
};

export default Dashboard;
